<template>
    <div class="col-12 d-flex justify-content-center align-items-center mt-3 px-0">
        <div class="row justify-content-center row__width">
            <div class="col-12 d-flex justify-content-start align-items-center px-0">
                <h2 v-if="actualActivity.payment_type_id == 2">Seleccionar valor del apartado</h2>
                <h2 v-else>Seleccionar porcentaje</h2>
            </div>
            <div class="col-12 d-flex justify-content-center align-items-center px-0">
                <div class="row justify-content-center row__width">
                    <button class="d-flex justify-content-center align-items-center">
                        <img src="../../../img/subir-productos-icons/cursos-icons/porcentaje.svg" alt="">
                    </button>
                    <div class="col max-width d-flex justify-content-center align-items-center">
                        <input type="radio" id="id1" name="percent" value="10" :readonly="!actualActivity.payment_type_id"  @change="handleRadioClick()" v-model="tempPercentageR"> <!--actualActivity.discount.percentage--->
                        <label for="id1">
                            <div class="padding row justify-content-center">
                                <div class="col-12 d-flex justify-content-between align-items-center">
                                    <h5>10 %</h5>
                                    <i></i>
                                </div>
                            </div>
                        </label>
                    </div>
                    <div class="col max-width d-flex justify-content-center align-items-center">
                        <input type="radio" id="id2" name="percent" value="20" :readonly="!actualActivity.payment_type_id"  @change="handleRadioClick()" v-model="tempPercentageR">
                        <label for="id2">
                            <div class="padding row justify-content-center">
                                <div class="col-12 d-flex justify-content-between align-items-center">
                                    <h5>20%</h5>
                                    <i></i>
                                </div>
                            </div>
                        </label>
                    </div>
                    <div class="col max-width d-flex justify-content-center align-items-center">
                        <input type="radio" id="id3" name="percent" value="30" :readonly="!actualActivity.payment_type_id"  @change="handleRadioClick()" v-model="tempPercentageR">
                        <label for="id3">
                            <div class="padding row justify-content-center">
                                <div class="col-12 d-flex justify-content-between align-items-center">
                                    <h5>30%</h5>
                                    <i></i>
                                </div>
                            </div>
                        </label>
                    </div>
                    <input type="number" min="0" max="100" class="col d-flex justify-content-center align-items-center ms-0 ms-lg-3 mt-3 mt-lg-0"  :readonly="!actualActivity.payment_type_id" :class="tempPercentage ? 'blue-border' : ''" placeholder="Ej: 17%" id="percentage"
                     @click="tempPercentageR = null; handleInputClick(null)"  @change="checkInput(tempPercentage)" v-model="tempPercentage">
                </div>
            </div>
           
            <div class="col-12 d-flex justify-content-center align-items-center px-0 mt-4" v-if="actualActivity.payment_type_id == 2">
                <div class="row justify-content-center row__width">
                    <div class="col-12 px-0">
                        <h2>Cantidad de cuotas</h2>
                    </div>
                    <input type="number" min="2" max="12" class="col-12 d-flex justify-content-center align-items-center" :class="payments ? 'blue-border' : ''" placeholder="Cantidad de cuotas" id="payments"
                    v-model="actualActivity.payments" @change="checkInputPayments(actualActivity.payments)" >
                </div>
            </div>
           
        </div>
    </div>
</template>
<script setup>
import { inject, onMounted, ref, watch } from 'vue';
import Swal from 'sweetalert2';

let actualActivity = inject('actualActivity');
let tempPercentage = ref(null);
let tempPercentageR = ref(null);
let payments = ref(null);

const checkInput = () => {
    console.log(tempPercentage.value);
    console.log(tempPercentageR.value);

    if (tempPercentage.value < 0) {
        tempPercentage.value = 0;
    } else if (tempPercentage.value > 100) {
        tempPercentage.value = 100;
    }

    // Si hay un valor seleccionado por radio button
    if (tempPercentageR.value) {
        if (actualActivity.value.payment_type_id === 1) {
            actualActivity.value.discount = Number(tempPercentageR.value);
            delete actualActivity.value.initial;
        } else {
            actualActivity.value.initial = Number(tempPercentageR.value);
            delete actualActivity.value.discount;
        }
    } 
    else if (tempPercentage.value) {
        const value = Number(tempPercentage.value);

        if (value === 10 || value === 20 || value === 30) {
            // Seleccionar automáticamente el radio button correspondiente
            tempPercentageR.value = value;
            tempPercentage.value = null;
        }

        if (actualActivity.value.payment_type_id === 1) {
            actualActivity.value.discount = value;
            delete actualActivity.value.initial;
        } else {
            actualActivity.value.initial = value;
            delete actualActivity.value.discount;
        }
    }

    console.log(actualActivity.value);
};

const checkInputPayments = (value) => {
    console.log(value)
    if (value < 2) {
        actualActivity.value.payments = 2;
        console.log(actualActivity.value.payments, 'mayor')
    } else if (value > 12) {
        actualActivity.value.payments = 12;
        console.log(actualActivity.value.payments, 'menor')
    }
};

const handleInputClick = (event) => {
  if (!actualActivity.value.payment_type_id) {
    Swal.fire({
      icon: 'error',
      title: 'Elige una forma de pago',
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 2000,
      timerProgressBar: true
    });
    event.preventDefault(); // Evita que el input sea seleccionado
    event.stopPropagation(); // Detiene la propagación del evento
  } else {
    tempPercentageR.value = null; // Limpia el radio seleccionado si se hace clic en el input manual
  }
};

const handleRadioClick = () => {
  if (!actualActivity.value.payment_type_id) {
    Swal.fire({
      icon: 'error',
      title: 'Elige una forma de pago',
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 2000,
      timerProgressBar: true
    });
    event.preventDefault(); // Evita que el radio se seleccione
    event.stopPropagation();
  } else {
    checkInput();
  }
};

watch(
    () => actualActivity.value.payment_type_id,
    (newVal, oldVal) => {
        console.log(`payment_type_id cambiado de ${oldVal} a ${newVal}`);
        // Ejecutar checkInput para verificar y reasignar valores
        checkInput();
    }
);

onMounted( ( ) => {
 if(actualActivity.value.discount){
    if (actualActivity.value.discount === 10 || actualActivity.value.discount === 20 || actualActivity.value.discount === 30) {
        tempPercentageR.value = actualActivity.value.discount
    }else{
        tempPercentage.value = actualActivity.value.discount
    }
 }else if(actualActivity.value.initial){
    
    if (actualActivity.value.initial === 10 || actualActivity.value.initial === 20 || actualActivity.value.initial === 30) {
        tempPercentageR.value = actualActivity.value.initial
    }else{
        tempPercentage.value = actualActivity.value.initial
    }
    
 }
})
</script>

<style lang="scss" scoped>
h2{
    color: var(--colorSecondary);
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    padding: 7px 0px;
}
h3{
    color: var(--colorSecondary);
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    padding: 7px 0px;
}
input{
    border: 2px solid #E9E3E3;
    border-radius: 10px;
    background-color: transparent;
    height: 50px;
    padding-left: 15px;
    outline: 0;
    color: #697891;
    font-size: 15px;
    font-weight: 400;
    &:focus, &:hover{
        border: 2px solid #1679FC;
    }
    &::placeholder{
        color: #697891;
    }
}
button{
    background-color: #fff;
    border: 2px solid #E9E3E3;
    outline: 0;
    border-radius: 25px;
    height: 50px;
    width: 50px;
}

input[type=radio]{
        display: none;
}
label{
    width: 100%;
    height: 50px;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    border-radius: 10px;
    border: 2px solid #E9E3E3;
    h5{
        color: #A4A4A4;
        font-size: 16px;
        font-weight: 400;
    }
    .span{
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        border: 2px solid #E4E4E4;
        min-width: 28px;
        min-height: 28px;
        left: 0;
        &::after{
            content: '';
            position: absolute;
            border-radius: 50%;
            background-color: var(--colorPrimary);
            width: 14px;
            height: 14px;
            display: none;
        }
    }
    i{
        height: 25px;
        width: 25px;
        display: block;
        background-repeat: no-repeat;
        position: absolute;
        right: 5%;
    }
}
input[type=radio]:checked + label .span{
    border-color: var(--colorPrimary);
    &::after{
        display: block;
    }
}
input[type=radio]:checked + label{
    border: 2px solid var(--colorPrimary);
    box-shadow: 0px 3px 6px #00000029;
    i{
        background-image: url('../../../img/subir-productos-icons/cursos-icons/tick.svg');
    }
    h5{
        color: var(--colorPrimary);
    }
}
.max-width{
    @media (max-width: 991px) {
        min-width: 28% !important;
        padding-right: 0;
    }
    @media (min-width: 992px) {
        max-width: 15%;
    }
}
.blue-border{
    border: 2px solid var(--colorPrimary)
}
</style>