<template>
    <div class="container-fluid usuario-sesion">
        <NavbarBuscador/>
        <div class="row justify-content-center">
            <div class="col-11 d-flex flex-column justify-content-center align-items-start px-0 padding">
                <TitulosSecciones :titulo="$t('message.termsTitle')" :haySubtitulo="false" class="py-0 px-0"/>
                <h2>{{ $t('message.conditions') }}</h2>
                <p>{{ $t('message.conditionsText') }}</p>

                <!-- Entrada a la plataforma -->
                <h2>{{ $t('message.enterPlatform') }}</h2>
                <p>{{ $t('message.enterPlatformText') }}</p>

                <!-- Almacenamiento de contenido -->
                <h2>{{ $t('message.storeContent') }}</h2>
                <p>{{ $t('message.storeContentText') }}</p>

                <!-- Iniciar sesión y registro -->
                <h2>{{ $t('message.loginRegister') }}</h2>
                <p>{{ $t('message.loginRegisterText') }}</p>

                <!-- Usuarios -->
                <h2>{{ $t('message.users') }}</h2>
                <p>{{ $t('message.usersText') }}</p>

                <!-- Precios -->
                <h2>{{ $t('message.pricing') }}</h2>
                <p>{{ $t('message.pricingText') }}</p>

                <!-- Tarifas y reembolsos -->
                <h2>{{ $t('message.feesRefunds') }}</h2>
                <p>{{ $t('message.feesRefundsText') }}</p>
            </div>
        </div>
    </div>
</template>

<script setup>
import NavbarBuscador from '@/components/Navs/NavbarBuscador.vue';
import TitulosSecciones from '@/components/Titulo/TitulosSecciones.vue';
import { onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const route = useRoute();
const router = useRouter();

let insertedNodes = []

const useHeadTags = async (settings = null) => {
    const url = process.env.VUE_APP_BASE_URL

    try {
        let response = await axios.post(`${url}/api/public/seo/pages`, { slug: settings.slug });

        const tagsString = await response.data.data

        const parser = new DOMParser()
        const doc = parser.parseFromString(
        `<html><head>${tagsString}</head><body></body></html>`,
        'text/html'
        )

        const headNodes = Array.from(doc.head.childNodes)

        headNodes.forEach(node => {
        const nodeName = node.nodeName.toLowerCase()

        switch (nodeName) {
            case 'title':
            document.title = node.textContent ?? ''
            break

            case 'meta': {
            const metaName = node.getAttribute('name')
            if (metaName) {
                const existingMeta = document.head.querySelector(`meta[name="${metaName}"]`)
                if (existingMeta) {
                document.head.removeChild(existingMeta)
                }
            }
            const meta = document.createElement('meta')
            Array.from(node.attributes).forEach(attr => {
                meta.setAttribute(attr.name, attr.value)
            })
            document.head.appendChild(meta)
            insertedNodes.push(meta)
            break
            }

            case 'script': {
            const script = document.createElement('script')
            Array.from(node.attributes).forEach(attr => {
                script.setAttribute(attr.name, attr.value)
            })
            script.textContent = node.textContent
            document.head.appendChild(script)
            insertedNodes.push(script)
            break
            }

            case 'link': {
            const link = document.createElement('link')
            Array.from(node.attributes).forEach(attr => {
                link.setAttribute(attr.name, attr.value)
            })
            document.head.appendChild(link)
            insertedNodes.push(link)
            break
            }

            default:
            console.warn(`Etiqueta <${nodeName}> no manejada.`)
        }
        })
        console.warn('TAGS CARGADOS')
    } catch (error) {
        console.error('Error procesando las etiquetas:', error)
    }
}

router.beforeEach(() => {
    insertedNodes.forEach(node => {
    if (document.head.contains(node)) {
        document.head.removeChild(node)
    }
    })
    insertedNodes = []
})

onMounted(() => {
    useHeadTags({ slug: route.fullPath.slice(1) })
})

</script>

<style lang="scss" scoped>
.padding{
    padding-top: 100px;
    padding-bottom: 50px;
    @media (max-width: 992px) {
        padding-top: 70px;
    }
}
h2{
    font-weight: 700;
    font-size: 22px;
    color: var(--colorSecondary);
    text-align: left;
    margin: 20px 0;
}
p{
    font-size: 14px;
    font-weight: 400;
    color: #B8C1DB;
    text-align: left;
    padding-top: 10px;
}
</style>