<template>
    <div class="col-12 d-flex justify-content-center align-items-center px-0 content">
        <div action="" v-if="information != null">
            <div class="row justify-content-center">
                <!-- {{ information }}
                {{ modality }}
                {{ classId }} -->
                <div class="col-12 d-flex justify-content-center align-items-center px-0 mt-4">
                    <div class="row justify-content-start row__width">
                        <Nombre/>
                        <Fecha v-if="classId == 1 && modality"/>
                        <Pais v-if="classId == 1 && modality"/>
                        <Estado v-if="classId == 1 && modality"/>
                        <Ciudad v-if="classId == 1 && modality"/>
                        <Ubicacion v-if=" classId == 1 && modality"/>
                        <Descripcion/>
                        <Creador/>
                        <Producido/>
                        <Colaboradores/>
                        <Patrocionadores/>
                        <Profesion/>
                        <Especializacion/>
                        <Contador v-if="classId == 1 && modality"/>
                        
                        
                        <!-- <Tiempo class="d-none"/> -->
                        <FechaInicio class="d-none"/>
                        <FechaFin class="d-none"/>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import Pais from '../../../components/Inputs/InformacionProfesional/Pais.vue';
import Estado from '../../../components/Inputs/InformacionProfesional/Estado.vue';
import Ciudad from '../../../components/Inputs/InformacionProfesional/Ciudad.vue';
import Nombre from '../../../components/Inputs/InformacionSubirCurso/Nombre.vue';
import Ubicacion from '../../../components/Inputs/InformacionSubirCurso/Ubicacion.vue';
import Descripcion from '../../../components/Inputs/InformacionSubirCurso/Descripcion.vue';
import Creador from '../../../components/Inputs/InformacionSubirCurso/Creador.vue';
import Producido from '../../../components/Inputs/InformacionSubirCurso/Producido.vue';
import Profesion from '../../../components/Inputs/InformacionSubirCurso/Profesion.vue';
import Colaboradores from '../../../components/Inputs/InformacionSubirCurso/Colaboradores.vue';
import Especializacion from '../../../components/Inputs/InformacionSubirCurso/Especializacion.vue';
import FechaFin from '../../../components/Inputs/InformacionSubirCurso/FechaFin.vue';
import FechaInicio from '../../../components/Inputs/InformacionSubirCurso/FechaInicio.vue';
import Tiempo from '../../../components/Inputs/InformacionSubirCurso/Tiempo.vue';

import { onMounted, provide, ref, watch, inject } from 'vue';
import axios from "axios"
import { useI18n } from "vue-i18n"
import { useRouter } from 'vue-router';
import { storeToRefs } from "pinia";
import { useUsersStore } from "../../../stores/user";
import { useLanguageStore } from "../../../stores/langs"
import CreadorAdmin from '../../../components/Inputs/InformacionSubirCurso/CreadorAdmin.vue';
import Fecha from '@/components/Inputs/InformacionSubirCurso/Fecha.vue';
import Contador from '@/components/Inputs/InformacionSubirCurso/Contador.vue';
import Patrocionadores from '@/components/Inputs/InformacionSubirCurso/Patrocionadores.vue';

const {locale} = useI18n({ useScope: 'global' })
const langs = useLanguageStore()
const {} = langs

const users = useUsersStore();
const { access, idUser } = storeToRefs(users);
const { url, validate } = users;

const information = inject('information')
const product = inject('product')
const modality = inject('modality')
const classId = inject('classId')
</script>

<style lang="scss" scoped>
.content{
    h6{
        font-weight: 700;
        color: var(--colorSecondary);
        font-size: 22px;
        text-align: left;
    }
    .max{
        overflow-x: scroll;
        overflow-y: hidden;
        max-width: 100%;
        .scroll{
            min-width: 120vw;
            padding-top: 2vh;

        }
        @media (min-width: 992px) {
            overflow: hidden;
            .scroll{
                min-width: auto;
            }
        }
    }
    h4{
        font-weight: 400;
        font-size: 16px;
        color: #0A1637;
    }
    h5{
        font-weight: 600;
        color: var(--colorSecondary);
        font-size: 16px;
        text-align: left;
    }
    .banner{
        position: relative;
        min-height: 200px;
        input[type=file]{
            display: none;
        }
        .banner_img{
            max-width: 100%;
            height: 20vh;
            width: 100%;
            border-radius: 15px;
            box-shadow: 0px 3px 6px #00000029;
            object-fit: cover;
        }
        button{
            background-color: #fff;
            width: 43px;
            height: 43px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 0;
            position: absolute;
        }
        .perfil{
            width: 100%;
            .img{
                border-radius: 50%;
                height: 120px;
                width: 120px;
                border: 2px solid #FFFFFF;
                object-fit: cover;
            }
            .button__perfil{
                bottom: -10%;
                right: -5%;
            }
        }
        .button__banner{
            background-color: var(--bgPrincipal);
            width: 37px;
            height: 37px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            position: absolute;
            right: 2%;
            top: 10%;
        }
        .button_perfil{
            background-color: var(--bgPrincipal);
            width: 37px;
            height: 37px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            position: absolute;
            right: -2%;
            bottom: -14%;
        }
        .foto_perfil{
            position: absolute;
            left: 3%;
        }
        
    }
    .agregar__contenedor{
        box-shadow: 0px 3px 15px #00000029;
        border-radius: 15px;
        height: auto;
        padding: 40px;
        h2{
            font-weight: 600;
            color: var(--colorSecondary);
            font-size: 22px;
            text-align: left;
            padding-bottom: 10px;
        }
        h3{
            font-weight: 400;
            color: #2E4765;
            font-size: 14px;
            text-align: left;
        }
        .position{
            position: relative;
            margin-bottom: 20px;
            height: 20vh;
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 15px;
            padding: 0;
            .img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 15px;
                padding: 0;
                &:focus + button, &:hover + button{
                    display: flex !important;
                }
            }
            button{
                border: 0;
                outline: 0;
                background-color: #fff;
                box-shadow: 0px 3px 6px #00000029;
                height: 35px;
                width: 35px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                right: 2%;
                top: -7%;
                img{
                    transform: rotate(45deg);
                }
            }
            .progress{
                width: 90%;
            }
        }
        .sinImagen{
            background-color: #FAFAFA;
        }
    }
    .btn-none{
        background-color: transparent;
        border: 0;
        outline: 0;
    }
    .input__file{
        button{
            border: 2px dashed #E4E4E4;
            border-radius: 15px;    
            background-color: #FAFAFA;
            min-height: 20vh;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        input{
            display: none;
        }
        h4{
            font-weight: 500;
            color: #697891;
            font-size: 14px;
            text-align: center;
            padding: 20px 0;
        }
        h5{
            font-weight: 400;
            color: #B8C1DB;
            font-size: 14px;
            text-align: center;
            padding: 20px 0;
        }
        span{
            cursor: pointer;
            border: 2px dashed var(--colorPrimary);
            height: 50px;
            width: 80%;
            color: var(--colorPrimary);
            font-size: 16px;
            font-weight: 700;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 28px;
            img{
                margin-right: 10px;
            }
        }
    }
    .pdf{
        input[type=file]{
            display: none;
        }
        button{
            background-color: var(--colorPrimary);
            color: var(--bgPrincipal);
            border: 2px solid var(--colorPrimary);
            font-weight: 500;
            font-size: 16px;
            height: 50px;
            min-width: min-content;
            margin: 10px 0;
            padding: 10px 15px;
            border-radius: 28px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            &:focus, &:hover{
                background-color: var(--bgPrincipal);
                color: var(--colorPrimary);
                border: 2px solid var(--colorPrimary);
            }
        }
        p{
            font-weight: 500;
            font-size: 16px;
            color: var(--colorSecondary);
            padding-left: 15px;
        }
    }
}

</style>