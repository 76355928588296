<template>
    <div class="col d-flex flex-column justify-content-start justify-content-lg-start align-items-center slide px-0">
        <div class="row justify-content-between align-items-start caption row__width">
            <router-link :to="{ name: 'Curso', params: { slug: slug }}" class="col-12 d-flex justify-content-center align-items-center px-1 position-relative">
                <img :src="image" alt="" class="ejemplo">
                <div class="row justify-content-center row__width position">
                    <div class="col-12 d-flex justify-content-center align-items-center px-1" style="padding: 0 0;">
                        <div class="row justify-content-center align-items-center row__width row__slide">
                            <div class="col-12 d-flex justify-content-between align-items-start arriba">
                                <img src="../../img/home-icons/cursos-icons/verificado.svg" alt="" v-if="membership">
                            </div>
                            <div class="col-12 d-flex justify-content-center align-items-center abajo px-0">
                                <div class="row justify-content-center row__width">
                                    <div class="col col-lg-7 d-flex flex-row justify-content-start align-items-center px-1 px-lg-auto" v-if="marketplaces">
                                        <h4>{{ $t('message.produced') }}</h4>
                                        <h5 class="px-0 ps-md-1">{{ marketplaces.translations[lang].name }}</h5>
                                    </div>
                                    <div class="col col-lg-5 d-flex justify-content-end align-items-center">
                                        <h6><img src="../../img/home-icons/cursos-icons/rate.svg" alt="" class="">{{ reviews > 0 ? reviews : '' }}</h6>
                                        <p>{{ reviews > 0 ? `(${reviews})` : $t('message.new') }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </router-link>
            <div class="col d-flex flex-column justify-content-start justify-content-lg-center align-items-center px-0">
                <div class="row justify-content-center row__width descripcion">
                    <router-link :to="{ name: 'Curso', params: { slug: slug }}" class="col-12 d-flex justify-content-start justify-content-lg-start align-items-start pt-2 pt-lg-0">
                        <h2 class="pt-lg-2">{{ name }}</h2>
                    </router-link>
                    <div class="col-12 d-flex flex-column justify-content-center align-items-center px-0">
                        <div class="row justify-content-start row__width pt-1 py-lg-3">
                            <router-link :to="{name: 'Profesional', params: {slug: owner_slug}}" class="col-6 d-flex justify-content-start align-items-center" v-if="owner_slug">
                                <img :src="avatar" alt="avatar" class="avatarImage">
                                <div class="d-flex flex-column justify-content-center align-items-start" >
                                    <p class="creator">{{$t('message.creator')}}</p>
                                    <p class="owner">{{ owner }}<img src="../../img/home-icons/cursos-icons/verficacion-perfil.svg" alt="profile-verified" v-if="verified"></p>
                                </div>
                            </router-link>
                            <div class="col-6 d-flex justify-content-start align-items-center" v-else>
                                <img :src="avatar" alt="avatar" class="avatarImage">
                                <div class="d-flex flex-column justify-content-center align-items-start" >
                                    <p class="creator">{{$t('message.creator')}}</p>
                                    <p class="owner">{{ owner }}<img src="../../img/home-icons/cursos-icons/verficacion-perfil.svg" alt="profile-verified" v-if="verified"></p>
                                </div>
                            </div>
                            <div class="col d-flex justify-content-start justify-content-lg-end align-items-center colaboradores">
                                <button type="button" class="btn_none d-flex justify-content-center align-items-center" v-if=" collabs && collabs.length >= 1" @click="openModal(id)">
                                    <img :src="collabs[0].avatar" alt="avatar-collab" class="img_ejemplo">
                                    <div class="colaboran"><p class="collabs">+{{ collabs.length }}</p></div>
                                    <p class="collaborate p_mobile">{{$t('message.collaborate')}}</p>
                                </button>
                                <button type="button" class="btn_none d-flex justify-content-center align-items-center" v-if=" sponsors && sponsors.length >= 1" @click="openModal2(id)">
                                    <img :src="sponsors[0].avatar" alt="avatar-collab" class="img_ejemplo">
                                    <div class="colaboran"><p class="collabs">+{{ sponsors.length }}</p></div>
                                    <p class="collaborate p_mobile">Patrocinan</p>
                                </button>
                            </div>
                        </div>
                        <span class="border-bottom"></span>
                    </div>
                </div>
                <router-link :to="{ name: 'Curso', params: { slug: slug }}" class="row justify-content-center row__width horarios py-2 py-lg-0">
                    <div class="col-12 d-flex justify-content-center align-items-center px-0">
                        <div class="row justify-content-start row__width py-1 py-lg-2">
                            <div class="col-4 col-lg d-flex justify-content-start justify-content-lg-center align-items-center border-2">
                                <img src="../../img/home-icons/cursos-icons/participantes.svg" alt="">
                                <h6>{{ students }}</h6>
                            </div>
                            <div class="col-4 col-lg-4 d-flex justify-content-start align-items-center pe-0">
                                <h3 class="line-title">{{ modality }}</h3>
                            </div>
                            <div class="col d-flex justify-content-start align-items-center px-0 mt-3 mt-lg-0" v-if="details">
                                <div class="d-flex justify-content-center align-items-center px-0 me-2">
                                    <img src="../../img/home-icons/cursos-icons/horario.svg" alt="" v-if="details.time_duration">
                                    <h6 v-if="details.time_duration">{{ details.time_duration }} {{ $t('message.hours') }}</h6>
                                </div>
                                <div class="d-flex justify-content-center align-items-center px-0" v-if="details.schedules">
                                    <img src="../../img/home-icons/cursos-icons/calendario.svg" alt="">
                                    <h6>{{ details.schedules[0] }}</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 d-flex justify-content-start align-items-center mb-1 pt-2 pe-lg-0">
                        <p class="location">
                            <template v-if="country?.code">
                                {{ countryCodeToFlagEmoji(country.code) }}  {{ country.translations[lang].name }},
                            </template>
                            <template v-if="state?.translations" >
                                {{ state.translations[lang].name}},
                            </template>
                            <template v-if="city?.translations" >
                                {{ city.translations[lang].name}}
                            </template>
                        </p>
                    </div>
                </router-link>
                <router-link :to="{ name: 'Curso', params: { slug: slug }}" class="row justify-content-center align-items-center comprar row__width" v-if="realPrice == 'GRATIS'">
                    <button class="mirar" @click="router.push({ name: 'Curso', params: { slug: slug }})">
                        <span>{{$t('message.freeUppercase')}}</span>
                        <img src="../../img/home-icons/cursos-icons/mirar-ahora.svg" alt=""> {{ $t('message.lookNow') }}
                    </button>
                </router-link>
                <router-link :to="{ name: 'Curso', params: { slug: slug }}" class="row justify-content-start comprar row__width" v-else>
                    <div class="col d-flex flex-column justify-content-center align-items-start padding pe-0" v-if="prices && prices.MXN[0]">
                        <h2>MXN ${{ selectedPrice.finalPrice }}</h2>
                        <h5 v-if="selectedPrice.discount">MXN ${{ selectedPrice.totalPrice }}</h5>
                    </div>
                    <div class="col col-md-5 col-lg d-flex justify-content-center align-items-center">
                        <router-link :to="{name: 'Curso', params: {slug: slug}}" class="btn__primario btn-width">
                            {{$t('message.buy')}}
                        </router-link>
                    </div>
                </router-link>
            </div>
        </div>
    </div>

</template>

<script setup>
import { polyfillCountryFlagEmojis } from "country-flag-emoji-polyfill";
    import BTNPrimario from "../Botones/PrimarioRutas.vue";
    import { useGeneralStore } from "../../stores/general"
    import { storeToRefs } from "pinia";
    import { useRouter } from 'vue-router';
    import { onMounted, ref, computed } from "vue";
    
    const router = useRouter()

    const general = useGeneralStore();
    const {textos, filtrosDesplegados, mapaDesplegado} = storeToRefs(general);
    const {} = general;

    //Recibe los parametros de busqueda del componente padre
    const props = defineProps({
        product: '',
    })

    const lang = ref('ES')
    let realPrice = 0

    //Setea las variables con los resultados para su uso
    let id = props.product.id
    let name = props.product.names?.translations[lang.value]?.name
    let slug = props.product.slugs?.translations[lang.value]?.slug || props.product.dinamic_slug
    let owner = props.product.owner?.name
    let owner_slug = props.product.owner?.slug ?? null
    let avatar = props.product.owner?.avatar
    let image = props.product.image
    let modality = props.product.type?.translations[lang.value]?.name
    let details = props.product.details
    let prices = props.product.prices
    let verified = props.product.verified
    let createdAt = props.product.created_at
    let collabs = props.product.colaborators
    let reviews = parseInt(props.product.rating)
    let marketplaces = props.product.marketplaces[0]
    let membership = props.product.membership
    let students = props.product.students
    let purchases_count = props.product.purchases_count
    let country = props.product.country
    let state = props.product.state ?? null
    let city = props.product.city

    const selectedPrice = computed(() => {
        if (!prices.MXN || prices.MXN.length === 0) return null;
        return prices.MXN.find(p => p.payment_type?.type === 'one_time') || prices.MXN[0];
    });

    const openModal = (id) => {
        console.log(id)
        let element = document.getElementById('collabModal'+id)
        console.log(element)
        console.log(element.classList)
        element.classList.remove("d-none")
    }

    const roundHours = (minutes) => {
        const hours = Math.floor(minutes / 60);
        const remainingMinutes = minutes % 60;

        if (remainingMinutes >= 45) {
            return hours + 1;
        } else {
            return hours;
        }
    }

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.toLocaleString('es-ES', { month: 'long' });

        // Capitaliza la primera letra del mes
        const formattedMonth = month.charAt(0).toUpperCase() + month.slice(1);

        return `${day} ${formattedMonth}`;
    }

    onMounted(() => {
        if (details) {
            if (details.time_duration) {
                details.time_duration = roundHours(details.time_duration)
            }
            if (details.schedules) {
                details.schedules[0] = formatDate(details.schedules[0])
            }
        }
        polyfillCountryFlagEmojis();
    })
    
    function countryCodeToFlagEmoji(countryCode) {
        return countryCode
            .toUpperCase()
            .replace(/./g, char => String.fromCodePoint(127397 + char.charCodeAt()));
    }
    //console.log(props)
    const openModal2 = (id) => {
        console.log(id)
        let element = document.getElementById('sponsorModal'+id)
        console.log(element)
        console.log(element.classList)
        element.classList.remove("d-none")
    }
</script>

<style lang="scss" scoped>
.slide{
    background-color: #FFFFFF;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 15px;
    opacity: 1;
    height: 500px !important;
    max-height: 500px;
    max-width: 100%;
    @media (max-width: 992px) {
        height: 455px !important;
        max-height: 455px;
    }
    .ejemplo{
        width: 100%;
        border-radius: 15px;
        object-fit: cover;
        height: 225px !important;
        max-height: 2225px;
    }
    .caption{
        position: relative;
        margin: 10px 0;
        padding: 0 5px;
        height: 100%;
        .position{
            position: absolute;
            top: 0;
            min-height: 100%;
            h3{
                font-weight: 700;
                font-size: 16px;
                color: var(--bgPrincipal);
            }
            h4{
                font-weight: 400;
                font-size: 15px;
                color: var(--bgPrincipal);
            }
            h5{
                font-weight: 700;
                font-size: 15px;
                color: var(--bgPrincipal);
                padding-left: 5px;
            }
            h6{
                font-family: 'Proxima Nova';
                font-weight: 500;
                font-size: 17px;
                color: #FEB230;
                display: flex;
                justify-content: center;
                align-items: center;
                min-width: max-content;
                position: relative;
                top: 2px;
                img{
                    height: 20px;
                    width: 20px;
                    top: -2px;
                    position: relative;
                    padding-left: 5px
                }
            }
            p{  font-family: 'Proxima Nova';
                font-weight: 500;
                font-size: 17px;
                color: var(--bgPrincipal);
                padding-left: 3px;
            }
            .row__slide{
                position: relative;
                min-height: 100% !important;
                min-height: 250px;
                border-radius: 15px;
                background: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0) 80%);
                .arriba{
                    position: absolute;
                    top: 3%;
                }
                .abajo{
                    position: absolute;
                    bottom: 3%;
                }
            }
            
        }
    }
    .descripcion{
        h2{
            font-weight: 700;
            font-size: 22px;
            color: var(--colorSecondary);
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            line-clamp: 2;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            line-height: 1.2;
            text-align: left;
        }
        .creator, .collaborate{
            color: #B8C1DB;
            font-weight: 500;
            font-size: 15px;
            
        }
        .owner{
            color: var(--colorSecondary);
            font-weight: 500;
            font-size: 15px;
            max-width: 7vw;
            text-align: start;
            overflow: hidden;
            text-wrap: nowrap;
            text-overflow: ellipsis;
            display: block;
            line-clamp: 2;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            @media (max-width: 1439px) {
                max-width: 100%;
            }
            img{
                margin-left: 5px;
            }
        }
        .collabs{
            color: var(--bgPrincipal);
            font-weight: 500;
            font-size: 15px;
        }
        h3{
            color: #B8C1DB;
            font-weight: 500;
            font-size: 15px;
            
        }
        h4{
            color: var(--colorSecondary);
            font-weight: 500;
            font-size: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: start;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            line-clamp: 2;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            img{
                margin-left: 5px;
            }
        }
        p{
            color: var(--bgPrincipal);
            font-weight: 500;
            font-size: 15px;
        }
        .colaboradores{
        position: relative;
        p{
            color: #B8C1DB;
            font-weight: 500;
            font-size: 15px;
        }
        .img_ejemplo{
            object-fit: cover;
            border-radius: 50%;
            min-width: 25px;
            min-height: 25px;
            max-width: 25px;
            max-height: 25px; 
            margin-right: 5px;
            position: relative;
            left: 0;
            
        }
        .colaboran{
            background-color: #697891;
            box-shadow: 0px 3px 6px #00000029;
            border: 2px solid #FFFFFF;
            border-radius: 50%;
            min-width: 25px;
            min-height: 25px;
            max-width: 25px;
            max-height: 25px;                
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            right: 15px;
            z-index: 1;
            p{
                font-size: 10px
            }
        }
        .collabs{
            color: var(--bgPrincipal);
            font-weight: 500;
            font-size: 15px;
        }
        .colaboran + .collaborate{
            position: relative;
            right: 10px;
        }
        @media (min-width: 1200px) {
            max-width: 50%;
            .img_ejemplo, .colaboran{
                min-width: 40px;
                min-height: 40px;
                max-width: 40px;
                max-height: 40px; 
                // position: absolute;
                // left: 2%;
            }
            .img-ejemplo{
                left: 15px;
            }
            // .colaboran + h3{
            //     position: relative;
            //     right: 0;
            // }
            .colaboran {
                p{
                    font-size: 14px;
                }
            }
        }
    }

        .creador{
            border-right: 1px solid rgba(184, 193, 219, 0.3);
        }
        .border-bottom{
            width: 90%;
            height: 1px;
            background-color: rgba(184, 193, 219, 0.3);
        }
    }
    .horarios{
        h6{
            color: #7D93B2;
            font-weight: 500;
            font-size: 12px;
            padding-left: 5px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
        .border2{
            width: 1px;
            height: 100%;
            background-color: rgba(184, 193, 219, 0.3);
            margin: 0 10px;
        }
        .border-2{
            border-right: 1px solid rgba(184, 193, 219, 0.3);
            max-width: max-content;
        }
        .location, h3{
            color: var(--colorSecondary);
            font-weight: 600;
            font-size: 15px;
            text-align: start;
            text-wrap: nowrap;
            text-overflow: ellipsis;
            max-width: 40vw;
            overflow: hidden;
        }
        .location{
            color: #697891;
        }
        .col-perso2{
            max-width: 25%;
        }
    }
    .comprar{
        margin-bottom: 5px;
        @media (max-width: 992px) {
            margin-top: 0px;
            margin-bottom: 0px;
        }
        h2{
            font-weight: 700;
            font-size: 22px;
            color: var(--colorSecondary);
            text-align: left;
            text-wrap: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: 100%;
        }
        h5{
            font-size: 14px;
            font-weight: 400;
            color: #B8C1DB;
            text-decoration: line-through;
            text-align: left;
            text-wrap: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: 100%;
        }
        .padding{
            padding-left: 10px;
        }
    }
    @media (min-width: 992px) {
        padding: 5px 5px !important;
        .ejemplo{
            max-height: 240px;
        }
        .max-width-btn{
            max-width: 200px;
        }
        
    }
    @media (max-width: 1024px) {
        .caption{
            .ejemplo{
                height: 100%;
            }
            .position{
                h4, h5{
                    font-size: 10px
                }
                p{
                    font-size: 12px;
                }
            }
        }
        .descripcion{
            h2{
                font-size: 18px;
            }
            h3, h4{
                font-size: 14px;
            }
        }
        .horarios{
            h3{
                font-size: 13px;
            }
        }
        .comprar{
            h2{
                font-size: 16px;
            }
            .padding{
                padding-left: 10px;
            }
            a{
                height: 40px;
                //width: 100px;
                font-size: 15px
            }
        }
    }
    @media (min-width: 1025px) {
        .comprar{
            h2{
                font-size: 20px;
            }
        }
    }
    @media (min-width: 1366px) {
        .comprar{
            h2{
                font-size: 22px;
            }
        }
    }
}
@media (max-width: 992px) {
    .slide{
        height: 490px;
    }
}
.line-title {
    clear: both;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
}
.btn-width {
    width: 150px;
}
.image-fix {
    margin: 0px 3px 2px 0px;
    height: 20px;
    width: 20px;
}
.max-width-col2{
    max-width: 400px
}
.mirar{
        border: 2px solid var(--colorSecondary);
        border-radius: 25px;
        background-color: var(--bgPrincipal);
        color: var(--colorSecondary);
        font-size: 16px;
        font-weight: 600;
        height: 50px;
        width: 90%;
        position: relative;
        img{
            padding-right: 10px;
        }
        span{
           position: absolute;
           text-transform: uppercase;
           color: var(--colorPrimary);
           font-weight: 600;
           font-size: 14px;
           background-color: var(--bgPrincipal);
           top: -10px;
           right: 15px;
           min-width: 40%;
        }
    }

    .avatarImage{
        object-fit: cover;
        border-radius: 50%;
        min-width: 40px;
        min-height: 40px;
        max-width: 40px;
        max-height: 40px;
        margin-right: 5px;
    }

    @media (min-width: 1200px) and (max-width: 1520px) {
        .col-xl-3 {
            flex: 0 0 auto !important;
            width: 33.33333333% !important;
        }
    }

    
</style>