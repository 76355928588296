<template>
    <div class="container-fluid justify-content-center" v-if="product">
        <NavbarBuscador/>
        <HeaderTitulo class="d-none d-lg-flex" :tituloHeader="$t('message.HomeCourses')"/>
        <ResumenScroll  :title="product.name"
                        :ownerPic="product.owner.image"
                        :presentation="product.presentation.image"
                        :collabs="product.collabs"
                        :modality="product.details.modality"
                        :participants="product.participants"/>
        <div class="row justify-content-center my-lg-5">
            <div class="col-12 col-lg-11 d-flex justify-content-center align-items-center px-0">
                <div class="row justify-content-center justify-content-lg-between row__width">
                    <div class="col-12 col-lg d-flex flex-column justify-content-center align-items-center px-0 max-width-contenido">
                        <div class="row justify-content-center row__width">
                            <TituloHomeCursos   :tituloCurso="product.name"
                                                :tituloGrande="true" class="d-none d-lg-flex"/>
                            <ProfesorHomeCursos class="d-none d-lg-flex"/>
                            <RateProfesorCurso class="d-none"/>
                            <HomeCursosVideo/>
                            <div class="col-11 col-lg-12 d-flex flex-column justify-content-center align-items-center tabs">
                                <TabsHomeCursos/>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 d-flex justify-content-center align-items-center align-items-lg-start my-4 my-lg-0">
                        <div class="row justify-content-center align-items-center row__width">
                            <PagosHomeCursos :key="componentKey2"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <DespliegueCarro class="d-lg-none"/>
        <Footer class="d-lg-none" id="footer"/>
        <FooterHome class="padding-bottom"/>
        <div class="container-loader" v-if="loader">
            <div class="custom-loader"></div>
        </div>
    </div>
</template>

<script setup>
import DespliegueCarro from '../../components/Carrito/DespliegueCarro.vue';
import Footer from '../../components/Footer/Footer.vue';
import FooterHome from '../../components/Footer/FooterHome.vue';
import HeaderTitulo from "../../components/Titulo/HeaderTitulo.vue";
import HomeCursosVideo from '../../components/Cursos/Video.vue';
import NavbarBuscador from '../../components/Navs/NavbarBuscador.vue';
import NavbarBack from "../../components/Navs/Navbar.vue";
import PagosHomeCursos from "../../components/Cursos/Pagos.vue";
import ProfesorHomeCursos from '../../components/Cursos/Profesor.vue';
import RateProfesorCurso from '../../components/Cursos/RateProfesorCurso.vue';
import ResumenScroll from "../../components/Cursos/ResumenScroll.vue";
import TabsHomeCursos from '../../components/Cursos/Tabs.vue';
import TituloHomeCursos from '../../components/Titulo/TituloHomeCursos.vue';
import { useUsersStore } from "../../stores/user";
import { useVideoStore } from "../../stores/video";
import { useConfigStore } from "../../stores/config"
import { storeToRefs } from "pinia";
import { ref, watch, provide, nextTick, onMounted, onUnmounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useI18n } from "vue-i18n";
import axios from 'axios';

const users = useUsersStore();
const {cambioHeader, idUser} = storeToRefs(users);

const video = useVideoStore();
const {videosDurations, totalTimeVideo} = storeToRefs(video);
const {verifiedPlataform, getTotalDuration} = video;

const config = useConfigStore();
const { coinSelected } = storeToRefs(config);

const router = useRouter();
const route = useRoute();

const {locale} = useI18n({ useScope: 'global' })
const lang = ref('ES')

const url = process.env.VUE_APP_BASE_URL
const urlApi = '/api/course/' // lang - slug? - prodId? - userId?
const urlFetchFaqs = '/api/product/personal/faqs/' // prodId
const urlGetFreeCourse = '/api/suscribe/free/course'

//Precarga parametros de resultados de busqueda
//const product = ref(0)
const product = ref()
const cart = ref([])
let slug = route.params.slug || null
let id = route.params.id || 0
const slugRef = ref('')
let professional = ref()
let loader = ref(true)
let componentKey2 = ref(0)

let insertedNodes = []
let product_type = ref({
    Curso: 'COURSE',
    Descargable: 'DOWNLOADABLE',
    Paquetes: 'PACK'
})

provide('product', product)
provide('cart', cart)
provide('professional', professional)
provide('loader', loader)

const useHeadTags = async (settings = null) => {
  const url = process.env.VUE_APP_BASE_URL

  try {
    let response = await axios.post(`${url}/api/public/seo/products`, { slug: settings.slug, class: settings.class });

    const tagsString = await response.data.data

    const parser = new DOMParser()
    const doc = parser.parseFromString(
      `<html><head>${tagsString}</head><body></body></html>`,
      'text/html'
    )

    const headNodes = Array.from(doc.head.childNodes)

    headNodes.forEach(node => {
      const nodeName = node.nodeName.toLowerCase()

      switch (nodeName) {
        case 'title':
          document.title = node.textContent ?? ''
          break

        case 'meta': {
          const metaName = node.getAttribute('name')
          if (metaName) {
            const existingMeta = document.head.querySelector(`meta[name="${metaName}"]`)
            if (existingMeta) {
              document.head.removeChild(existingMeta)
            }
          }
          const meta = document.createElement('meta')
          Array.from(node.attributes).forEach(attr => {
            meta.setAttribute(attr.name, attr.value)
          })
          document.head.appendChild(meta)
          insertedNodes.push(meta)
          break
        }

        case 'script': {
          const script = document.createElement('script')
          Array.from(node.attributes).forEach(attr => {
            script.setAttribute(attr.name, attr.value)
          })
          script.textContent = node.textContent
          document.head.appendChild(script)
          insertedNodes.push(script)
          break
        }

        case 'link': {
          const link = document.createElement('link')
          Array.from(node.attributes).forEach(attr => {
            link.setAttribute(attr.name, attr.value)
          })
          document.head.appendChild(link)
          insertedNodes.push(link)
          break
        }

        default:
          console.warn(`Etiqueta <${nodeName}> no manejada.`)
      }
    })
    console.warn('TAGS CARGADOS')
  } catch (error) {
    console.error('Error procesando las etiquetas:', error)
  }
}

//Hago un post del usuario x
const fetchSearch = async () => {
    loader.value = true
    try {
        await axios.get(url+urlApi+lang.value+`/`+slug+'/'+id, ).then((result) => {
            console.log(result, 'result product')
            if(Object.keys(result.data).length > 0){
                product.value = result.data
                // console.log(product.value)
                if(result.data.presentation.length == 1){
                    product.value.presentation.image = result.data.presentation.filter(item => item.image)[0].image
                }
                if(result.data.presentation.length == 2){
                    product.value.presentation.image = result.data.presentation.filter(item => item.image)[0].image
                    product.value.presentation.video = result.data.presentation.filter(item => item.video)[0].video
                }
                console.log(product.value.payments)
                // Elimina el resto de precios si hay uno gratuito
                let zeroPrice = product.value.payments.find(p => p.price === 0)
                if (zeroPrice) {
                    product.value.payments = [zeroPrice]
                } else {
                    managePrices()
                }
                product.value.selectedOption = null
            } else{
                router.push({ name: 'PageNotFound' })
            }
            useHeadTags({ class: product_type.value[product.value.class.name], slug: route.params.slug })
        })
        await fetchFaqs()
        product.value.intro.information = await verifiedPlataform(product.value.intro.video)
        // await fetchLessonIds()
        // getTotalDuration(videosDurations.value)
        // product.value.totalTimeVideo = totalTimeVideo
        // console.log(product.value.totalTimeVideo)
        await fetchStatistics()
        setTimeout(() => {
            loader.value = false
        }, 500)
    }
    catch (error) {
        console.log(error)
        setTimeout(() => {
            loader.value = false
        }, 500)
    }
}
fetchSearch()

const fetchFaqs = async () => {
    let id = product.value.product_id
    try {
        await axios.get(url+urlFetchFaqs+id).then((result) => {
            // console.log(result)
            product.value.faqs = result.data
            // console.log(product.value.faqs)
        })
    } catch (error) {
        console.log(error)
    }
}

// Consultar por cada lección 
const fetchLessonIds = async() => {
    for (let i = 0; i < product.value.units.length; i++) {
        let unit = product.value.units[i];
        
        let unitTotalSeconds = 0; // Variable para almacenar el tiempo total en segundos de cada unidad
        let hours = undefined
        let minutes = undefined
        let seconds = undefined
        unit.complete = 0
        // console.log(unit.complete)
        // console.log(unit.length)
        if (unit[0].lessons) {
            for (let lesson of unit[0].lessons) {
                let lessonInfo = await verifiedPlataform(lesson.video)

                if (lessonInfo && lessonInfo.time) {
                    lesson.information = lessonInfo // Asigna la información obtenida a cada lección
                    // const { hours, minutes, seconds } = lessonInfo.time
                    // unitTotalSeconds += (Number(hours) || 0) * 3600 + (Number(minutes) || 0) * 60 + (Number(seconds) || 0)
                }
            }
        }

        // Calcular el tiempo total de la unidad en la propiedad totalTime
        /* hours = Math.floor(unitTotalSeconds / 3600),
        minutes =  Math.floor((unitTotalSeconds - (hours * 3600)) / 60),
        seconds = unitTotalSeconds - (hours * 3600) - (minutes * 60), */
        // Guardar el tiempo total de la unidad en la propiedad totalTime
        console.log(hours, minutes, seconds)
        /* unit.totalTime = {
            hours, minutes, seconds
        };
        unit[0].totalTime = {
            hours, minutes, seconds
        }; */
        // console.log(unit)
        console.log(unitTotalSeconds)
    }
    // console.log(product.value)
}

const fetchProf = async () => {
     console.log(product.value)
    let id = product.value.owner.professional_id
    console.log(id)
    try {
        await axios.get(url+'/api/professionals/get/'+id).then((result) => {
            console.log(result, 'professional result')
            professional.value = result.data
        })
       
    } catch (error) {
        console.log(error)
    }
}

watch(product, () => {
    fetchProf()
})

const getFreeCourse = async() => {
    let pId = product.value.product_id
    let userId = idUser.value.id
    try {
        await axios.post(url+urlGetFreeCourse, {pId: pId, userId: userId}).then(result => {
            // console.log(result)
        })
    } catch (error) {
        console.log(error)
    }
}

provide('getFreeCourse', getFreeCourse)

const managePrices = () => {
    console.log('managePrices')
    if(product.value.payments.length > 0){
        if(product.value.payments[0].price > 0){
            product.value.payments[0].showPrice = product.value.payments[0].price
            if(product.value.payments[0].discount > 0){
                product.value.payments[0].realPrice = parseFloat((product.value.payments[0].showPrice - (product.value.payments[0].showPrice * (product.value.payments[0].discount / 100))).toFixed(2))
            } else{
                product.value.payments[0].realPrice = product.value.payments[0].showPrice
            }
        } else{
            // producto gratuito
            product.value.payments[0].realPrice = 'GRATIS'
            product.value.payments[0].showPrice = null
        }
    }
    console.log(product.value.payments)
    componentKey2.value++
}

watch( coinSelected, async () => {
    managePrices()
}, {deep: true})


// Definir las variables reactivas
const progresos = ref([
    {progreso: 0, titulo: '0%'},
    {progreso: 0, titulo: '0%'},
    {progreso: 0, titulo: '0%'},
    {progreso: 0, titulo: '0%'},
    {progreso: 0, titulo: '0%'}
])

const rates = ref(null)
const noRate = ref([{}, {}, {}, {}, {}])
const totalScore = ref(0)


provide('progresos', progresos)
provide('totalScore', totalScore)
provide('noRate', noRate)
provide('rates', rates)


const fetchStatistics = async () => {
    const body = {
        reviewable_type: "PRODUCT",
        reviewable_slug: product.value.slug
    }

    try {
        const result = await axios.post(url + '/api/public/products/reviews/statistics', body);
        const { stars, quantity, total_score } = result.data.data;
        console.log(result)
        totalScore.value = total_score
        
        console.log(total_score.value, total_score)

        const totalRatings = stars.five + stars.four + stars.three + stars.two + stars.one;
        const averageRating = totalRatings > 0 ? (
        (5 * stars.five + 4 * stars.four + 3 * stars.three + 2 * stars.two + stars.one) / totalRatings
        ) : 0;

        rates.value = totalRatings > 0 ? Array.from({ length: Math.round(averageRating) }, () => ({})) : null;

        if (quantity > 0) {
            progresos.value = [
                { progreso: stars.five * 100 / quantity, titulo: `${stars.five}` },
                { progreso: stars.four * 100 / quantity, titulo: `${stars.four}` },
                { progreso: stars.three * 100 / quantity, titulo: `${stars.three}` },
                { progreso: stars.two * 100 / quantity, titulo: `${stars.two}` },
                { progreso: stars.one * 100 / quantity, titulo: `${stars.one}` }
            ];
        } else {
            progresos.value = [
                { progreso: 0, titulo: '0' },
                { progreso: 0, titulo: '0' },
                { progreso: 0, titulo: '0' },
                { progreso: 0, titulo: '0' },
                { progreso: 0, titulo: '0' }
            ];
        }
    } catch (error) {
            console.error('Error fetching the reviews statistics:', error);
    }
}
provide('fetchStatistics', fetchStatistics)

router.beforeEach(() => {
    insertedNodes.forEach(node => {
    if (document.head.contains(node)) {
        document.head.removeChild(node)
    }
    })
    insertedNodes = []
})
</script>

<style lang="scss" scoped>
@media (min-width: 992px) {
    .max-width-contenido{
        max-width: 58%;
    }
}
@media (max-width: 991px) {
    .resumen{
        display: none;
    }
}
.container-loader{
    background-color: var(--bgPrincipal);
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1045;
}
.custom-loader {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: 
    radial-gradient(farthest-side,#1679FC 94%,#0000) top/8px 8px no-repeat,
    conic-gradient(#0000 30%,#1679FC);
  -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 8px),#000 0);
  mask: radial-gradient(farthest-side,#0000 calc(100% - 8px),#000 0);
  animation:s3 1s infinite linear;
}

@keyframes s3{ 
  100%{transform: rotate(1turn)}
}

.padding-bottom{
    padding-bottom: 100px;
    @media (min-width: 992px) {
        padding-bottom: 0;
    }
}   
</style>