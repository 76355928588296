<template>
    <div class="container-fluid d-flex justify-content-center justify-content-lg-between align-items-start ps-lg-0">
        <NavbarCertificados :seccion="$t('message.products')"/>
        <div  class="col ms-3 mt-3 d-flex justify-content-center align-items-start classSpecial">
            <!--- INDEX --->
            <div class="row justify-content-center justify-content-lg-start row__width">
                <div class="col-12 col-lg d-flex flex-column justify-content-center justify-content-lg-start align-items-center px-0 caja">
                    <!--- INFORMACIÓN --->
                    <form class="row justify-content-center row__width contenedor">
                        <div class="col-12 d-flex flex-column justify-content-center justify-content-lg-start align-items-center contenido">
                            <div class="row justify-content-center row__width py-4">
                                <div class="col-12 d-flex justify-content-start align-items-start titulo px-0">
                                    <h2>Ver información del producto</h2>
                                </div>
                            </div>
                            <div class="row justify-content-center row__width pb-4">
                                <div class="col-5 col-lg-3 d-flex justify-content-center align-items-center introduccion ps-0">
                                    <img src="../../../img/home-icons/ejemplo.png" alt="" class="img">
                                </div>
                                <div class="col-7 col-lg-9 d-flex justify-content-center align-items-center pe-0">
                                    <div class="row justify-content-center row__width">
                                        <div class="col-12 d-flex flex-column justify-content-center align-items-start mb-3 mb-lg-0 mt-lg-3 px-0" >
                                            <h3>Nombre</h3>
                                            <input type="text" v-model="information.title">
                                        </div>
                                        <div class="col-12 d-flex flex-column justify-content-center align-items-start mt-lg-3 px-0" >
                                            <h3>Descripción</h3>
                                            <input type="text" v-model="information.subtitle">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row justify-content-center row__width border-top pt-4">
                                <div class="col-12 d-flex flex-column justify-content-center align-items-center px-0">
                                    <div class="tab-content row justify-content-center row__width" id="myTabContent">
                                        <!--- INFORMACIÓN DEL PRODUCTO --->
                                        <div class="col-12 col-lg-6 d-flex flex-column justify-content-center align-items-start mb-3 mb-lg-0 mt-lg-3 px-0 pe-lg-3" >
                                            <h3>{{ $t('message.courseName') }}</h3>
                                            <input type="text" v-model="information.title">
                                        </div>
                                        <div class="col-12 col-lg-6 d-flex flex-column justify-content-center align-items-start mb-3 mb-lg-0 mt-lg-3 px-0 ps-lg-3" >
                                            <h3>{{ $t('message.courseName') }}</h3>
                                            <input type="text" v-model="information.title">
                                        </div>
                                        <div class="col-12 col-lg-6 d-flex flex-column justify-content-center align-items-start mb-3 mb-lg-0 mt-lg-3 px-0 pe-lg-3" >
                                            <h3>{{ $t('message.courseName') }}</h3>
                                            <input type="text" v-model="information.title">
                                        </div>
                                        <div class="col-12 col-lg-6 d-flex flex-column justify-content-center align-items-start mb-3 mb-lg-0 mt-lg-3 px-0 ps-lg-3" >
                                            <h3>{{ $t('message.courseName') }}</h3>
                                            <input type="text" v-model="information.title">
                                        </div>
                                        <div class="col-12 col-lg-6 d-flex flex-column justify-content-center align-items-start mb-3 mb-lg-0 mt-lg-3 px-0 pe-lg-3" >
                                            <h3>{{ $t('message.courseName') }}</h3>
                                            <input type="text" v-model="information.title">
                                        </div>
                                        <div class="col-12 col-lg-6 d-flex flex-column justify-content-center align-items-start mb-3 mb-lg-0 mt-lg-3 px-0 ps-lg-3" >
                                            <h3>{{ $t('message.courseName') }}</h3>
                                            <input type="text" v-model="information.title">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <FooterSubirCursoBotones/>
                    </form>
                </div>
            </div>
        </div>
        <FooterCertificados :site="true" class="d-lg-none"/>
        <div class="espacio2"></div>
    </div>
</template>

<script setup>
import NavbarCertificados from "../../../components/Navs/NavbarCertificados.vue";
import FooterCertificados from "../../../components/Footer/FooterCertificados.vue";
import ProductosPanel from "../../../components/Panel/Admin/Productos/ProductosPanel.vue";
import NavLateralPanelAdmin from "../../../components/Navs/NavLateralPanelAdmin.vue";

// Importo mi método
import { storeToRefs } from "pinia"
// Llamo a mi tienda de Pinia en forma de Setup 
import { useUsersStore } from "../../../stores/user"
import { useLanguageStore } from "../../../stores/langs"
import { onBeforeMount, ref, provide, watch, onMounted } from "vue"
import { useRoute, useRouter } from 'vue-router';
import axios from 'axios';

const route = useRoute();
const router = useRouter();

// Desestructuro mis returns (es para achicar código, en vez de llamar useUsersStore.usuarios.email se asume que ya sale de ahí) //
const users = useUsersStore();
const information = ref({})
// Mis estados los paso a objetos porque en la tienda están en reactivos //
const {access} = storeToRefs(users);
const {} = users;
const {validate} = users;

const algo = onBeforeMount( () => {
    access.value = [1, 4]
    //console.log(access.value) 
    validate();
}) 

const isSidebarOpen = ref(true)
provide(isSidebarOpen, 'isSidebarOpen')

const toggleSidebar = () => {
    isSidebarOpen.value = !isSidebarOpen.value
    if(document.getElementById('sidebar').classList.contains('d-none')){
        document.getElementById('sidebar').classList.remove('d-none')
    } else{
        document.getElementById('sidebar').classList.add('d-none')
    }
    if(document.getElementById('div-grey').classList.contains('right')){
        document.getElementById('div-grey').classList.remove('right')
        document.getElementById('div-grey').classList.add('left')
    } else{
        document.getElementById('div-grey').classList.remove('left')
        document.getElementById('div-grey').classList.add('right')
    }
}

let insertedNodes = []

const useHeadTags = async (settings = null) => {
    const url = process.env.VUE_APP_BASE_URL

    try {
        let response = await axios.post(`${url}/api/public/seo/pages`, { slug: settings.slug });

        const tagsString = await response.data.data

        const parser = new DOMParser()
        const doc = parser.parseFromString(
        `<html><head>${tagsString}</head><body></body></html>`,
        'text/html'
        )

        const headNodes = Array.from(doc.head.childNodes)

        headNodes.forEach(node => {
        const nodeName = node.nodeName.toLowerCase()

        switch (nodeName) {
            case 'title':
            document.title = node.textContent ?? ''
            break

            case 'meta': {
            const metaName = node.getAttribute('name')
            if (metaName) {
                const existingMeta = document.head.querySelector(`meta[name="${metaName}"]`)
                if (existingMeta) {
                document.head.removeChild(existingMeta)
                }
            }
            const meta = document.createElement('meta')
            Array.from(node.attributes).forEach(attr => {
                meta.setAttribute(attr.name, attr.value)
            })
            document.head.appendChild(meta)
            insertedNodes.push(meta)
            break
            }

            case 'script': {
            const script = document.createElement('script')
            Array.from(node.attributes).forEach(attr => {
                script.setAttribute(attr.name, attr.value)
            })
            script.textContent = node.textContent
            document.head.appendChild(script)
            insertedNodes.push(script)
            break
            }

            case 'link': {
            const link = document.createElement('link')
            Array.from(node.attributes).forEach(attr => {
                link.setAttribute(attr.name, attr.value)
            })
            document.head.appendChild(link)
            insertedNodes.push(link)
            break
            }

            default:
            console.warn(`Etiqueta <${nodeName}> no manejada.`)
        }
        })
        console.warn('TAGS CARGADOS')
    } catch (error) {
        console.error('Error procesando las etiquetas:', error)
    }
}

router.beforeEach(() => {
    insertedNodes.forEach(node => {
    if (document.head.contains(node)) {
        document.head.removeChild(node)
    }
    })
    insertedNodes = []
})

onMounted(() => {
    useHeadTags({ slug: route.fullPath.slice(1) })
})

</script>

<style lang="scss" scoped>
#app, .container-fluid{
    background-color: #fff;
    min-height: 100vh;
}
.contenedor{
   @media (min-width: 992px) {
        background-color: #fff;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 20px;
        padding:20px 0 30px 0;
        min-height: 80vh;
   }
   @media (min-width: 992px) {
        padding-top: 80px;
    }
}
.border-bottom2{
    @media (min-width:992px) {
        border-bottom: 1px solid #E4E4E4;
        padding-bottom: 20px;
    }
}
.classSpecial{
    width: 70vw !important;
}


.contenido{
    @media (max-width: 991px) {
        background-color: #fff;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 20px;
        padding: 0 15px;
    }
    @media (min-width: 992px) {
        padding: 0 30px;
    }
    
    .titulo{
        h2{
            font-weight: 700;
            color: var(--colorSecondary);
            font-size: 25px;
            text-align: left;
        }
    }
    .img{
        max-width: 100%;
        height: 100%;
        border-radius: 16px;
        object-fit: cover;
    }
    .div-gris{
        background-color: #F5F5F5;
        height: auto;
        padding: 20px 20px;
        border-radius: 10px;
        box-shadow: 0px 3px 6px #0000001f;
    }
    h3{
        font-weight: 600;
        color: var(--colorSecondary);
        font-size: 14px;
        text-align: left;
        margin-bottom: 10px !important;
    }
    
    input[type=text], select{
        background-color: #FCFCFC;
        font-weight: 400;
        color: #697891;
        font-size: 16px;
        border-radius: 10px;
        border: 2px solid #E9E3E3;
        height: 50px;
        -webkit-appearance: none;
        width: 100%;
        padding-left: 15px;
        outline: 0;
        &:focus, &:hover{
            border: 2px solid var(--colorPrimary);
        }
    }
}

</style>