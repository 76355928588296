<template>
    <div class="col-12 d-flex flex-column justify-content-start align-items-center px-0" v-for="(item, id) in products">
        <div class="row justify-content-start row__width ps-3 slide">
            <div class="col-3 col-xl-2 d-flex justify-content-center align-items-center px-0 caption">
                <img :src="item.image" alt="" v-if="item.image">
                <img :src="item?.presentations[0]?.url" alt="" v-else-if="item.presentations">
            </div>
            <div class="col-9 col-xl-3 d-flex flex-column justify-content-center align-items-start" v-if="item">
                <h3>{{ item.name }}</h3>
                <h4>{{ item.description }}</h4>
            </div>
            <div class="col-12 col-xl-7 d-flex flex-xl-row justify-content-between align-items-center mt-4 mt-xl-0">
                <div class="row row__width flex-xl-row justify-content-between align-items-center">
                    <div class="col-6 col-xl d-flex  justify-content-center align-items-center px-0 mt-2 mt-lg-0">
                        <div class="row justify-content-center row__width me-1">
                            <div class="col-12 d-flex justify-content-start align-items-center">
                                <h3>{{ $t('message.type') }}</h3>
                            </div>
                            <div class="col-12 d-flex justify-content-between align-items-center pt-2">
                                <button class="acciones px-0">
                                    <img class="me-2" src="../../img/panel-icons/demos-icon.svg" alt="">
                                    {{ item.class.name }}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 col-xl d-flex justify-content-center align-items-center px-0 mt-2 mt-lg-0">
                        <div class="row justify-content-center row__width">
                            <div class="col-12 d-flex justify-content-start align-items-center">
                                <h3>{{ $t('message.status') }}</h3>
                            </div>
                            <div class="col-12 d-flex justify-content-between align-items-center pt-2">
                                <button class="acciones px-0">
                                    {{ item.status }}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl d-flex justify-content-center align-items-center border-left px-0 d-none d-xl-flex">
                        <router-link :to="'/editar-curso/'+item.class.id+'/'+item.id" class="btn__primario mirar__ahora">    
                            {{ $t('message.edit') }}
                        </router-link>
                    </div>
                    <div class="col-xl d-flex justify-content-center align-items-center border-left px-0 d-none d-xl-flex">
                        <button type="button" class="btn__primario mirar__ahora" @click="deleteProduct(item.id)">
                            {{ $t('message.delete') }}
                        </button>
                    </div>
                    <div class="col-6 d-flex justify-content-center align-items-center px-0 d-xl-none my-3">
                        <router-link :to="'/editar-curso/'+item.class.id+'/'+item.id" class="btn__primario mirar__ahora">    
                            {{ $t('message.edit') }}
                        </router-link>
                    </div>
                    <div class="col-6 d-flex justify-content-center align-items-center border-left px-0 d-xl-none my-3">
                        <button type="button" class="btn__primario mirar__ahora" @click="deleteProduct(item.id)">
                            {{ $t('message.delete') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, inject} from "vue";
import Items from "../Carrito/Items.vue";
const id = ref(0)
let products = inject('products')
let deleteProduct = inject('deleteProduct')

</script>

<style lang="scss" scoped>
.slide{
    background-color: #FFFFFF;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    min-height: auto;
    padding: 10px 0;
    margin-bottom: 20px;
    button:not(.mirar__ahora){
        border: 0;
        background-color: transparent;
        outline: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .see-more{
        font-size: 14px;
        color: var(--colorPrimary);
        font-weight: 500;
        text-decoration: underline;
    }
    .border-left{
        border-left: 1px solid rgba($color: #000000, $alpha: 0.2);
    }
    h3{
        color: #0E1133;
        font-weight: 700;
        font-size: 16px;
        text-align: left;
    }
    h4{
        font-weight: 400;
        font-size: 16px;
        color: #B8C1DB;
        text-align: left;
        padding-top: 3px;
        max-height: 60px;
        max-width: 100%;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .acciones{
        color: #B8C1DB;
        font-size: 14px;
        font-weight: 500;

    }
    .dropdown-toggle{
        color: var(--colorSecondary);
        font-weight: 500;
        font-size: 13px;
        text-decoration: underline;
        padding: 0;
        img{
            margin-left: 5px;
        }
        &::after{
            display: none;
        }
        @media (min-width: 425px) {
            font-size: 16px;
        }
    }
    .caption{
        position: relative;
        height:83px ;
        img{
            border-radius: 10px;
            width: 100%;
            height:83px ;
            object-fit: cover;
        }
        &::before{
            content: '';
            position: absolute;
            bottom: 0;
            width: 108%;
            max-width: 100%;
            height: 100%;
            background: transparent linear-gradient(180deg, #252E4800 0%, #081026 100%) 0% 0% no-repeat padding-box;
            filter: opacity(1);
            border-radius: 0px 0px 10px 10px;
            z-index: 1;
        }
    }
}
.mirar__ahora{
    font-size: 14px;
    width: 90%;
    height: 40px;
}
</style>