<template>
    <div class="col-12 d-flex justify-content-center align-items-center d-lg-none" v-if="professional">
        <div class="row row__width">
            <div class="col-12 d-flex flex-column justify-content-center align-items-center">
                <a :href="'/profesionales/'+professional.slug" class="ver_perfil py-2">
                    {{ $t('message.viewProfile') }}
                </a>
            </div>
            <div class="col-12 d-flex justify-content-center align-items-center">
                <div 
                    class="ql-editor w-100 description-box"
                    :class="{ 'collapsed': !isExpanded }"
                    v-html="userAboutCorrected"
                    v-if="description_is_html"
                    ref="descriptionRef">
                </div>

                <p 
                    class="description-box"
                    :class="{ 'collapsed': !isExpanded }"
                    v-else
                    ref="descriptionRef">
                    {{ userAboutCorrected }}
                </p>
            </div>
        </div>
    </div>
    <div v-if="isLongDescription" class="col-12 col-lg-6 d-flex justify-content-center">
        <MostrarMas :funcionTexto="toggleExpand" :img="isExpanded"/>
    </div>
</template>

<script setup>
import { inject, onMounted, ref, watch, nextTick } from 'vue';
import MostrarMas from "../Botones/MostrarMas.vue";

let product = inject('product')
let professional = inject('professional')

const isExpanded = ref(false);
const description_is_html = ref(false);
const userAboutCorrected = ref('');
const isLongDescription = ref(false);
const descriptionRef = ref(null);

const isHtml = (text) => {
  return /<\/?[a-z][\s\S]*>/i.test(text);
};

const fixLinks = (description) => {
  if (!description) return '';

  const div = document.createElement('div');
  div.innerHTML = description;

  div.querySelectorAll('a').forEach(link => {
    const href = link.getAttribute('href');
    if (href && !/^(https?:\/\/|mailto:|tel:|#)/.test(href)) {
      link.setAttribute('href', 'https://' + href);
    }
  });

  return div.innerHTML;
};

const checkLongDescription = () => {
  if (descriptionRef.value) {
    isLongDescription.value = descriptionRef.value.scrollHeight > descriptionRef.value.clientHeight;
  }
};

const seeDescription = () => {
  if (professional.value) {
    const aboutText = professional.value.user?.personal_data?.about || "";
    description_is_html.value = isHtml(aboutText);
    userAboutCorrected.value = description_is_html.value ? fixLinks(aboutText) : aboutText;
    nextTick(checkLongDescription);
  }
};

const toggleExpand = () => {
  isExpanded.value = !isExpanded.value;
};

watch(professional, (newValue) => {
  if (newValue) {
    seeDescription();
  }
});

onMounted(() => {
  seeDescription();
});
</script>

<style lang="scss" scoped>
.texto_mas{
    p, li{
        font-weight: 400;
        font-size: 16px;
        color: var(--colorSecondary);
        text-align: left;
    }
    ul{
        padding: 0;
        max-width: 100%;
        position: relative;
        li{
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            padding-left: 30px;
            min-height: 40px;
            width: 100%;
            margin-bottom: 10px;
            &::before{
                content: url('../../img/home-icons/cursos-icons/tick.png');
                position: absolute;
                left: 0;
                width: 20px;
                height: 20px;
            }
        }
    }
    .seccion{
        padding: 10px 10px;
        img{
            padding-right: 10px;
        }
    }
}
.ver_perfil{
    background-color: var(--bgPrincipal);
    border: 2px solid var(--colorPrimary);
    border-radius: 26px;
    width: 100%;
    font-weight: 600;
    font-size: 16px;
    color: var(--colorPrimary);
    margin: 10px 0px;
}
.max-width{
    max-width: 80%;
    @media (max-width: 992px) {
        max-width: 100%;
    }
}


.description-box {
  display: -webkit-box;
  -webkit-line-clamp: 4; /* Limita a 4 líneas */
  -webkit-box-orient: vertical;
  overflow: hidden;
  transition: max-height 0.3s ease;
  padding-bottom: 1px;
}

.description-box:not(.collapsed) {
  -webkit-line-clamp: unset;
  overflow: visible;
}
</style>