<template>
    <div class="col-12 col-lg-6 d-flex flex-column justify-content-center align-items-start mt-3 skills colaborators mt-3 ps-lg-0">
        <h3>Patrocionadores</h3>
        <multiselect2 
        v-model="information.sponsors"
        mode="tags"
        :close-on-select="false"
        :searchable="true"
        :create-option="false"
        :options="options"
        label="name" trackBy="name" valueProp="id"
        :multipleLabel="multipleLabel" 
        :value="information.sponsors" 
        :placeholder="$t('message.select')" 
        :noResultsText="$t('message.withoutResults')"/>
    </div>
    
</template>

<script setup>
import axios from "axios"
import { useI18n } from "vue-i18n"
import Multiselect from 'vue-multiselect'
import Multiselect2 from '@vueform/multiselect'
import { ref, inject, onMounted, watch } from 'vue';
import { storeToRefs } from "pinia";
import { useLanguageStore } from "../../../stores/langs"
import { useUsersStore } from "../../../stores/user";

const {locale} = useI18n({ useScope: 'global' })
const langs = useLanguageStore()
const {langWeb} = storeToRefs(langs)
const {} = langs


const validez = inject('validez')
const habilities = inject('habilities')
const options = ref([])

let information = inject('information')

const users = useUsersStore()
const { userData } = storeToRefs(users)
const { url } = users


const urlListProf = '/api/professionals/list'

const listProf = async() =>{
    try {
        await axios.get(url+'/api/public/brands').then((result) => {
            console.log(result, 'brands')
            options.value = []
            if (result.status == 200){
                for(let i = 0; i < result.data.data.length; i++){
                    options.value.push( result.data.data[i] )
                }
            }
        })
        //console.log(options.value)
    }
    catch (error) {
        console.log(error)
    }
}
listProf()

const multipleLabel = (values) => {
  return values.map((value) => {
    //console.log(value)
    const option = options.find((option) => option.id === value)
    //console.log(option)
    return option ? option.name : value
  }).join(', ')
}
</script>

<style lang="scss" scoped>
h3{
    color: var(--colorSecondary);
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    padding: 7px 0px;
}
input,select{
    width: 100%;
    border: 2px solid #E9E3E3;
    border-radius: 10px;
    background-color: #FCFCFC;
    height: 50px;
    padding-left: 15px;
    outline: 0;
    color: #697891;
    font-size: 15px;
    font-weight: 400;
    -webkit-appearance: none;
    &:focus, &:hover{
        border: 2px solid #1679FC;
    }
    &::placeholder{
        color: #697891;
    }
}

</style>