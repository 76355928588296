<template>
    <div class="container-fluid usuario-sesion">
        <Navbar class="d-none d-lg-flex" v-if="windowWidth >= 992"/>
        <NavbarBuscador class="d-lg-none" />
        <div class="row justify-content-center ">
            <div class="col-11 margin-top-arreglo">
                <Volver_Inicio/>
            </div>
            <div class="d-flex flex-column justify-content-center align-items-center max-width">
                <RolInicioRegistro/>
                <TituloYSubtituloRegistro :titulo="$t('message.titleLogin')" :subtitulo="$t('message.subtitleHome')" :desaparecerSubtitulo="true"/>
                <!--<h1>{{ tokenAccess }}</h1>-->
                <!-- <div class="row justify-content-center row__width">
                    <BTNFacebook :textoBTNFacebook="$t('message.loginFacebook')"/>
                    <BTNGoogle :textoBTNGoogle="$t('message.loginGoogle')"/>
                   <BTNTelefono :textoBTNTelefono="$t('message.loginPhone')" />
                </div> -->
                <FormLogin/>
                <div class="row justify-content-center row__width margin-top">
                    <RegistrarmeEnlace :cuenta="$t('message.dontHaveAccountYet')" :enlace="$t('message.registerMe')"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import NavbarBuscador from '../../components/Navs/NavbarBuscador.vue';
import Navbar from "../../components/Navs/Navbar.vue";
import Volver_Inicio from '../../components/Volver/Volver_Inicio.vue';
import RolInicioRegistro from '../../components/Roles/RolInicioRegistro.vue';
import TituloYSubtituloRegistro from '../../components/Titulo/TituloYSubtituloRegistro.vue';
import BTNFacebook from '../../components/Botones/Facebook.vue';
import BTNGoogle from '../../components/Botones/Google.vue';
import BTNTelefono from '../../components/Botones/Telefono.vue';
import FormLogin from '../../components/Forms/FormLogin.vue';
import RegistrarmeEnlace from "../../components/Enlaces/RegistrarmeEnlace.vue";
import { onMounted, onUnmounted, ref } from 'vue'
import axios from "axios"

import { useLanguageStore } from "../../stores/langs"
import { storeToRefs } from "pinia";
import { useRoute, useRouter } from 'vue-router';

const route = useRoute()
const router = useRouter();

const langs = useLanguageStore();
const {textos} = storeToRefs(langs);
const {} = langs;

const url = process.env.VUE_APP_BASE_URL
const urlLanguage = '/api/lang/get/full'

const windowWidth = ref(window.innerWidth)

const handleResize = () => {
  windowWidth.value = window.innerWidth
}

let insertedNodes = []

const useHeadTags = async (settings = null) => {
    const url = process.env.VUE_APP_BASE_URL

    try {
        let response = await axios.post(`${url}/api/public/seo/pages`, { slug: settings.slug });

        const tagsString = await response.data.data

        const parser = new DOMParser()
        const doc = parser.parseFromString(
        `<html><head>${tagsString}</head><body></body></html>`,
        'text/html'
        )

        const headNodes = Array.from(doc.head.childNodes)

        headNodes.forEach(node => {
        const nodeName = node.nodeName.toLowerCase()

        switch (nodeName) {
            case 'title':
            document.title = node.textContent ?? ''
            break

            case 'meta': {
            const metaName = node.getAttribute('name')
            if (metaName) {
                const existingMeta = document.head.querySelector(`meta[name="${metaName}"]`)
                if (existingMeta) {
                document.head.removeChild(existingMeta)
                }
            }
            const meta = document.createElement('meta')
            Array.from(node.attributes).forEach(attr => {
                meta.setAttribute(attr.name, attr.value)
            })
            document.head.appendChild(meta)
            insertedNodes.push(meta)
            break
            }

            case 'script': {
            const script = document.createElement('script')
            Array.from(node.attributes).forEach(attr => {
                script.setAttribute(attr.name, attr.value)
            })
            script.textContent = node.textContent
            document.head.appendChild(script)
            insertedNodes.push(script)
            break
            }

            case 'link': {
            const link = document.createElement('link')
            Array.from(node.attributes).forEach(attr => {
                link.setAttribute(attr.name, attr.value)
            })
            document.head.appendChild(link)
            insertedNodes.push(link)
            break
            }

            default:
            console.warn(`Etiqueta <${nodeName}> no manejada.`)
        }
        })
        console.warn('TAGS CARGADOS')
    } catch (error) {
        console.error('Error procesando las etiquetas:', error)
    }
}

router.beforeEach(() => {
    insertedNodes.forEach(node => {
    if (document.head.contains(node)) {
        document.head.removeChild(node)
    }
    })
    insertedNodes = []
})

onMounted(() => {
  window.addEventListener('resize', handleResize)
  useHeadTags({ slug: route.fullPath.slice(1) })
})

onUnmounted(() => {
  window.removeEventListener('resize', handleResize)
})
/*const prueba2 = onMounted(async() =>{
    try {
        await axios.get(url+urlLanguage).then((result) => {
            console.log(result)
        })
    }
    catch (error) {
        console.log(error)
    }
})*/
</script>




<style lang="scss" scoped>
.max-width{
    @media (max-width: 768px) {
        width: 389px !important;
    }
    @media (min-width: 768px) {
        width: 420px !important;
    }
}
</style>