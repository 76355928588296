<template>
    <div class="container-fluid d-flex justify-content-center justify-content-lg-between align-items-start">
        <NavbarCertificados :seccion="$t('message.settings')"/>
        <div class="col d-flex flex-column justify-content-center align-items-center align-items-lg-start px-0 classSpecial" > <!--- :style="isSidebarOpen ? 'padding-left: 300px !important;'  : 'padding-left: 50px !important;'" --->
            <!--- INFORMACIÓN --->
            <form class="row justify-content-center row__width contenedor">
                <div class="col-12 d-flex flex-column justify-content-center justify-content-lg-start align-items-center px-0 ">
                    <TituloAdminAjustes :title="$t('message.generalSettings')"/>
                    <div class="row justify-content-center row__width px-0 px-lg-4 border-top">
                        <div class="col-12  d-flex flex-column justify-content-center align-items-center tablas">
                            <div class="scroll d-flex justify-content-center justify-content-lg-start align-items-center" > 
                                <ul class="nav nav-tabs row justify-content-start justify-content-lg-start" id="myTab" role="tablist">
                                    <li class="nav-item col-6 col-lg-3 d-flex justify-content-center align-items-center" role="presentation">
                                        <button class="nav-link active" id="general-tab" data-bs-toggle="tab" data-bs-target="#general" type="button" role="tab" aria-controls="general" aria-selected="true">{{ $t('message.basicInformation') }}</button>
                                    </li>
                                    <!-- <li class="nav-item col-6 col-lg-3 d-flex justify-content-center align-items-center" role="presentation">
                                        <button class="nav-link " id="courses-tab" data-bs-toggle="tab" data-bs-target="#courses" type="button" role="tab" aria-controls="courses" aria-selected="false">{{ $t('message.products') }}</button>
                                    </li> -->
                                </ul>
                            </div>
                        </div>
                        <div class="col-12 d-flex flex-column justify-content-center align-items-center contenido">
                            <!-- <AgregarConfiguraciones v-if="buttons"/> -->
                            <!--- FAQ --->
                            <PreguntasFrecuentes v-if="actual_page == 1"/>
                            <!--- Beneficios --->
                            <Beneficios v-if="actual_page == 2"/>
                            <!--- Propuesta de valor --->
                            <Valores v-if="actual_page == 3"/>
                            <!--- Configuración académica --->
                            <ConfiguracionAcademica v-if="actual_page == 4"/>
                            <!--- Precios --->
                            <!--- Categorías --->
                            <Categorias  v-if="actual_page == 5"/>
                            <!--- PROFESIONES --->
                            <div class="row justify-content-center row__width professions mt-3" v-if="actual_page == 6 && !professionPage && !specPage">
                                <div class="col-12 d-flex justify-content-center align-items-center filtrosMobile px-0">
                                    <div class="row justify-content-center  row__width">
                                        <div class="col-12 col-sm d-flex justify-content-start align-items-center px-0">
                                            <button class="btn_none px-0 d-flex justify-content-center align-items-center pe-3" @click.prevent="actual_page == 1; professionPage = false">
                                                <img src="../../../img/home-icons/arrow-left-negra.svg" alt="">
                                            </button>
                                            <div class="d-flex flex-column justify-content-center align-items-start">
                                                <h5>Profesiones</h5>
                                                <h6>{{professions.length }} Profesiones</h6>
                                            </div>
                                        </div>
                                        <div class="col-12 col-sm-5 d-flex justify-content-center justify-content-lg-end align-items-center">
                                            <div class="row justify-content-center row__width">
                                                <button type="button" class="col-4 agregar-cursos-btn" @click="showInputAdd(1, null)">
                                                    <span><img src="../../../img/panel-icons/perfil-mas.svg" alt=""></span>
                                                    Agregar profesión
                                                </button>
                                            </div>
                                        </div>
                                        <div class="col-12 d-flex justify-content-center align-items-center contenedor px-0 my-4 d-none">
                                            <input type="text" placeholder="Busca una profesión">
                                            <button><img src="../../../img/home-icons/lupa.svg" alt=""></button>
                                        </div>
                                    </div>
                                </div>
                                <!--- INPUT AÑADIR PROFESION --->
                                <div class="col-12 d-flex flex-column justify-content-center align-items-center">
                                    <div class="row justify-content-center mt-3 w-100 d-none" id="addProfElement1">
                                        <div class="col-12 d-flex justify-content-center align-items-center px-0">
                                            <div class="row justify-content-center row__width py-3">
                                                <div class="col-12 px-0">
                                                    <div class="row d-flex justify-content-start align-items-center mb-2">
                                                        <div class="col-12 col-md-4 d-flex flex-column justify-content-center align-items-start mb-4 mb-md-0">
                                                            <h5 class="mb-2">Añade un nombre</h5>
                                                            <input type="text" placeholder="Añade una profesión" v-model="profName">
                                                        </div>
                                                        <div class="col-12 col-md-4 d-flex flex-column justify-content-center align-items-start mb-4 mb-md-0">
                                                            <h5 class="mb-2">Añade una descripción</h5>
                                                            <input type="text" placeholder="Añade una descripción" v-model="profDescription">
                                                        </div>
                                                        <div class="col-12 col-md-4 d-flex flex-column justify-content-center align-items-start mb-4 mb-md-0">
                                                            <h5 class="mb-2">Selecciona un idioma</h5>
                                                            <select v-model="profLang" @change="checkLangAndDuplicateInputs">
                                                                <option value="ES">Español</option>
                                                                <option value="EN">Inglés</option>
                                                                <option value="FR">Francés</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div v-if="duplicateInputs" class="row d-flex justify-content-start align-items-center mt-4">
                                                        <div class="col-12 col-md-4 d-flex flex-column justify-content-center align-items-start  mb-4 mb-md-0">
                                                            <h5 class="mb-2">Añade un nombre (Español)</h5>
                                                            <input type="text" placeholder="Añade un nombre" v-model="profNameSpanish">
                                                        </div>
                                                        <div class="col-12 col-md-4 d-flex flex-column justify-content-center align-items-start  mb-4 mb-md-0">
                                                            <h5 class="mb-2">Añade una descripción (Español)</h5>
                                                            <input type="text" placeholder="Añade una descripción" v-model="profDescriptionSpanish">
                                                        </div>
                                                        <div class="col-12 col-md-4 d-flex flex-column justify-content-center align-items-start  mb-4 mb-md-0">
                                                            <h5 class="mb-2">Idioma (Español)</h5>
                                                            <select v-model="profLangSpanish" disabled>
                                                            <option value="ES">Español</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12 d-flex justify-content-end align-items-center">
                                                    <button type="button" class="button ms-3" @click="addProf(profName, profDescription, profLang)">
                                                        <img src="../../../img/subir-productos-icons/correct.svg" alt="">
                                                    </button>
                                                    <button type="button" class="button ms-3" @click="addCheck(1)">
                                                        <img src="../../../img/subir-productos-icons/incorrect.svg" alt="">
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row row__width justify-content-center mb-3" v-for="(profession, id) in professions" :key="id">
                                        <div class="col-12 d-flex justify-content-start align-items-center padding-start border-box px-4" v-if="profession.translation">
                                            <div class="col-7 col-lg-9 d-flex flex-column justify-content-center align-items-start">
                                                <h5 v-if="profession.translation">{{ profession.translation.name }}</h5>
                                                <h6 class="mt-2" v-if="profession.translation">{{ profession.translation.description }}</h6>
                                            </div>
                                            <div class="col-5 col-lg-3 d-flex justify-content-end align-items-center">
                                                <input 
                                                    type="checkbox" 
                                                    :checked="profession.active"
                                                    v-model="profession.active" 
                                                    @change="statusProf(profession.active, profession.id)"
                                                    :id="'statusProf'+profession.id"
                                                >
                                                <button type="button" class="button eliminar" @click="getProfession(profession.id)">
                                                    <img src="../../../img/subir-productos-icons/cursos-icons/editar.svg" alt="">
                                                </button>
                                                <button type="button" class="button eliminar" @click="deleteProf(profession.id)">
                                                    <img src="../../../img/subir-productos-icons/cursos-icons/eliminar.svg" alt="">
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!--- FIN PROFESIONES--->
                            <!--- EDITAR PROFESIÓN --->
                            <div class="row justify-content-center row__width professions mt-3" v-if="actual_page == 6 && professionPage && !specPage">
                                <div class="col-12 d-flex justify-content-center align-items-center filtrosMobile px-0">
                                    <div class="row justify-content-center row__width">
                                        <div class="col-12 col-sm d-flex justify-content-start align-items-center px-0">
                                            <button type="button" @click="specPage = false; professionPage = false;"> <img src="../../../img/home-icons/arrow-left-negra.svg" alt=""> </button>
                                            <div class="d-flex flex-column justify-content-center align-items-start">
                                                <h5>Traducciones</h5>
                                                <h6  v-if="profession.translations">{{profession.translations.length }} traducciones</h6>
                                                <h6  v-else>No hay traducciones</h6>
                                            </div>
                                        </div>
                                        <!--- AÑADIR TRADUCCION PROFESIÓN --->
                                        <div class="col-12 col-sm-5 d-flex justify-content-center justify-content-lg-end align-items-center px-0 pe-4 mb-4">
                                            <div class="row justify-content-center row__width">
                                                <button type="button" class="col agregar-cursos-btn agregar-cursos-btn-4" @click="showInputAdd(8, profession.id)">
                                                    <img src="../../../img/panel-icons/perfil-mas.svg" alt="">Añadir traducción
                                                </button>
                                            </div>
                                        </div>
                                        <!--- INPUT AÑADIR --->
                                        <div class="col-12 d-flex justify-content-center align-items-center px-0">
                                            <div class="row justify-content-center row__width" v-if="profession.id">
                                                <div class="col-12 d-flex justify-content-center align-items-center px-0 px-lg-1 d-none mb-4 px-0" :id="'addProfTransElement'+ profession.id">
                                                    <div class="row justify-content-center row__width">
                                                        <div class="col-12 d-flex flex-column justify-content-between align-items-center px-0">
                                                            <div class="row row__width d-flex justify-content-start align-items-center">
                                                                <h4 class="mb-4">Añade una traducción</h4>
                                                                <div class="col-12 col-md-4 d-flex flex-column justify-content-center align-items-start  mb-4 mb-md-0">
                                                                    <h5 class="mb-2">Añade un nombre</h5>
                                                                    <input type="text" placeholder="Añade una traducción" id="addSpecName" v-model="profTransName">
                                                                </div>
                                                                <div class="col-12 col-md-4 d-flex flex-column justify-content-center align-items-start  mb-4 mb-md-0">
                                                                    <h5 class="mb-2">Añade una descripción</h5>
                                                                    <input type="text" placeholder="Añade una traducción" id="addSpecDesc" v-model="profTransDesc">
                                                                </div>
                                                                <div class="col-12 col-md-4 d-flex flex-column justify-content-center align-items-start  mb-4 mb-md-0">
                                                                    <h5 class="mb-2">Selecciona un idioma</h5>
                                                                    <select class="" name="" id="" v-model="profTransLang">
                                                                        <option v-for="lang in unusedLanguages" :key="lang.code" :value="lang.code">{{ lang.name }}</option>
                                                                    </select>
                                                                </div>
                                                                
                                                            </div>
                                                        <div class="row d-flex justify-content-end align-items-center row__width mt-3">
                                                                <button type="button" class="button ms-3" @click="addTransProf(profTransName, profTransDesc, profTransLang, profession.id)">
                                                                    <img src="../../../img/subir-productos-icons/correct.svg" alt="">
                                                                </button>
                                                                <button type="button" class="button ms-3" @click="addCheck(8, profession.id)">
                                                                    <img src="../../../img/subir-productos-icons/incorrect.svg" alt="">
                                                                </button>
                                                        </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!--- FIN AÑADIR TRADUCCION PROFESIÓN --->
                                    </div>
                                </div>
                                <div class="col-12 d-flex justify-content-center align-items-center" v-if="profession">
                                    <div class="row justify-content-start align-items-center row__width">
                                        
                                        <div class="col-12 d-flex flex-column d-flex justify-content-center align-items-center px-0" v-if="profession.translations">
                                            <!---TRADUCCION PROFESIÓN --->
                                            <div class="row row__width justify-content-center mb-3 border-box p-4" v-for="(trans, index) in profession.translations" :key="index">
                                                <div class="col-12 d-flex d-flex justify-content-center align-items-center px-0">
                                                    <div class="d-flex flex-column row__width justify-content-center align-items-start">
                                                        <h5 class="mb-2">{{ trans.name }}</h5>
                                                        <h6 class="mb-2">{{ trans.description }}</h6>
                                                    </div>
                                                    <div class="d-flex row__width justify-content-end align-items-center">
                                                        <button type="button" class="button ms-3" @click="showInputAdd(5, profession.id, index)">
                                                            <img src="../../../img/subir-productos-icons/cursos-icons/editar.svg" alt="Editar">
                                                        </button>
                                                        <button type="button" class="button ms-3" @click="deleteTranslationProf(profession.id, trans.id)">
                                                            <img src="../../../img/subir-productos-icons/cursos-icons/eliminar.svg" alt="Eliminar">
                                                        </button>
                                                    </div>
                                                </div>
                                                <!--- INPUT EDITAR TRADUCCION PROFESIÓN --->
                                                <div class="col-12 my-3 d-none px-0" :id="'inputEditTransProf'+profession.id+index">
                                                    <div class="row row__width justify-content-center justify-content-lg-start">
                                                        <h4 class="my-4 title">Editar traducción</h4>
                                                        <div class="col-12 col-md-4 d-flex flex-column justify-content-center align-items-start mb-4 mb-md-0">
                                                            <h5 class="mb-2">Edita el nombre</h5>
                                                            <input type="text" placeholder="Edite el nombre" v-model="trans.name">
                                                        </div>
                                                        <div class="col-12 col-md-4 d-flex flex-column justify-content-center align-items-start mb-4 mb-md-0">
                                                            <h5 class="mb-2">Edita la descripción</h5>
                                                            <input type="text" placeholder="Edite la descripción" v-model="trans.description">
                                                        </div>
                                                        <div class="col-12 d-flex justify-content-end align-items-center">
                                                            <button class="button button-edit" @click.prevent="editTranslateProf(trans.name, trans.description, profession.id, trans.id, index)">
                                                                Editar
                                                            </button>
                                                        </div>
                                                    </div>  
                                                </div>
                                            </div>
                                            <!--- FIN TRADUCCION PROFESIÓN --->
                                        </div>
                                        <!--- VER ESPECIALIZACIONES TRADUCCION PROFESIÓN --->
                                        <div class="col-12 d-flex justify-content-center align-items-center px-0">
                                            <div class="row justify-content-end row__width">
                                                <button type="button" class="col-12 col-lg-4 agregar-cursos-btn d-flex justify-content-center align-items-center" @click="getProfessionsSpecializations(profession.id)">Ver especializaciones
                                                </button>
                                            </div>
                                        </div>
                                        <!--- FIN VER ESPECIALIZACIONES TRADUCCION PROFESIÓN --->
                                    </div>
                                </div>
                            </div>
                            <!--- FIN EDITAR PROFESIÓN --->
                            <!--- ESPECIALIZACIONES --->
                            <div class="row justify-content-center row__width professions mt-3" v-if="actual_page == 6 && specPage">
                                <div class="col-12 d-flex justify-content-center align-items-center filtrosMobile px-0">
                                    <div class="row justify-content-center row__width">
                                        <div class="col-12 col-sm d-flex justify-content-start align-items-center px-0">
                                            <button type="button" @click="specPage = false; professionPage = true"> <img src="../../../img/home-icons/arrow-left-negra.svg" alt=""> </button>
                                            <div class="d-flex flex-column justify-content-center align-items-start">
                                                <h5>Especializaciones</h5>
                                                <h6 v-if="specializations">{{specializations.length }} especializaciones</h6>
                                                <h6 v-else>No hay especializaciones</h6>
                                            </div>
                                        </div>
                                        <!--- AÑADIR ESPECIALIZACIONES --->
                                        <div class="col-12 col-sm-5 d-flex justify-content-center justify-content-lg-end align-items-center px-0 pe-4 mb-4">
                                            <div class="row justify-content-center row__width">
                                                <button type="button" class="col agregar-cursos-btn agregar-cursos-btn-4" @click="showInputAdd(2, profession.id)">
                                                    <img src="../../../img/panel-icons/perfil-mas.svg" alt="">Añadir especialización
                                                </button>
                                            </div>
                                        </div>
                                        <!--- INPUT AÑADIR ESPECIALIZACIÓN--->
                                        <div class="col-12 d-flex justify-content-center align-items-center mb-3">
                                            <div class="row justify-content-center row__width" v-if="profession.id">
                                                <div class="col-12 d-flex justify-content-center align-items-center px-0 px-lg-1 d-none" :id="'addSpecElement'+ profession.id">
                                                    <div class="row justify-content-center row__width">
                                                        <div class="col-12 d-flex flex-column justify-content-between align-items-center">
                                                            <div class="row d-flex justify-content-start align-items-center">
                                                                <h4 class="mb-4">Añade una especialización</h4>
                                                                <div class="col-12 col-md-4 d-flex flex-column justify-content-center align-items-start mb-4 mb-md-0">
                                                                    <h5 class="mb-2">Añade un nombre</h5>
                                                                    <input type="text" placeholder="Añade una especialización" id="addSpecName" v-model="specName">
                                                                </div>
                                                                <div class="col-12 col-md-4 d-flex flex-column justify-content-center align-items-start mb-4 mb-md-0">
                                                                    <h5 class="mb-2">Añade una descripción</h5>
                                                                    <input type="text" placeholder="Añade una especialización" id="addSpecDesc" v-model="specDesc">
                                                                </div>
                                                                <div class="col-12 col-md-4 d-flex flex-column justify-content-center align-items-start mb-4 mb-md-0">
                                                                    <h5 class="mb-2">Selecciona un idioma</h5>
                                                                    <select name="" id="" v-model="specLang" @change="checkLangAndDuplicateInputsSpec()">
                                                                        <h5 class="mb-2">Selecciona un idioma</h5>
                                                                        <option value="ES">Español</option>
                                                                        <option value="EN">Inglés</option>
                                                                        <option value="FR">Francés</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div v-if="duplicateInputsSpec" class="row d-flex justify-content-start align-items-center mt-4">
                                                                <div class="col-12 col-md-4 d-flex flex-column justify-content-center align-items-start mb-4 mb-md-0">
                                                                    <h5 class="mb-2">Añade un nombre (Español)</h5>
                                                                    <input type="text" placeholder="Añade un nombre" v-model="specNameSpanish">
                                                                </div>
                                                                <div class="col-12 col-md-4 d-flex flex-column justify-content-center align-items-start mb-4 mb-md-0">
                                                                    <h5 class="mb-2">Añade una descripción (Español)</h5>
                                                                    <input type="text" placeholder="Añade una descripción" v-model="specDescriptionSpanish">
                                                                </div>
                                                                <div class="col-12 col-md-4 d-flex flex-column justify-content-center align-items-start mb-4 mb-md-0">
                                                                    <h5 class="mb-2">Idioma (Español)</h5>
                                                                    <select v-model="specLangSpanish" disabled>
                                                                        <option value="ES">Español</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div class="row d-flex justify-content-end align-items-center row__width mt-3">
                                                                <button type="button" class="button ms-3" @click="addSpec(specName, specDesc, specLang, profession.id)">
                                                                    <img src="../../../img/subir-productos-icons/correct.svg" alt="">
                                                                </button>
                                                                <button type="button" class="button ms-3" @click="addCheck(2, profession.id)">
                                                                    <img src="../../../img/subir-productos-icons/incorrect.svg" alt="">
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!--- FIN AÑADIR ESPECIALIZACIONES --->
                                    </div>
                                </div>
                                <div class="col-12 d-flex justify-content-center align-items-center px-0" v-if="specializations">
                                    <div class="row justify-content-start align-items-center row__width">
                                        <!--- ESPECIALIZACIONES --->
                                        <div class="col-12 d-flex flex-column justify-content-center align-items-center" v-if="specializations">
                                            <div class="row row__width mb-3 border-box p-3" v-for="(speciality, index) in specializations" :key="index">
                                                <div class="col-12 justify-content-center align-items-center my-3 px-0" v-if="speciality.translation">
                                                    <div class="row row__width justify-content-center">
                                                        <div class="col-12 col-sm-6 d-flex flex-column justify-content-center align-items-start">
                                                            <h5 class="mb-3">{{ speciality.translation.name }}</h5>
                                                            <h6 class="mb-3">{{ speciality.translation.description }}</h6>
                                                        </div>
                                                        <div class="col-12 col-sm-6 d-flex justify-content-end align-items-center px-0">
                                                            <input type="checkbox" :checked="speciality.active" v-model="speciality.active" @change="statusSpec(speciality.active, speciality.id)" :id="'statusSpec'+speciality.id" />
                                                            <button type="button" class="button" @click="deleteSpec(speciality.id, profession.id)">
                                                                <img src="../../../img/subir-productos-icons/cursos-icons/eliminar.svg" alt="" />
                                                            </button>
                                                            <button class="button-edit button-edit-2 ms-2" @click.prevent="getTranslationSpec(speciality.id)">Ver traducciones</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!--- TRADUCCIONES ESPECIALIZACIONES --->
                                                <div class="col-12 d-flex flex-column justify-content-center align-items-center px-0" v-if="specTrans[speciality.id] && specTrans[speciality.id].length">
                                                    <div class="row row__width mb-3" v-for="(trans, index) in specTrans[speciality.id]" :key="index">
                                                        <div class="col-12 d-flex flex-column justify-content-center align-items-center px-0">
                                                            <div class="row row__width justify-content-center">
                                                                <div class="col-6 d-flex flex-column justify-content-center align-items-start">
                                                                    <h5 class="mb-3">{{ trans.name }}</h5>
                                                                    <h6 class="mb-3">{{ trans.description }}</h6>
                                                                </div>
                                                                <div class="col-6 d-flex justify-content-end align-items-center">
                                                                    <button type="button" class="button ms-3" @click="showInputAdd(6, speciality.id, trans.id)">
                                                                        <img src="../../../img/subir-productos-icons/cursos-icons/editar.svg" alt="Editar" />
                                                                    </button>
                                                                    <button type="button" class="button ms-3" @click="deleteTranslationSpec(speciality.id, trans.id, profession.id)">
                                                                        <img src="../../../img/subir-productos-icons/cursos-icons/eliminar.svg" alt="Eliminar" />
                                                                    </button>
                                                                </div>
                                                                <!--- INPUT EDITAR TRADUCCIÓN PROFESIÓN --->
                                                                <div class="col-12 d-flex justify-content-center align-items-center px-0">
                                                                    <div class="row row__width my-3 d-none" :id="'inputEditTransSpec' + speciality.id + trans.id">
                                                                        <h4 class="my-4">Editar traducción</h4>
                                                                        <div class="col-12 col-md-4 d-flex flex-column justify-content-center align-items-start mb-4 mb-md-0">
                                                                            <h5 class="mb-2">Edita el nombre</h5>
                                                                            <input type="text" placeholder="Edite el nombre" v-model="trans.name" />
                                                                        </div>
                                                                        <div class="col-12 col-md-4 d-flex flex-column justify-content-center align-items-start mb-4 mb-md-0">
                                                                            <h5 class="mb-2">Edita la descripción</h5>
                                                                            <input type="text" placeholder="Edite la descripción" v-model="trans.description" />
                                                                        </div>
                                                                        <div class="col-12 d-flex justify-content-end align-items-center mt-3">
                                                                            <button type="button" class="button button-edit" @click.prevent="editTranslateSpec(trans.name, trans.description, profession.id, trans.id, speciality.id)">
                                                                                Editar
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row row__width mt-3">
                                                        <div class="col-12 d-flex justify-content-end align-items-center px-0">
                                                            <button type="button" class="col agregar-cursos-btn ms-2" @click="showInputAdd(9, speciality.id)">
                                                                <img src="../../../img/panel-icons/perfil-mas.svg" alt="" class="mx-2" />
                                                                Añadir traducción
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!--- FIN TRADUCCIONES ESPECIALIZACIONES --->
                                                <div class="col-12 d-flex justify-content-center align-items-center px-0">
                                                    <div class="row justify-content-center row__width" v-if="speciality.id">
                                                        <div class="col-12 d-flex flex-column justify-content-center align-items-start px-0 px-lg-1 d-none" :id="'addTransSpecElement'+ speciality.id">
                                                            <div class="row d-flex justify-content-start align-items-center">
                                                                <h4 class="mb-4">Añade una traducción</h4>
                                                                <div class="col-12 col-md-4 d-flex flex-column justify-content-center align-items-start mb-4 mb-md-0">
                                                                    <h5 class="mb-2">Añade un nombre</h5>
                                                                    <input type="text" placeholder="Añade una traducción" id="addSpecName" v-model="specTransName" />
                                                                </div>
                                                                <div class="col-12 col-md-4 d-flex flex-column justify-content-center align-items-start mb-4 mb-md-0">
                                                                    <h5 class="mb-2">Añade una descripción</h5>
                                                                    <input type="text" placeholder="Añade una traducción" id="addSpecDesc" v-model="specTransDesc" />
                                                                </div>
                                                                <div class="col-12 col-md-4 d-flex flex-column justify-content-center align-items-start mb-4 mb-md-0">
                                                                    <h5 class="mb-2">Selecciona un idioma</h5>
                                                                    <select class="" name="" id="" v-model="specTransLang">
                                                                        <option v-for="lang in unusedLanguages" :key="lang.code" :value="lang.code">{{ lang.name }}</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div class="row d-flex justify-content-end align-items-center row__width mt-3">
                                                                <button type="button" class="button ms-3" @click="addTransSpec(specTransName, specTransDesc, specTransLang, speciality.id)">
                                                                    <img src="../../../img/subir-productos-icons/correct.svg" alt="" />
                                                                </button>
                                                                <button type="button" class="button ms-3" @click="addCheck(9, speciality.id)"><img src="../../../img/subir-productos-icons/incorrect.svg" alt="" /></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!--- FIN ESPECIALIZACIONES --->
                                    </div>
                                </div>
                            </div>
                            <!-- REVIEWS -->
                            <div class="row justify-content-center row__width mt-3 reviews" v-if="actual_page == 7">
                                <div class="col-12 d-flex flex-column justify-content-center align-items-center  px-0">
                                    <div class="row justify-content-center  row__width filtrosMobile">
                                        <div class="col-12 col-sm d-flex justify-content-start align-items-center px-0">
                                            <button class="btn_none px-0 d-flex justify-content-center align-items-center pe-3" 
                                            @click.prevent="actual_page == 1; professionPage = false">
                                                <img src="../../../img/home-icons/arrow-left-negra.svg" alt="">
                                            </button>
                                            <div class="d-flex flex-column justify-content-center align-items-start mb-3">
                                                <h5>Reviews</h5>
                                                <h6>{{reviews?.data?.length }} reviews</h6>
                                            </div>
                                        </div>
                                        <!-- <div class="col-12 col-sm-5 d-flex justify-content-center justify-content-lg-end align-items-center">
                                            <div class="row justify-content-center row__width">
                                                <button type="button" class="col-4 agregar-cursos-btn" @click="showInputAdd(1, null)">
                                                    <span><img src="../../../img/panel-icons/perfil-mas.svg" alt=""></span>
                                                    Agregar review
                                                </button>
                                            </div>
                                        </div> -->
                                    </div>
                                        <div class="row row__width justify-content-center mb-3" v-for="(review, id) in reviews.data" :key="id">
                                            <div class="col-12 d-flex justify-content-center align-items-center padding-start border-box px-4" v-if="review.comment">
                                                <div class="row row__width justify-content-center">
                                                    <div class="col-12 col-md-9 d-flex flex-column justify-content-center align-items-start">
                                                        <p class="title mb-2">Producto reseñado: {{ review?.reviewable?.name }}</p>
                                                        <h5 v-if="review.reviewer">Reseñador: {{ review.reviewer.name }}</h5>
                                                        <h6 class="mt-2" v-if="review.score">Rating: {{ review.score }}</h6>
                                                        <!-- Contenedor de la reseña -->
                                                        <div class="review-container d-flex flex-column justify-content-center align-items-start">
                                                            <p ref="reviewText" :class="{ 'clamped': !expanded[id] }">
                                                                Reseña: {{ review.comment }}
                                                            </p>
                                                            <button type="button" v-if="showMoreButton[id]" @click="toggleExpand(id)" class="btn-none">
                                                                {{ expanded[id] ? 'Ver menos' : 'Ver más' }}
                                                            </button>
                                                        </div>
                                                    </div>
                                                    
                                                    <div class="col-12 col-md-3 d-flex justify-content-end align-items-center">
                                                        <button type="button" class="button eliminar" @click="approvedReview(review.id)">
                                                            <img src="../../../img/subir-productos-icons/cursos-icons/correct.svg" alt="">
                                                        </button>
                                                        <button type="button" class="button eliminar" @click="desapprovedReview(review.id)">
                                                            <img src="../../../img/subir-productos-icons/cursos-icons/incorrect.svg" alt="">
                                                        </button>
                                                        <button type="button" class="button eliminar" @click="deleteReview(review.id)">
                                                            <img src="../../../img/subir-productos-icons/cursos-icons/eliminar.svg" alt="">
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row  row__width justify-content-center mt-3">
                                            <!-- Paginación -->
                                            <template v-if="reviews">
                                                <nav v-if="reviews.links" class="py-2">
                                                    <ul class="pagination">
                                                        <li v-for="(link, index) in reviews.links" :key="link.label" :class="{'active': link.active}" class="page-item">
                                                            <a v-if="link.url" class="page-link" href="#" v-html="link.label" @click.prevent="getReviews(link.url)"></a>
                                                            <span v-else class="page-link" v-html="link.label"></span>
                                                        </li>
                                                    </ul>
                                                </nav>
                                            </template>
                                        </div>
                                        <!-- <Loader loading="loader"/> -->
                                    
                                </div>
                            </div>
                        </div>
                        <!--- PRODUCTOS --->
                        <div class="col-12 px-0" >
                            <!-- <AgregarConfiguracionesProductos v-if="buttons2"/> -->
                            <!--- tipos de productos --->
                            <!-- <Productos v-show="viewsProducts == 1"/> -->
                            <!--- Modalidades --->
                            <!-- <Modalidades v-show="viewsProducts == 2"/> -->
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <FooterCertificados :upload="true" class="d-lg-none"/>
        <div class="espacio2"></div>
    </div>
</template>

<script setup>
import NavbarCertificados from "../../../components/Navs/NavbarCertificados.vue";
import FooterCertificados from "../../../components/Footer/FooterCertificados.vue";
import NavLateralPanelAdmin from "../../../components/Navs/NavLateralPanelAdmin.vue";
import AgregarConfiguraciones from "../../../components/Panel/Admin/Ajustes/AgregarConfiguraciones.vue";
import TituloAdminAjustes from "../../../components/Titulo/TituloAdminAjustes.vue";
import PreguntasFrecuentes from "../../../components/Panel/Admin/Ajustes/PreguntasFrecuentes.vue";
import Productos from "../../../components/Panel/Admin/Ajustes/Productos.vue";
import Modalidades from "../../../components/Panel/Admin/Ajustes/Modalidades.vue";
import ConfiguracionAcademica from "../../../components/Panel/Admin/Ajustes/ConfiguracionAcademica.vue";
import Beneficios from "../../../components/Panel/Admin/Ajustes/Beneficios.vue";
import Valores from "../../../components/Panel/Admin/Ajustes/Valores.vue";
import Categorias from "../../../components/Panel/Admin/Ajustes/Categorias.vue";
import AgregarConfiguracionesProductos from "../../../components/Panel/Admin/Ajustes/AgregarConfiguracionesProductos.vue";
import { useI18n } from "vue-i18n"
import { storeToRefs } from "pinia"
import { useUsersStore } from "../../../stores/user"
import { useLanguageStore } from "../../../stores/langs"
import { onBeforeMount, ref, provide, watch, onMounted, nextTick  } from "vue"
import axios from 'axios';
import Swal from 'sweetalert2'
import Loader from "@/components/Filtros/Loader.vue";
import { useRoute, useRouter } from "vue-router";

const route = useRoute();
const router = useRouter();

const {locale} = useI18n({ useScope: 'global' })



const users = useUsersStore();
const {access} = storeToRefs(users);
const {url} = users;
const {validate} = users;

const langs = useLanguageStore();
const {listLangs} = storeToRefs(langs);

const listTypes = ref([])
const listModalities = ref([])
const listProfs = ref([])
const listBenefits = ref([])
const listIcons = ref([])
const listValues = ref([])
const listFaqs = ref([])

provide('listTypes', listTypes)
provide('listModalities', listModalities)
provide('listProfs', listProfs)
provide('listBenefits', listBenefits)
provide('listIcons', listIcons)
provide('listValues', listValues)
provide('listFaqs', listFaqs)

const algo = onBeforeMount( () => {
    access.value = [1]
    //console.log(access.value)
    validate();
})

const views = ref(7)
provide("views", views)

const viewsProducts = ref(0)
provide("viewsProducts", viewsProducts)

const buttons = ref(true)
provide("buttons", buttons)

const buttons2 = ref(true)
provide("buttons2", buttons2)

const tabs = ref(true)
provide("tabs", tabs)


const actual_page = ref(7)
const loader = ref(false)

const changePage = (page) => {
    actual_page.value = page
}

provide('actual_page', actual_page)
provide('changePage', changePage)


//-----------PROFESSIONS --------------//
const urlProfs = '/api/admin/professions'
const urlProf = '/api/admin/professions/'

const urlAddSpec = '/api/admin/specializations'
const urlDeleteSpec = '/api/admin/specializations/'
const urlEditSpec = '/api/admin/specializations/'
const urlShowTransSpec = '/api/admin/specializations/'
const urlAddTransSpec = "/api/admin/professions/"
const urlDeleteTransSpec = '/api/admin/specializations/'



const professions = ref(0)
const profession = ref(null)
const professionPage = ref(null)

const professionTrans = ref(null)

const profName = ref(null);
const profDescription = ref(null);
const profLang = ref(null);
const profNameSpanish = ref(null);
const profDescriptionSpanish = ref(null);
const profLangSpanish = ref('ES');
const duplicateInputs = ref(false);


const profTransName = ref(null);
const profTransDesc = ref(null);
const profTransLang = ref(null);


const specializations = ref (null)
const specTrans = ref({})

const specTransName = ref(null);
const specTransDesc = ref(null);
const specTransLang = ref(null);

const specName = ref(null)
const specDesc= ref(null)
const specLang = ref(null)

const specNameSpanish = ref(null);
const specDescriptionSpanish = ref(null);
const specLangSpanish = ref('ES');
const duplicateInputsSpec = ref(false);

const specPage = ref(null)

const reviews = ref([])

// Lista de idiomas disponibles
const availableLanguages = ref([
  { code: 'ES', name: 'Español' },
  { code: 'EN', name: 'Inglés' },
  { code: 'FR', name: 'Francés' }
]);

// Lista de idiomas no utilizados
const unusedLanguages = ref([]);


provide("professionPage", professionPage)

//----------------- Reviews ---------------- //
const expanded = ref({});
const showMoreButton = ref({});
const reviewText = ref([]);

//console.log(url+urlProfs)

const checkLangAndDuplicateInputs = () => {
  duplicateInputs.value = profLang.value === 'EN' || profLang.value === 'FR';
};
const checkLangAndDuplicateInputsSpec = () => {
    duplicateInputsSpec.value = specLang.value === 'EN' || specLang.value === 'FR';
};

const showMessage = (icon, message) => {
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    }) 
    Toast.fire({
        icon: icon == true ? 'success' : 'error',
        title: message
    })
} 

const showInputAdd = (action, id = null, index = null) => {
    if (action == 1){
        let element = document.getElementById('addProfElement1')
        element.classList.remove('d-none')
    }
    else if (action == 2){
        let element = document.getElementById('addSpecElement'+id)
        console.log(id)
        console.log(element)
        element.classList.toggle('d-none')
    }
    else if (action == 3){
        let element = document.getElementById('editProfElement'+id)
        element.classList.toggle('d-none')
    }
    else if (action == 4){
        let element = document.getElementById('editSpecElement'+id+index)
        element.classList.toggle('d-none')
    }
    else if (action == 5){
        let element = document.getElementById('inputEditTransProf'+id+index)
        console.log(element)
        element.classList.toggle('d-none')
    }
    else if (action == 6){
        let element = document.getElementById('inputEditTransSpec'+id+index)
        //console.log(element)
        //console.log('inputEditTransSpec'+id+index)
        element.classList.toggle('d-none')
    }
    else if (action == 7){
        let element = document.getElementById('editSpecTransElement'+id)
        console.log(element)
        element.classList.toggle('d-none')
    }
    else if (action == 8){
        let element = document.getElementById('addProfTransElement'+id)
        element.classList.toggle('d-none')
    }
    else if (action == 9){
        let element = document.getElementById('addTransSpecElement'+id)
        element.classList.toggle('d-none')
    }
}
const addCheck = (action, id = null, index = null) => {
    if (action == 1){
        let element = document.getElementById('addProfElement1')
        element.classList.add('d-none')
        profName.value = ''
        profDescription.value = ''
    }
    else if (action == 2){
        let element = document.getElementById('addSpecElement'+id)
        element.classList.add('d-none')
    }
    else if (action == 3){
        let element = document.getElementById('editProfElement'+id)
        element.classList.add('d-none')
    }
    else if (action == 5){
        let element = document.getElementById('inputEditTransProf'+id+index)
        element.classList.add('d-none')
    }
    else if (action == 6){
        let element = document.getElementById('inputEditTransSpec'+id+index)
        element.classList.add('d-none')
    }
    else if (action == 7){
        let element = document.getElementById('editSpecTransElement'+id)
        console.log(element)
        element.classList.add('d-none')
        specTransName.value = '',
        specTransDesc.value = '',
        specTransLang.value = ''
    }
    else if (action == 8){
        let element = document.getElementById('addProfTransElement'+id)
        console.log(element)
        element.classList.add('d-none')
        profTransName.value = '',
        profTransDesc.value = '',
        profTransLang.value = ''
    }
    else if (action == 9){
        let element = document.getElementById('addTransSpecElement'+id)
        element.classList.add('d-none')
    }
}

const getProfessions = async(name) => {
    const token = JSON.parse(localStorage.getItem('token'))
    const headers = ref({'Authorization': `Bearer ${token}`})
    const lang = 'ES'
    try {
        await axios.get(url+urlProfs, {headers: headers.value}).then((response) => {
            console.log(response.data.data)
            professions.value = response.data.data
        })
    } catch (error) {
        console.error(error)
    }
    
}
getProfessions()

const getTranslationProfession = async(idProf) => {
    const token = JSON.parse(localStorage.getItem('token'))
    const headers = ref({'Authorization': `Bearer ${token}`})
    const lang = 'ES'
    console.log(url+'/api/admin/users/1/professional/data')
    console.log(headers.value)
    try {
        await axios.get(url+urlProf+idProf, {headers: headers.value}).then((response) => {
            console.log(response.data.data)
            console.log(profession.value)
            profession.value.translations = response.data.data.translations
        })
    } catch (error) {
        console.error(error)
    }
    
}

const getProfession = async(idProf) => {
    const token = JSON.parse(localStorage.getItem('token'))
    const headers = ref({'Authorization': `Bearer ${token}`})
    try {
        await axios.get(url+urlProf+idProf, {headers: headers.value}).then((response) => {
            console.log(response.data.data)
            profession.value = response.data.data
            console.log(profession.value)
            const usedLanguages = profession.value.translations.map(trans => trans.language);
            unusedLanguages.value = availableLanguages.value.filter(lang => !usedLanguages.includes(lang.code));
        })
        if(profession.value){
            professionPage.value = true
            //getTranslationProfession(profession.value.id)
        }
    } catch (error) {
        console.error(error)
    }
    
}


const addProf = async () => {
  const token = JSON.parse(localStorage.getItem('token'));
  const headers = { 'Authorization': `Bearer ${token}` };
  
  const translations = [
    {
      language: profLang.value,
      name: profName.value,
      description: profDescription.value,
    }
  ];
  
  if (duplicateInputs.value) {
    translations.push({
      language: profLangSpanish.value,
      name: profNameSpanish.value,
      description: profDescriptionSpanish.value,
    });
  }
  try {
    const response = await axios.post(url + urlProfs, { translations }, { headers });
    console.log(response);
    getProfessions();
    addCheck(1)
    showMessage(true, response.data.message)
  } catch (error) {
    console.error(error);
    if(error){
        showMessage(false, error.response.data.message)
    } 
  }
};

const addTransProf = async (name, description, lang, idProf) => {
    const token = JSON.parse(localStorage.getItem('token'));
    const headers = ref({ 'Authorization': `Bearer ${token}` });
    const info = {
        language: lang,
        name: name,
        description: description
    };
    console.log(token, headers.value)
    try {
        const response = await axios.post(url + urlProf + idProf + '/translations', info, {headers: headers.value});
        console.log(response.data.data);
        getTranslationProfession(idProf);
        addCheck(8, idProf)
        showMessage(true, response.data.message)
    } catch (error) {
        console.error('Error adding translation:', error);
        if(error){
            showMessage(false, error.response.data.message)
        }   
       
    }
};


const editTranslateProf = async(name, description, idProf, idTranslation, index) => {
    const token = JSON.parse(localStorage.getItem('token'))
    const headers = ref({'Authorization': `Bearer ${token}`})
    const info = {
        name: name,
        description:  description
    }
    try {
        await axios.put(url+urlProf+idProf+ '/translations/'+idTranslation, { info }, {headers: headers.value}).then((response) => {
            console.log(response)
            getProfessions()
            addCheck(5, idProf, index)
            showMessage(true, response.data.message)
        })
    } catch (error) {
        console.error(error)
        if(error){
            showMessage(false, error.response.data.message)
        } 
    }
}

const statusProf = async (status, idProf) => {
    const token = JSON.parse(localStorage.getItem('token'))
    const headers = ref({'Authorization': `Bearer ${token}`})
    console.log(headers.value)
    if(status){
        try {
            await axios.patch(url + urlProf + idProf + '/enable', {}, {headers: headers.value}).then((response) => {
                console.log(response)
                getProfessions()
                console.log('actualizado')
                showMessage(true, response.data.message)
            })
        } catch (error) {
            console.error(error);
            if(error){
                showMessage(false, error.response.data.message)
            } 
        }
    } else if(!status){
        try {
            await axios.patch(url + urlProf + idProf + '/disable', {}, {headers: headers.value}).then((response) => {
                console.log(response)
                getProfessions()
                console.log('actualizado')
                showMessage(true, response.data.message)
            })
        } catch (error) {
            console.error(error);
            if(error){
                showMessage(false, error.response.data.message)
            } 
        }
    }
}

const deleteProf = async(id, idProf) => {
    const token = JSON.parse(localStorage.getItem('token'))
    const headers = ref({'Authorization': `Bearer ${token}`})

    try {
        const response = await axios.delete(url + urlProf + id, { headers: headers.value })
        console.log(response)
        getProfessions()
        showMessage(true, response.data.message)
    } catch (error) {
        console.error('Error al eliminar la profesión:', error)
        if(error){
            showMessage(false, error.response.data.message)
        } 
    }
}

const deleteTranslationProf = async(idProf, idTrans) => {
    const token = JSON.parse(localStorage.getItem('token'))
    const headers = ref({'Authorization': `Bearer ${token}`})

    try {
        const response = await axios.delete(url + urlProf + idProf + '/translations/' + idTrans, { headers: headers.value })
            console.log(response)
            getProfession(idProf)
            //getProfessionsSpecializations(idProf)
            console.log('Eliminado correctamente');
            showMessage(true, response.data.message)
    } catch (error) {
        console.error('Error al eliminar la profesión:', error)
        if(error){
            showMessage(false, error.response.data.message)
        }  
    }
}


//----------  specializations ---------//

const getProfessionsSpecializations = async (id) => {
    const token = JSON.parse(localStorage.getItem('token'));
    const headers = ref({ 'Authorization': `Bearer ${token}` });
    const lang = 'ES';
    console.log(id)
    try {
        const response = await axios.get(url+`/api/admin/specializations?professionId=${id}`, { headers: headers.value });
        console.log(response.data.data);
        specializations.value = response.data.data;
        professionPage.value = false
        specPage.value = true
    } catch (error) {
        console.error('Error fetching specializations:', error);
    }
};

const getAllProfessionsSpecializations = async (id) => {
    const token = JSON.parse(localStorage.getItem('token'));
    const headers = ref({ 'Authorization': `Bearer ${token}` });
    const lang = 'ES';
    console.log(id)
    try {
        const response = await axios.get(url+`/api/admin/specializations/${id}`, { headers: headers.value });
        console.log(response.data.data);
        specializations.value = response.data.data;
        professionPage.value = false
        specPage.value = true
    } catch (error) {
        console.error('Error fetching specializations:', error);
    }
};


const getTranslationSpec = async (idSpec) => {
    const token = JSON.parse(localStorage.getItem('token'));
    const headers = { 'Authorization': `Bearer ${token}` };
    try {
        const response = await axios.get(url + urlShowTransSpec + idSpec, { headers: headers });
        console.log(response.data.data, 'especializaciones translate');
        
        // Actualizar el array de traducciones, eliminando las que ya no existen
        if (specTrans.value[idSpec]) {
            // Obtener la lista de IDs de las traducciones actuales en el servidor
            const updatedTranslations = response.data.data.translations || [];
            const updatedTranslationIds = updatedTranslations.map(trans => trans.id);

            // Filtrar las traducciones en specTrans para eliminar las que no están en updatedTranslations
            specTrans.value[idSpec] = specTrans.value[idSpec].filter(trans => updatedTranslationIds.includes(trans.id));

            // Reemplazar o agregar las traducciones que están en updatedTranslations
            updatedTranslations.forEach(updatedTranslation => {
                const index = specTrans.value[idSpec].findIndex(trans => trans.id === updatedTranslation.id);
                if (index !== -1) {
                    specTrans.value[idSpec][index] = updatedTranslation;
                } else {
                    specTrans.value[idSpec].push(updatedTranslation);
                }
            });
        } else {
            // Si no existe en specTrans, simplemente asignar el nuevo array
            specTrans.value[idSpec] = response.data.data.translations || [];
        }

        console.log(specTrans.value);
        const usedLanguages = specTrans.value[idSpec].map(trans => trans.language);
        unusedLanguages.value = availableLanguages.value.filter(lang => !usedLanguages.includes(lang.code));
    } catch (error) {
        console.error(error);
    }
};


const addSpec = async (name, description, lang, idProf) => {
    const token = JSON.parse(localStorage.getItem('token'));
    const headers = { 'Authorization': `Bearer ${token}` };
    const translations = [
        {
        language: specLang.value,
        name: specName.value,
        description: specDesc.value,
        }
    ];
    
    if (duplicateInputsSpec.value) {
        translations.push({
            language: specLangSpanish.value,
            name: specNameSpanish.value,
            description: specDescriptionSpanish.value,
        });
    }

    const info = {
        profession_id: idProf,
        translations: translations
    };
    console.log(info)
    try {
        const response = await axios.post(url + urlAddSpec, info, { headers: headers });
            console.log(response);
            getProfessionsSpecializations(idProf);
            addCheck(2, idProf)
            //showMessage(true, response.data.message)
    } catch (error) {
        console.error(error);
        if(error){
            showMessage(false, error.response.data.message)
        } 
    }
};

const addTransSpec = async (name, description, lang, idSpec) => {
    const token = JSON.parse(localStorage.getItem('token'));
    const headers = ref({ 'Authorization': `Bearer ${token}` });
    const info = {
        language: lang,
        name: name,
        description: description
    };
    console.log(token, headers.value)
    try {
        const response = await axios.post(url + urlShowTransSpec + idSpec + '/translations', info, {headers: headers.value});
        console.log(response)
        console.log(response.data.data);
        getProfessionsSpecializations(idSpec);
        getTranslationSpec(idSpec);
        showMessage(true, response.data.message)
        addCheck(9, idSpec)
    } catch (error) {
        console.error('Error adding translation:', error);
        if(error){
            showMessage(false, error.response.data.message)
        }  
    }
};


const editTranslateSpec = async (name, description, idProf, idTrans, idSpec, method = 'PATCH') => {
    const token = JSON.parse(localStorage.getItem('token'));
    const headers = ref({ 'Authorization': `Bearer ${token}` });
    const info = {
        name: name,
        description: description
    };
    console.log(info);
    console.log(url + urlEditSpec + idProf + '/translations/' + idTrans)
    try {
        const response = await axios.put(url + urlEditSpec + idProf + '/translations/' + idTrans, info, { headers: headers.value });
        console.log(response);

        // Llamar a getTranslationSpec para actualizar las traducciones de esa especialización específica
        await getTranslationSpec(idSpec);
        await getProfessionsSpecializations(idProf);
        // Opcional: hacer que la interfaz refleje los cambios inmediatamente
        addCheck(6, idSpec, idTrans);
        showMessage(true, response.data.message)
    } catch (error) {
        console.error(error);
        if(error){
            showMessage(false, error.response.data.message)
        }  
    }
};


const edit = async (name, description, idProf, idTrans, method = 'PATCH') => {
    const token = JSON.parse(localStorage.getItem('token'));
    const headers = ref({ 'Authorization': `Bearer ${token}` });
    const info = {
        name: name,
        description: description
    };
    console.log(headers.value);
    console.log(info);
    console.log(url + urlEditSpec + idProf + '/translations/' + idTrans);
    try {
        const response = await axios({
            method: method,
            url: url + urlEditSpec + idProf + '/translations/' + idTrans,
            data: info,
            headers: headers.value
        });
        console.log(response);
        getAllProfessionsSpecializations(idProf);
        addCheck(6)
    } catch (error) {
        console.error(error);
    }
};


const statusSpec = async (status, idSpec) => {
    const token = JSON.parse(localStorage.getItem('token'))
    const headers = ref({'Authorization': `Bearer ${token}`})
    console.log(headers.value)
    if(status){
        try {
            await axios.patch(url + urlEditSpec + idSpec + '/enable', {}, {headers: headers.value}).then((response) => {
                console.log(response)
                getProfessions()
                console.log('actualizado')
                showMessage(true, response.data.message)
            })
        } catch (error) {
            console.error(error);
            if(error){
                showMessage(true, error.response.data.message)
            }  
        }
    } else if(!status){
        try {
            await axios.patch(url + urlEditSpec + idSpec + '/disable', {}, {headers: headers.value}).then((response) => {
                console.log(response)
                getProfessions()
                console.log('actualizado')
                showMessage(true, response.data.message)
            })
        } catch (error) {
            console.error(error);
            if(error){
                showMessage(false, error.response.data.message)
            }  
        }
    }
}

const deleteSpec = async(id, idProf) => {
    const token = JSON.parse(localStorage.getItem('token'))
    const headers = ref({'Authorization': `Bearer ${token}`})
    console.log(id, idProf, 'eliminar especialización')
    try {
        const response = await axios.delete(url + urlDeleteSpec + id, { headers: headers.value })
        console.log(response)
        getProfessions()
        getProfessionsSpecializations(idProf)
        showMessage(true, response.data.message)
    } catch (error) {
        console.error('Error al eliminar la especialización:', error)
        if(error){
            showMessage(false, error.response.data.message)
        }  
    }
}

const deleteTranslationSpec = async(idSpec, idTrans, idProf) => {
    const token = JSON.parse(localStorage.getItem('token'))
    const headers = ref({'Authorization': `Bearer ${token}`})
    console.log(idSpec, idTrans)
    try {
        const response = await axios.delete(url + urlDeleteTransSpec + idSpec + '/translations/' + idTrans, { headers: headers.value })
        console.log(response)
        await getTranslationSpec(idSpec)
        showMessage(true, response.data.message)
    } catch (error) {
        console.error('Error al eliminar la profesión:', error)
        if(error){
            showMessage(false, error.response.data.message)
        }  
    }
}

// -----------------  ---------------- //

const getReviews = async (page) => {
    const token = JSON.parse(localStorage.getItem('token'));
    const headers = ref({ 'Authorization': `Bearer ${token}` });
    let urlAxios = ''
    if(!page){
        urlAxios =  url+`/api/admin/reviews?page=${1}`
    }else if(page){
        urlAxios =  page
    }
    try {
        const response = await axios.get(urlAxios, { headers: headers.value });
        console.log(response.data.data);
        reviews.value = response.data.data;
        checkTextOverflow()
    } catch (error) {
        console.log(error)
        if(error){
            showMessage(false, error.response.data.message)
        }  
    }
    
};
getReviews()

const approvedReview = async(id) => {
    const token = JSON.parse(localStorage.getItem('token'))
    const headers = ref({'Authorization': `Bearer ${token}`})
    try {
        const response = await axios.put(url + '/api/admin/reviews/' + id+'/approve', { headers: headers.value })
        console.log(response)
        showMessage(true, response.data.message)
        getReviews()
    } catch (error) {
        console.error(error)
        if(error){
            showMessage(false, error.response.data.message)
        }  
    }
}

const desapprovedReview = async(id) => {
    const token = JSON.parse(localStorage.getItem('token'))
    const headers = ref({'Authorization': `Bearer ${token}`})
    try {
        const response = await axios.put(url + '/api/admin/reviews/'+ id +'/reject', { headers: headers.value })
        console.log(response)
        showMessage(true, response.data.message)
        getReviews()
    } catch (error) {
        console.error(error) 
        if(error){
            showMessage(false, error.response.data.message)
        }  
    }
}

const deleteReview = async(id) => {
    const token = JSON.parse(localStorage.getItem('token'))
    const headers = ref({'Authorization': `Bearer ${token}`})
    try {
        const response = await axios.delete(url + '/api/admin/reviews/' + id, { headers: headers.value })
        console.log(response)
        showMessage(true, response.data.message)
        getReviews()
    } catch (error) {
        console.error(error)
        if(error){
            showMessage(false, error.response.data.message)
        }  
    }
}


const checkTextOverflow = () => {
    console.log(reviewText.value)
  nextTick(() => {
    reviewText.value.forEach((el, index) => {
      if (el && el.scrollHeight > el.clientHeight) {
        showMoreButton.value[index] = true;
      } else {
        showMoreButton.value[index] = false;
      }
    });
  });
};

// Alternar el estado expandido del texto
const toggleExpand = (id) => {
  expanded.value[id] = !expanded.value[id];
};

let insertedNodes = []

const useHeadTags = async (settings = null) => {
    const url = process.env.VUE_APP_BASE_URL

    try {
        let response = await axios.post(`${url}/api/public/seo/pages`, { slug: settings.slug });

        const tagsString = await response.data.data

        const parser = new DOMParser()
        const doc = parser.parseFromString(
        `<html><head>${tagsString}</head><body></body></html>`,
        'text/html'
        )

        const headNodes = Array.from(doc.head.childNodes)

        headNodes.forEach(node => {
        const nodeName = node.nodeName.toLowerCase()

        switch (nodeName) {
            case 'title':
            document.title = node.textContent ?? ''
            break

            case 'meta': {
            const metaName = node.getAttribute('name')
            if (metaName) {
                const existingMeta = document.head.querySelector(`meta[name="${metaName}"]`)
                if (existingMeta) {
                document.head.removeChild(existingMeta)
                }
            }
            const meta = document.createElement('meta')
            Array.from(node.attributes).forEach(attr => {
                meta.setAttribute(attr.name, attr.value)
            })
            document.head.appendChild(meta)
            insertedNodes.push(meta)
            break
            }

            case 'script': {
            const script = document.createElement('script')
            Array.from(node.attributes).forEach(attr => {
                script.setAttribute(attr.name, attr.value)
            })
            script.textContent = node.textContent
            document.head.appendChild(script)
            insertedNodes.push(script)
            break
            }

            case 'link': {
            const link = document.createElement('link')
            Array.from(node.attributes).forEach(attr => {
                link.setAttribute(attr.name, attr.value)
            })
            document.head.appendChild(link)
            insertedNodes.push(link)
            break
            }

            default:
            console.warn(`Etiqueta <${nodeName}> no manejada.`)
        }
        })
        console.warn('TAGS CARGADOS')
    } catch (error) {
        console.error('Error procesando las etiquetas:', error)
    }
}

router.beforeEach(() => {
    insertedNodes.forEach(node => {
    if (document.head.contains(node)) {
        document.head.removeChild(node)
    }
    })
    insertedNodes = []
})

// Llamamos a la función al montar el componente
onMounted(() => {
    useHeadTags({ slug: route.fullPath.slice(1) })

    nextTick(() => {
        checkTextOverflow();
        window.addEventListener('resize', checkTextOverflow);
    });
});
</script>

<style lang="scss" scoped>
#app, .container-fluid{
    background-color: #FAFAFA;
    min-height: 100vh;
    @media (min-width: 992px) {
        background-color: #fff;
    }
}
.contenedor{
    animation: fade-in 0.2s ease-in forwards;
    @media (min-width: 992px) {
        max-width: 95vw;
        padding-top: 80px;
    }
    .tablas{  
        background-color: transparent;
        min-height: 56px;
        margin-bottom: 30px;
        border-bottom: 1px solid #70707023;
        box-shadow: 0px 3px 6px #00000016;
        border-radius: 10px;
        ul{
            background-color: transparent;
            padding: 8px 0;
            min-width: 100%;
        }
        .nav-item{
            padding: 0;
        }
        .nav-link{
            width: 100%;
            position: relative;
            padding: 10px 0;
            border: 0;
            background-color: transparent;
            border-radius: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 500;
            font-size: 16px;
            color: #B8C1DB;
            h3{
                font-weight: 500;
                font-size: 16px;
                color: #B8C1DB;
            }
            @media (min-width: 992px) {
                color: #0E1133;
                h3{
                    color: #0E1133;
                }
            }
        }
        .nav-link.active{
            font-weight: 700;
            border-color: transparent;
            color: var(--colorPrimary);
            background-color: transparent;
            border-radius: 0;
            &::before{
                content: '';
                position: absolute;
                width: 100%;
                height: 5px;
                bottom: -10px;
                left: 0;
                background-color: var(--colorPrimary);
                border-radius: 10px;
            }
        }
        .scroll{
            width: 100%;
            height: 100%;
        }
        @media (max-width: 991px) {
            border-radius: 0px;
            margin-bottom: 10px;
        }
        
    }
    .filtrosMobile{
        h5{
            color: var(--colorSecondary);
            font-weight: 700;
            font-size: 22px;
            text-align: left;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        h6{
            color: #697891;
            font-weight: 500;
            font-size: 16px;
            text-align: left;
            padding-top: 10px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .contenedor{
            box-shadow: 0px 3px 6px #00000029;
            border: 1px solid #ECECEC;
            background-color: var(--bgPrincipal);
            border-radius: 40px;
            min-height: 50px;
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            padding: 15px;
            @media (min-width: 992px) {
                border-radius: 40px;
            }
            @media (max-width: 991px) {
                width: 100%;
            }
            .img{
                padding: 0 20px;
            }
            input{
                border: 0;
                color: #B8C1DB;
                font-weight: 400;
                font-size: 14px;
                width: 90%;
                margin-left: 15px;
                &::placeholder{
                    color: #B8C1DB;
                }
                &:focus{
                    outline: 0;
                }
                @media (min-width: 992px) {
                    color: #697891;
                    font-size: 16px;
                    width: 93%;
                }
            }
            button{
                position: absolute;
                right: 7px;
                border: 0;
                min-height: 45px;
                width: 45px;
                box-shadow: 0px 3px 6px #00000029;
                border-radius: 50%;
                background-color: var(--colorPrimary);
                justify-content: center;
                align-items: center;
                img{
                    padding: 0;
                    max-height: 22px;
                }
                @media (min-width: 992px) {
                    right: 10px;
                }
            }
            .border-left{
                border-left: 1px solid rgba(184, 193, 219, 0.4);
            }
        }
    }
    .div{
        background-color: #fff;
        box-shadow: 0px 3px 10px #00000029;
        border-radius: 20px;
        padding:30px;
        min-height: auto;
        input{
            border: 0;
            color: #B8C1DB;
            font-weight: 400;
            font-size: 14px;
            width: 100%;
            &::placeholder{
                color: #B8C1DB;
            }
            &:focus{
                outline: 0;
            }
            @media (min-width: 992px) {
                color: #697891;
                font-size: 18px;
                width: 80%;
            }
        }
    }
    .collapses{
        h3{
            color: var(--colorSecondary);
            font-size: 16px;
            font-weight: 600;
            text-align: left;
            padding: 7px 0px;
        }
        h6{
            color:#B8BCC8;
            font-size: 15px;
            font-weight: 400;
            text-align: left;
            padding: 5px 0px;
        }
        .accordion{
            width: 100%;
            .accordion-item{
                border: 0;
                border-radius: 10px;
                box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.16);
                border-radius: 10px;
                background-color: #F9F9F9;
                margin-bottom: 15px;
                input{
                    width: 94%;
                    border: 2px solid #E9E3E3;
                    border-radius: 10px;
                    background-color: var(--bgPrincipal);
                    height: 50px;
                    padding-left: 15px;
                    outline: 0;
                    color: #697891;
                    font-size: 15px;
                    font-weight: 400;
                    &:focus, &:hover{
                        border: 2px solid #1679FC;
                    }
                    &::placeholder{
                        color: #697891;
                    }
                    @media (max-width: 991px) {
                        width: 83%;
                    }
                }
                .button{
                    background-color: #fff;
                    border: 2px solid #E9E3E3;
                    outline: 0;
                    border-radius: 25px;
                    min-height: 40px;
                    min-width: 40px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img{
                        height: 15px;
                    }
                }
                h4{
                    color: #0E1133;
                    font-weight: 500;
                    font-size: 16px;
                    text-align: left;
                }
                h5{
                    color: var(--colorSecondary);
                    font-weight: 600;
                    font-size: 16px;
                    text-align: left;
                }
            }
            .accordion-item:last-of-type .accordion-button.collapsed{
                border-bottom-right-radius: 10px;
                border-bottom-left-radius: 10px;
            }
            .accordion-header{
                border-radius: 10px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                position: relative;
                min-height: 70px;
                .accordion-button{
                    background-color: #F9F9F9;
                    padding: 10px;
                }
                .accordion-button:not(.collapsed){
                    background-color: inherit;
                    box-shadow: none;
                }
                .accordion-button::after{
                    position: absolute;
                    right: 2%;
                    background-image: url('../../../img/subir-productos-icons/arrow-gris-abajo.svg');
                    background-size: 50%;
                    background-position: center;
                    background-color: transparent;
                    border-radius: 50%;
                    height: 40px;
                    width: 40px;
                }
                .accordion-body{
                    span{
                        border: 2px solid #E9E3E3;
                        height: 40px;
                        width: 40px;
                        border-radius: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        h3{
                            text-align: center;
                        }
                    }
                    button{
                        background-color: transparent;
                        border: 2px solid #E4E4E4;
                        border-radius: 50%;
                        height: 40px;
                        width: 40px;
                        margin-left: 10px;
                    }
                }
                
            }
            .eliminar{
                background-color: transparent;
                border-radius: 50%;
                height: 40px;
                width: 40px;
                border: 1px solid #B8BCC8;
            }
        }
        .padding{
            padding: 0 11%;
        }
    }
    .academicas{
        background-color: #F5F5F5;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
        border-radius: 15px;
        height: 110px;
        h4{
            color: var(--colorSecondary);
            font-weight: 600;
            font-size: 16px;
        }
        h5{
            color: #2E4765;
            font-weight: 300;
            font-size: 14px;
        }
        p{
            color: #697891;
            font-size: 16px;
            font-weight: 400;
            text-align: left;
            padding-left: 10px;
        }
        .guardar{
            button{
                border: 2px solid var(--colorPrimary);
                background-color: var(--bgPrincipal);
                border-radius: 25px;
                height: 50px;
                font-weight: 600;
                font-size: 16px;
                color: var(--colorPrimary);
                width: 100%;
            }
        }
        input,select{
            width:100%;
            border: 2px solid #E9E3E3;
            border-radius: 10px;
            background-color: #FCFCFC;
            height: 50px;
            padding-left: 15px;
            outline: 0;
            color: #697891;
            font-size: 15px;
            font-weight: 400;
            -webkit-appearance: none;
            &:focus, &:hover{
                border: 2px solid #1679FC;
            }
            &::placeholder{
                color: #697891;
            }
        }
    }   
    .border-top{
        border-top: 2px solid #E4E4E4;
        padding-top: 20px;
    }
    .agregar-cursos-btn, .agregar-cursos-btn-3{
        box-shadow: 0 3px 6px #00000016;
        border-radius: 43px;
        height: 60px;
        margin: 20px 0;
        border: 0;
        background-color: var(--bgPrincipal);
        font-weight: 600;
        font-size: 16px;
        color: var(--colorSecondary);
        padding-bottom: 5px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0 15px;
        width: 100%;
        max-width: 300px;
        span{
            border: 2px solid #E4E4E4;
            border-radius: 50%;
            height: 40px;
            width: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 15px;
        }
    }
    .agregar-cursos-btn-4{
        max-width: 50px;
        height: 50px;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        span{
            margin-right: 0;
        }
    }
    .faq{
        .collapses{
            h3{
                margin-bottom: 5px !important;
                font-weight: 700;
                font-size: 18px;
            }
            .question{
                font-size: 30px;
                margin-bottom: 0 !important;
            }
            h4{
                font-weight: 400;
                font-size: 15px;
                color: #B8BCC8;
                text-align: left;
            }
            .accordion{
                width: 100%;
                .accordion-item{
                    margin: 15px 0px;
                    border: 0;
                    border-radius: 10px;
                    
                    p{
                        font-size: 14px;
                        font-weight: 400;
                        color: var(--colorSecondary);
                    }
                }
                .accordion-item:last-of-type .accordion-button.collapsed{
                    border-bottom-right-radius: 10px;
                    border-bottom-left-radius: 10px;
                }
                .accordion-header{
                    box-shadow: 0px 3px 6px #00000029;
                    border-radius: 10px;
                    background-color: #F9F9F9;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    position: relative;
                    .accordion-button{
                        border-radius: 10px;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        background-color: #F9F9F9;
                        &:focus{
                            border-color: inherit;
                            box-shadow: none;
                        }
                        span{
                            border: 2px solid #E9E3E3;
                            min-height: 50px;
                            min-width: 50px;
                            border-radius: 50%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                        @media (max-width: 991px) {
                            justify-content: flex-start;
                            align-items: flex-start;
                        }
                    }
                    .accordion-button:not(.collapsed){
                        background-color: inherit;
                        box-shadow: none;
                    }
                    .accordion-button::after{
                        position: absolute;
                        right: 5%;
                        background-image: url('../../../img/subir-productos-icons/arrow-abajo-azul.svg');
                        background-size: 100%;
                        background-position: center;
                        @media (max-width: 991px) {
                            margin-top: 5px;
                        }
                    }
                }
            }
            @media (min-width: 992px) {
                margin: 20px 0 60px 0;
            }
            .collapses2{
                h3{
                    margin-bottom: 0px !important;
                    font-size: 16px;
                }
                .accordion{
                    width: 100%;
                    .accordion-item{
                        margin: 15px 0px;
                        border: 0;
                        border-radius: 10px;
                    }
                    .accordion-item:last-of-type .accordion-button.collapsed{
                        border-bottom-right-radius: 10px;
                        border-bottom-left-radius: 10px;
                    }
                    .accordion-header{
                        box-shadow: 0px 3px 6px #00000029;
                        border-radius: 10px;
                        background-color: var(--bgPrincipal);
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: flex-start;
                        position: relative;
                        .accordion-button{
                            border-radius: 10px;
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            background-color: var(--bgPrincipal);
                            &:focus{
                                border-color: inherit;
                                box-shadow: none;
                            }
                            span{
                                border: 2px solid #E9E3E3;
                                min-height: 40px;
                                min-width: 40px;
                                border-radius: 50%;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                h3{
                                    text-align: center;
                                }
                            }
                        }
                        .accordion-button:not(.collapsed){
                            background-color: inherit;
                            box-shadow: none;
                        }
                        .accordion-button::after{
                            position: absolute;
                            right: 2.5%;
                            background-image: url('../../../img/subir-productos-icons/arrow-gris-abajo.svg');
                            background-size: 50%;
                            background-position: center;
                            border: 2px solid #E9E3E3;
                            border-radius: 50%;
                            min-height: 40px;
                            min-width: 40px;
                        }
                        .accordion-body{
                            input,select{
                                width: 100%;
                                border: 2px solid #E9E3E3;
                                border-radius: 10px;
                                background-color: #FCFCFC;
                                height: 50px;
                                padding-left: 15px;
                                outline: 0;
                                color: #697891;
                                font-size: 15px;
                                font-weight: 400;
                                -webkit-appearance: none;
                                &:focus, &:hover{
                                    border: 2px solid #1679FC;
                                }
                                &::placeholder{
                                    color: #697891;
                                }
                            }
                            span{
                                border: 2px solid #E9E3E3;
                                height: 40px;
                                width: 40px;
                                border-radius: 50%;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                h3{
                                    text-align: center;
                                }
                            }
                            button{
                                background-color: transparent;
                                border: 2px solid #E4E4E4;
                                border-radius: 50%;
                                min-height: 40px;
                                min-width: 40px;
                                margin-left: 10px;
                            }
                        }
                    }
                }
                
                @media (min-width: 992px) {
                    margin: 20px 0 60px 0;
                }
            }
            
            .max-width{
                width: 7%;
            }
            .position-relative{
                .position{
                    position: absolute;
                    right: 8%;
                    button{
                        background-color: transparent;
                        border: 2px solid #E4E4E4;
                        border-radius: 50%;
                        min-height: 40px;
                        min-width: 40px;
                        
                    }
                }
            }
        }
    }
    
}
.modality{
    h3{
        color: var(--colorSecondary);
        font-size: 16px;
        font-weight: 400;
        text-align: left;
        padding: 0 0px 10px 0;
    }
    input[type=text], textarea{
        width: 100%;
        border: 2px solid #E9E3E3;
        border-radius: 10px;
        background-color: transparent;
        height: 50px;
        padding-left: 15px;
        outline: 0;
        &:focus, &:hover{
            border: 2px solid #1679FC;
        }
        &::placeholder{
            color: #697891;
        }
    }
    textarea{
        padding-top: 15px;
        height: 150px;
    }
    input[type=file]{
        display: none;
    }
    input[type=file] + label{
        background-color: #fff;
        border: 2px dashed #B8BCC8;
        height: 50px;
        width: 100%;
        border-radius: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        font-size: 16px;
        color: #B8BCC8;
        &:focus, &:hover{
            border: 2px dashed var(--colorPrimary);
            color: var(--colorPrimary);
        }
    }
    .btn-close{
        &:focus{
            outline: 0;
            box-shadow: none;
        }
    }
}

.classSpecial{
    @media (max-width: 991px) {
        padding-bottom: 30vw;
    }
    @media (min-width: 992px) {
        width: 70vw !important;
    }
}

.professions{
    input, select{
        width: 100%;
        border: 2px solid #E9E3E3;
        border-radius: 10px;
        background-color: var(--bgPrincipal);
        height: 50px;
        padding-left: 15px;
        outline: 0;
        color: #697891;
        font-size: 15px;
        font-weight: 400;
        &:focus, &:hover{
            border: 2px solid #1679FC;
        }
        &::placeholder{
            color: #697891;
        }
    }
    .button{
        background-color: #fff;
        border: 2px solid #E9E3E3;
        outline: 0;
        border-radius: 25px;
        height: 40px;
        width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        img{
            height: 15px;
        }
    }
    h4{
        color: #0E1133;
        font-weight: 500;
        font-size: 16px;
        text-align: left;
    }
    h5{
        color: var(--colorSecondary);
        font-weight: 600;
        font-size: 16px;
        text-align: left;
    }
    .title{
        font-size: 20px;
    }
    .agregar-cursos-btn-4{
        max-width: none;
        @media (max-width: 992px) {
            max-width: 300px;
        }
        img{
            margin-right: 15px;
        }
    }
    .border-box{
            background-color: #f0f2f8;
            border-radius: 20px;
            min-height: 100px;
            .agregar-cursos-btn, .agregar-cursos-btn-3{
            box-shadow: 0 3px 6px #00000016;
            border-radius: 30px;
            height: 60px;
            margin: 20px 0;
            border: 0;
            background-color: var(--bgPrincipal);
            font-weight: 600;
            font-size: 16px;
            color: var(--colorSecondary);
            padding-bottom: 5px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 0 15px;
            width: 100%;
            max-width: max-content;
            display: flex;
            justify-content: center;
            align-items: center;
            h6{
                text-align: left;
            }
        }
        .button-edit{
            background-color: #1679FC;
            width: max-content;
            padding: 15px;
            height: 30px;
            border-radius: 15px;
            color: #ECECEC;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        button{
            border: #00000016 1px solid;
            height: 40px;
            width: 40px;
            border-radius: 50%;   
            margin-left: 10px;
        }
        input[type="checkbox"]{
            height: 20px;
            width: 20px;
        }
    }
    .button_test{
        height: 50px !important;
        width: max-content !important;
        background-color: #1679FC;
        color: #ffffff;
        font-size: 16px;
        border-radius: 15px !important;
        padding: 15px;
        margin: 15px;
    }
    h4.mb-4{
        color: #0E1133;
        font-weight: 600;
        font-size: 20px;
        text-align: left;
    }
    h5.mb-2{
        color: #2E4765;
        font-weight: 600;
        font-size: 16px;
        text-align: left;
    }
    h6.mt-2, h6.mb-2, h6.mb-3{
        text-align: left;
    }
    p{
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;

    }
}

.reviews{
    h5{
        color: #0E1133;
        font-weight: 400;
        font-size: 18px;
        text-align: left;
    }
    h6{
        color: #2E4765;
        font-weight: 600;
        font-size: 16px;
        text-align: left;
    }
    p, .btn-none {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        margin-top: 5px;
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .btn-none{
        border: 0 !important;
        outline: 0;
        background-color: transparent;
        width: max-content;
        padding: 0;
    }
    .clamped {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        max-height: 44px; 
    }
    input, select{
        width: 100%;
        border: 2px solid #E9E3E3;
        border-radius: 10px;
        background-color: var(--bgPrincipal);
        height: 50px;
        padding-left: 15px;
        outline: 0;
        color: #697891;
        font-size: 15px;
        font-weight: 400;
        &:focus, &:hover{
            border: 2px solid #1679FC;
        }
        &::placeholder{
            color: #697891;
        }
    }
    .button{
        background-color: #fff;
        border: 2px solid #E9E3E3;
        outline: 0;
        border-radius: 25px;
        height: 40px;
        width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        img{
            height: 15px;
        }
    }
    
    .title{
        font-size: 20px;
        font-weight: 600;
        color: var(--colorSecondary);
    }
    .agregar-cursos-btn-4{
        max-width: none;
        @media (max-width: 992px) {
            max-width: 300px;
        }
        img{
            margin-right: 15px;
        }
    }
    .border-box{
        background-color: #f0f2f8;
        border-radius: 20px;
        min-height: auto;
        padding: 20px;
        .agregar-cursos-btn, .agregar-cursos-btn-3{
            box-shadow: 0 3px 6px #00000016;
            border-radius: 30px;
            height: 60px;
            margin: 20px 0;
            border: 0;
            background-color: var(--bgPrincipal);
            font-weight: 600;
            font-size: 16px;
            color: var(--colorSecondary);
            padding-bottom: 5px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 0 15px;
            width: 100%;
            max-width: max-content;
            display: flex;
            justify-content: center;
            align-items: center;
            h6{
                text-align: left;
            }
        }
        .button-edit{
            background-color: #1679FC;
            width: max-content;
            padding: 15px;
            height: 30px;
            border-radius: 15px;
            color: #ECECEC;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        button:not(.btn-none){
            border: #00000016 1px solid;
            height: 40px;
            width: 40px;
            border-radius: 50%;   
            margin-left: 10px;
        }
        input[type="checkbox"]{
            height: 20px;
            width: 20px;
        }
    }
    .button_test{
        height: 50px !important;
        width: max-content !important;
        background-color: #1679FC;
        color: #ffffff;
        font-size: 16px;
        border-radius: 15px !important;
        padding: 15px;
        margin: 15px;
    }
    h4.mb-4{
        color: #0E1133;
        font-weight: 600;
        font-size: 20px;
        text-align: left;
    }
    h5.mb-2{
        color: #2E4765;
        font-weight: 600;
        font-size: 16px;
        text-align: left;
    }
    h6.mt-2, h6.mb-2, h6.mb-3{
        text-align: left;
    }
    
}


@keyframes fade-in {
    0% { opacity: 0; }
   90% { opacity: 0.9; }
  100% { opacity: 1; }
}

.pagination{
    .page-item{
        border: 0;
        border-radius: 25px;
        margin: 0 2px;
        .page-link{
            border-radius: 25px;
            border: 0;
            font-weight: 500;
            color: var(--colorSecondary);
            font-family: 'Poppins';
            a{
                &:hover{
                    background-color: var(--colorPrimary);
                    color: var(--colorSecondary);
                }
            }
            &:focus{
                box-shadow: none;
            }
        }
    }
    .buttons{
        border-radius: 15px;
        .page-link{
            border-radius: 15px;
        }
    }
    
    .active{
        background-color: var(--colorPrimary);
        .page-link{
            color: #fff !important;
        }
        a{
            color: #fff !important;
        }
    }

}
</style>