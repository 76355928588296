<template>
    <div class="container-fluid d-flex justify-content-center justify-content-lg-between align-items-start ps-lg-0">
        <NavbarCertificados :seccion="$t('message.productsPanel')"/>
        <div class="col d-flex flex-column justify-content-center align-items-center align-items-lg-start px-0 classSpecial">
            <!--- INFORMACIÓN --->
            <form class="row justify-content-center row__width contenedor">
                <div class="col-12 d-flex flex-column justify-content-center justify-content-lg-start align-items-center px-0 ">
                    <TituloAdminAjustes :title="$t('message.generalSettings')"/>
                    <div class="row justify-content-center row__width px-0 px-lg-4 border-top">
                        <div class="col-12  d-flex flex-column justify-content-center align-items-center tablas">
                            <div class="scroll d-flex justify-content-center justify-content-lg-start align-items-center" > 
                                <ul class="nav nav-tabs row justify-content-center justify-content-lg-start" id="myTab" role="tablist">
                                    <li class="nav-item col-6 col-lg-3 d-flex justify-content-center align-items-center" role="presentation">
                                        <button class="nav-link active" id="general-tab" data-bs-toggle="tab" data-bs-target="#general" type="button" role="tab" aria-controls="general" aria-selected="true">{{ $t('message.basicInformation') }}</button>
                                    </li>
                                    <li class="nav-item col-6 col-lg-3 d-flex justify-content-center align-items-center" role="presentation">
                                        <button class="nav-link " id="courses-tab" data-bs-toggle="tab" data-bs-target="#courses" type="button" role="tab" aria-controls="courses" aria-selected="false">{{ $t('message.products') }}</button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-12 d-flex flex-column justify-content-center align-items-center contenido">
                            <div class="tab-content row justify-content-center row__width" id="myTabContent">
                                <!--- GENERAL --->
                                <div class="col-12 tab-pane show active px-0" id="general" role="tabpanel" aria-labelledby="general-tab">
                                    <AgregarConfiguraciones v-if="buttons"/>
                                    <!------->
                                    <Concursos/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <FooterCertificados :upload="true" class="d-lg-none"/>
        <div class="espacio2"></div>
    </div>
</template>

<script setup>
import Categorias from "../../../components/Panel/Admin/Ajustes/Categorias.vue";
import NavbarCertificados from "../../../components/Navs/NavbarCertificados.vue";
import FooterCertificados from "../../../components/Footer/FooterCertificados.vue";
import NavLateralPanelAdmin from "../../../components/Navs/NavLateralPanelAdmin.vue";
import AgregarConfiguraciones from "../../../components/Panel/Admin/Ajustes/AgregarConfiguraciones.vue";
import TituloAdminAjustes from "../../../components/Titulo/TituloAdminAjustes.vue";
import Concursos from "../../../components/Panel/Admin/Concursos/Concursos.vue";
import AgregarConfiguracionesProductos from "../../../components/Panel/Admin/Ajustes/AgregarConfiguracionesProductos.vue";

import { useI18n } from "vue-i18n"
import { storeToRefs } from "pinia"
import { useUsersStore } from "../../../stores/user"
import { useLanguageStore } from "../../../stores/langs"
import { onBeforeMount, ref, provide, watch, onMounted } from "vue"
import { useRoute, useRouter } from 'vue-router'
import axios from 'axios';

const route = useRoute();
const router = useRouter();

const {locale} = useI18n({ useScope: 'global' })

const users = useUsersStore();
const {access} = storeToRefs(users);
const {} = users;
const {validate} = users;

const langs = useLanguageStore();
const {listLangs} = storeToRefs(langs);

const listTypes = ref([])
const listModalities = ref([])
const listProfs = ref([])
const listBenefits = ref([])
const listIcons = ref([])
const listValues = ref([])
const listFaqs = ref([])
provide('listTypes', listTypes)
provide('listModalities', listModalities)
provide('listProfs', listProfs)
provide('listBenefits', listBenefits)
provide('listIcons', listIcons)
provide('listValues', listValues)
provide('listFaqs', listFaqs)

const algo = onBeforeMount( () => {
    access.value = [1]
    //console.log(access.value)
    validate();
})

const isSidebarOpen = ref(true)
//provide(isSidebarOpen, 'isSidebarOpen')

const views = ref(0)
provide("views", views)

const viewsProducts = ref(0)
provide("viewsProducts", viewsProducts)

const buttons = ref(true)
provide("buttons", buttons)

const buttons2 = ref(true)
provide("buttons2", buttons2)

const tabs = ref(true)
provide("tabs", tabs)

const toggleSidebar = () => {
    isSidebarOpen.value = !isSidebarOpen.value
    if(document.getElementById('sidebar').classList.contains('d-none')){
        document.getElementById('sidebar').classList.remove('d-none')
    } else{
        document.getElementById('sidebar').classList.add('d-none')
    }
    if(document.getElementById('div-grey').classList.contains('right')){
        document.getElementById('div-grey').classList.remove('right')
        document.getElementById('div-grey').classList.add('left')
    } else{
        document.getElementById('div-grey').classList.remove('left')
        document.getElementById('div-grey').classList.add('right')
    }
}

let insertedNodes = []

const useHeadTags = async (settings = null) => {
    const url = process.env.VUE_APP_BASE_URL

    try {
        let response = await axios.post(`${url}/api/public/seo/pages`, { slug: settings.slug });

        const tagsString = await response.data.data

        const parser = new DOMParser()
        const doc = parser.parseFromString(
        `<html><head>${tagsString}</head><body></body></html>`,
        'text/html'
        )

        const headNodes = Array.from(doc.head.childNodes)

        headNodes.forEach(node => {
        const nodeName = node.nodeName.toLowerCase()

        switch (nodeName) {
            case 'title':
            document.title = node.textContent ?? ''
            break

            case 'meta': {
            const metaName = node.getAttribute('name')
            if (metaName) {
                const existingMeta = document.head.querySelector(`meta[name="${metaName}"]`)
                if (existingMeta) {
                document.head.removeChild(existingMeta)
                }
            }
            const meta = document.createElement('meta')
            Array.from(node.attributes).forEach(attr => {
                meta.setAttribute(attr.name, attr.value)
            })
            document.head.appendChild(meta)
            insertedNodes.push(meta)
            break
            }

            case 'script': {
            const script = document.createElement('script')
            Array.from(node.attributes).forEach(attr => {
                script.setAttribute(attr.name, attr.value)
            })
            script.textContent = node.textContent
            document.head.appendChild(script)
            insertedNodes.push(script)
            break
            }

            case 'link': {
            const link = document.createElement('link')
            Array.from(node.attributes).forEach(attr => {
                link.setAttribute(attr.name, attr.value)
            })
            document.head.appendChild(link)
            insertedNodes.push(link)
            break
            }

            default:
            console.warn(`Etiqueta <${nodeName}> no manejada.`)
        }
        })
        console.warn('TAGS CARGADOS')
    } catch (error) {
        console.error('Error procesando las etiquetas:', error)
    }
}

router.beforeEach(() => {
    insertedNodes.forEach(node => {
    if (document.head.contains(node)) {
        document.head.removeChild(node)
    }
    })
    insertedNodes = []
})

onMounted(() => {
    useHeadTags({ slug: route.fullPath.slice(1) })
})

</script>

<style lang="scss" scoped>
#app, .container-fluid{
    background-color: #FAFAFA;
    min-height: 100vh;
}
.contenedor{
    animation: fade-in 0.2s ease-in forwards;
    @media (min-width: 992px) {
        padding-top: 80px;
    }
    .tablas{  
        background-color: transparent;
        min-height: 56px;
        margin-bottom: 30px;
        border-bottom: 1px solid #70707023;
        box-shadow: 0px 3px 6px #00000016;
        border-radius: 10px;
        ul{
            background-color: transparent;
            padding: 8px 0;
            min-width: 100%;
        }
        .nav-item{
            padding: 0;
        }
        .nav-link{
            width: 100%;
            position: relative;
            padding: 10px 0;
            border: 0;
            background-color: transparent;
            border-radius: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 500;
            font-size: 16px;
            color: #B8C1DB;
            h3{
                font-weight: 500;
                font-size: 16px;
                color: #B8C1DB;
            }
            @media (min-width: 992px) {
                color: #0E1133;
                h3{
                    color: #0E1133;
                }
            }
        }
        .nav-link.active{
            font-weight: 700;
            border-color: transparent;
            color: var(--colorPrimary);
            background-color: transparent;
            border-radius: 0;
            &::before{
                content: '';
                position: absolute;
                width: 100%;
                height: 5px;
                bottom: -10px;
                left: 0;
                background-color: var(--colorPrimary);
                border-radius: 10px;
            }
        }
        .scroll{
            width: 100%;
            height: 100%;
        }
        @media (max-width: 991px) {
            border-radius: 0px;
            margin-bottom: 10px;
        }
        
    }
    .filtrosMobile{
        h5{
            color: var(--colorSecondary);
            font-weight: 700;
            font-size: 22px;
            text-align: left;
        }
        h6{
            color: #697891;
            font-weight: 500;
            font-size: 16px;
            text-align: left;
            padding-top: 10px;
        }
        .contenedor{
            box-shadow: 0px 3px 6px #00000029;
            border: 1px solid #ECECEC;
            background-color: var(--bgPrincipal);
            border-radius: 40px;
            min-height: 50px;
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            padding: 15px;
            @media (min-width: 992px) {
                border-radius: 40px;
            }
            @media (max-width: 991px) {
                width: 100%;
            }
            .img{
                padding: 0 20px;
            }
            input{
                border: 0;
                color: #B8C1DB;
                font-weight: 400;
                font-size: 14px;
                width: 90%;
                margin-left: 15px;
                &::placeholder{
                    color: #B8C1DB;
                }
                &:focus{
                    outline: 0;
                }
                @media (min-width: 992px) {
                    color: #697891;
                    font-size: 16px;
                    width: 93%;
                }
            }
            button{
                position: absolute;
                right: 7px;
                border: 0;
                min-height: 45px;
                width: 45px;
                box-shadow: 0px 3px 6px #00000029;
                border-radius: 50%;
                background-color: var(--colorPrimary);
                justify-content: center;
                align-items: center;
                img{
                    padding: 0;
                    max-height: 22px;
                }
                @media (min-width: 992px) {
                    right: 10px;
                }
            }
            .border-left{
                border-left: 1px solid rgba(184, 193, 219, 0.4);
            }
        }
    }
    .div{
        background-color: #fff;
        box-shadow: 0px 3px 10px #00000029;
        border-radius: 20px;
        padding:30px;
        min-height: auto;
        input{
            border: 0;
            color: #B8C1DB;
            font-weight: 400;
            font-size: 14px;
            width: 100%;
            &::placeholder{
                color: #B8C1DB;
            }
            &:focus{
                outline: 0;
            }
            @media (min-width: 992px) {
                color: #697891;
                font-size: 18px;
                width: 80%;
            }
        }
    }
    .collapses{
        h3{
            color: var(--colorSecondary);
            font-size: 16px;
            font-weight: 600;
            text-align: left;
            padding: 7px 0px;
        }
        h6{
            color:#B8BCC8;
            font-size: 15px;
            font-weight: 400;
            text-align: left;
            padding: 5px 0px;
        }
        .accordion{
            width: 100%;
            .accordion-item{
                border: 0;
                border-radius: 10px;
                box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.16);
                border-radius: 10px;
                background-color: #F9F9F9;
                margin-bottom: 15px;
                input{
                    width: 94%;
                    border: 2px solid #E9E3E3;
                    border-radius: 10px;
                    background-color: var(--bgPrincipal);
                    height: 50px;
                    padding-left: 15px;
                    outline: 0;
                    color: #697891;
                    font-size: 15px;
                    font-weight: 400;
                    &:focus, &:hover{
                        border: 2px solid #1679FC;
                    }
                    &::placeholder{
                        color: #697891;
                    }
                    @media (max-width: 991px) {
                        width: 83%;
                    }
                }
                .button{
                    background-color: #fff;
                    border: 2px solid #E9E3E3;
                    outline: 0;
                    border-radius: 25px;
                    min-height: 40px;
                    min-width: 40px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img{
                        height: 15px;
                    }
                }
                h4{
                    color: #0E1133;
                    font-weight: 500;
                    font-size: 16px;
                    text-align: left;
                }
                h5{
                    color: var(--colorSecondary);
                    font-weight: 600;
                    font-size: 16px;
                    text-align: left;
                }
            }
            .accordion-item:last-of-type .accordion-button.collapsed{
                border-bottom-right-radius: 10px;
                border-bottom-left-radius: 10px;
            }
            .accordion-header{
                border-radius: 10px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                position: relative;
                min-height: 70px;
                .accordion-button{
                    background-color: #F9F9F9;
                    padding: 10px;
                }
                .accordion-button:not(.collapsed){
                    background-color: inherit;
                    box-shadow: none;
                }
                .accordion-button::after{
                    position: absolute;
                    right: 2%;
                    background-image: url('../../../img/subir-productos-icons/arrow-gris-abajo.svg');
                    background-size: 50%;
                    background-position: center;
                    background-color: transparent;
                    border-radius: 50%;
                    height: 40px;
                    width: 40px;
                }
                .accordion-body{
                    span{
                        border: 2px solid #E9E3E3;
                        height: 40px;
                        width: 40px;
                        border-radius: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        h3{
                            text-align: center;
                        }
                    }
                    button{
                        background-color: transparent;
                        border: 2px solid #E4E4E4;
                        border-radius: 50%;
                        height: 40px;
                        width: 40px;
                        margin-left: 10px;
                    }
                }
                
            }
            .eliminar{
                background-color: transparent;
                border-radius: 50%;
                height: 40px;
                width: 40px;
                border: 1px solid #B8BCC8;
            }
        }
        .padding{
            padding: 0 11%;
        }
    }
    .academicas{
        background-color: #F5F5F5;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
        border-radius: 15px;
        height: 110px;
        h4{
            color: var(--colorSecondary);
            font-weight: 600;
            font-size: 16px;
        }
        h5{
            color: #2E4765;
            font-weight: 300;
            font-size: 14px;
        }
        p{
            color: #697891;
            font-size: 16px;
            font-weight: 400;
            text-align: left;
            padding-left: 10px;
        }
        .guardar{
            button{
                border: 2px solid var(--colorPrimary);
                background-color: var(--bgPrincipal);
                border-radius: 25px;
                height: 50px;
                font-weight: 600;
                font-size: 16px;
                color: var(--colorPrimary);
                width: 100%;
            }
        }
        input,select{
            width:100%;
            border: 2px solid #E9E3E3;
            border-radius: 10px;
            background-color: #FCFCFC;
            height: 50px;
            padding-left: 15px;
            outline: 0;
            color: #697891;
            font-size: 15px;
            font-weight: 400;
            -webkit-appearance: none;
            &:focus, &:hover{
                border: 2px solid #1679FC;
            }
            &::placeholder{
                color: #697891;
            }
        }
    }   
    .border-top{
        border-top: 2px solid #E4E4E4;
        padding-top: 20px;
    }
    .agregar-cursos-btn, .agregar-cursos-btn-3{
        box-shadow: 0 3px 6px #00000016;
        border-radius: 43px;
        height: 60px;
        margin: 20px 0;
        border: 0;
        background-color: var(--bgPrincipal);
        font-weight: 600;
        font-size: 16px;
        color: var(--colorSecondary);
        padding-bottom: 5px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0 15px;
        width: 100%;
        max-width: 300px;
        span{
            border: 2px solid #E4E4E4;
            border-radius: 50%;
            height: 40px;
            width: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 15px;
        }
    }
    .agregar-cursos-btn-4{
        max-width: 50px;
        height: 50px;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        span{
            margin-right: 0;
        }
    }
    .faq{
        .collapses{
            h3{
                margin-bottom: 5px !important;
                font-weight: 700;
                font-size: 18px;
            }
            .question{
                font-size: 30px;
                margin-bottom: 0 !important;
            }
            h4{
                font-weight: 400;
                font-size: 15px;
                color: #B8BCC8;
                text-align: left;
            }
            .accordion{
                width: 100%;
                .accordion-item{
                    margin: 15px 0px;
                    border: 0;
                    border-radius: 10px;
                    
                    p{
                        font-size: 14px;
                        font-weight: 400;
                        color: var(--colorSecondary);
                    }
                }
                .accordion-item:last-of-type .accordion-button.collapsed{
                    border-bottom-right-radius: 10px;
                    border-bottom-left-radius: 10px;
                }
                .accordion-header{
                    box-shadow: 0px 3px 6px #00000029;
                    border-radius: 10px;
                    background-color: #F9F9F9;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    position: relative;
                    .accordion-button{
                        border-radius: 10px;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        background-color: #F9F9F9;
                        &:focus{
                            border-color: inherit;
                            box-shadow: none;
                        }
                        span{
                            border: 2px solid #E9E3E3;
                            min-height: 50px;
                            min-width: 50px;
                            border-radius: 50%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                        @media (max-width: 991px) {
                            justify-content: flex-start;
                            align-items: flex-start;
                        }
                    }
                    .accordion-button:not(.collapsed){
                        background-color: inherit;
                        box-shadow: none;
                    }
                    .accordion-button::after{
                        position: absolute;
                        right: 5%;
                        background-image: url('../../../img/subir-productos-icons/arrow-abajo-azul.svg');
                        background-size: 100%;
                        background-position: center;
                        @media (max-width: 991px) {
                            margin-top: 5px;
                        }
                    }
                }
            }
            @media (min-width: 992px) {
                margin: 20px 0 60px 0;
            }
            .collapses2{
                h3{
                    margin-bottom: 0px !important;
                    font-size: 16px;
                }
                .accordion{
                    width: 100%;
                    .accordion-item{
                        margin: 15px 0px;
                        border: 0;
                        border-radius: 10px;
                    }
                    .accordion-item:last-of-type .accordion-button.collapsed{
                        border-bottom-right-radius: 10px;
                        border-bottom-left-radius: 10px;
                    }
                    .accordion-header{
                        box-shadow: 0px 3px 6px #00000029;
                        border-radius: 10px;
                        background-color: var(--bgPrincipal);
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: flex-start;
                        position: relative;
                        .accordion-button{
                            border-radius: 10px;
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            background-color: var(--bgPrincipal);
                            &:focus{
                                border-color: inherit;
                                box-shadow: none;
                            }
                            span{
                                border: 2px solid #E9E3E3;
                                min-height: 40px;
                                min-width: 40px;
                                border-radius: 50%;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                h3{
                                    text-align: center;
                                }
                            }
                        }
                        .accordion-button:not(.collapsed){
                            background-color: inherit;
                            box-shadow: none;
                        }
                        .accordion-button::after{
                            position: absolute;
                            right: 2.5%;
                            background-image: url('../../../img/subir-productos-icons/arrow-gris-abajo.svg');
                            background-size: 50%;
                            background-position: center;
                            border: 2px solid #E9E3E3;
                            border-radius: 50%;
                            min-height: 40px;
                            min-width: 40px;
                        }
                        .accordion-body{
                            input,select{
                                width: 100%;
                                border: 2px solid #E9E3E3;
                                border-radius: 10px;
                                background-color: #FCFCFC;
                                height: 50px;
                                padding-left: 15px;
                                outline: 0;
                                color: #697891;
                                font-size: 15px;
                                font-weight: 400;
                                -webkit-appearance: none;
                                &:focus, &:hover{
                                    border: 2px solid #1679FC;
                                }
                                &::placeholder{
                                    color: #697891;
                                }
                            }
                            span{
                                border: 2px solid #E9E3E3;
                                height: 40px;
                                width: 40px;
                                border-radius: 50%;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                h3{
                                    text-align: center;
                                }
                            }
                            button{
                                background-color: transparent;
                                border: 2px solid #E4E4E4;
                                border-radius: 50%;
                                min-height: 40px;
                                min-width: 40px;
                                margin-left: 10px;
                            }
                        }
                    }
                }
                
                @media (min-width: 992px) {
                    margin: 20px 0 60px 0;
                }
            }
            
            .max-width{
                width: 7%;
            }
            .position-relative{
                .position{
                    position: absolute;
                    right: 8%;
                    button{
                        background-color: transparent;
                        border: 2px solid #E4E4E4;
                        border-radius: 50%;
                        min-height: 40px;
                        min-width: 40px;
                        
                    }
                }
            }
        }
    }
}
.modality{
    h3{
        color: var(--colorSecondary);
        font-size: 16px;
        font-weight: 400;
        text-align: left;
        padding: 0 0px 10px 0;
    }
    input[type=text], textarea{
        width: 100%;
        border: 2px solid #E9E3E3;
        border-radius: 10px;
        background-color: transparent;
        height: 50px;
        padding-left: 15px;
        outline: 0;
        &:focus, &:hover{
            border: 2px solid #1679FC;
        }
        &::placeholder{
            color: #697891;
        }
    }
    textarea{
        padding-top: 15px;
        height: 150px;
    }
    input[type=file]{
        display: none;
    }
    input[type=file] + label{
        background-color: #fff;
        border: 2px dashed #B8BCC8;
        height: 50px;
        width: 100%;
        border-radius: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        font-size: 16px;
        color: #B8BCC8;
        &:focus, &:hover{
            border: 2px dashed var(--colorPrimary);
            color: var(--colorPrimary);
        }
    }
    .btn-close{
        &:focus{
            outline: 0;
            box-shadow: none;
        }
    }
}

.classSpecial{
    @media (max-width: 991px) {
        padding-bottom: 30vw;
    }
    @media (min-width: 992px) {
        width: 70vw !important;
    }
}

@keyframes fade-in {
    0% { opacity: 0; }
   90% { opacity: 0.9; }
  100% { opacity: 1; }
}
</style>