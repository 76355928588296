<template>
    <div class="container-fluid">
        <Navbar class="d-none d-lg-flex" v-if="windowWidth >= 992"/>
        <NavbarBuscador class="d-lg-none"/>
        <div class="row justify-content-center ">
            <div class="col-11 margin-top-arreglo">
                <Volver_Inicio/>
            </div>
            <div class="col-11 col-md-5 d-flex flex-column justify-content-center align-items-center max__width__login__Desk">
                <TituloYSubtituloRegistro :titulo="$t('message.welcomeBack')"/>
                <UsuarioIngresoAnterior/>
                <div class="row justify-content-center mt-4">
                    <ContinuarComoBTN titulo="Continua como Carla Cooper"/>
                    <NoEresIngresarCon/>
                    <ORegistrarseCon/>
                    <div class="col-12 d-flex flex-column justify-content-center align-items-center px-0">
                        <BTNGoogle :textoBTNGoogle="$t('message.loginGoogle')" class="mb-2"/>
                        <BTNMail/>
                        <!--<BTNTelefonoV2/> -->
                    </div>
                    <IniciarEnlace :cuenta="$t('message.dontHaveAccountYet')" :enlace="$t('message.registerMe')"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import NavbarBuscador from '../../components/Navs/NavbarBuscador.vue';
import Volver_Inicio from '../../components/Volver/Volver_Inicio.vue';
import TituloYSubtituloRegistro from "../../components/Titulo/TituloYSubtituloRegistro.vue";
import ORegistrarseCon from '../../components/Titulo/ORegistrarseCon.vue';
import BTNGoogle from '../../components/Botones/Google.vue';
import IniciarEnlace from '../../components/Enlaces/IniciarEnlace.vue';
import BTNTelefonoV2 from "../../components/Botones/TelefonoV2.vue";
import BTNMail from "../../components/Botones/Mail.vue";
import NoEresIngresarCon from "../../components/Enlaces/NoEresIngresarCon.vue";
import ContinuarComoBTN from "../../components/Botones/ContinuarComo.vue";
import UsuarioIngresoAnterior from "../../components/Usuario/UsuarioIngresoAnterior.vue";
import Navbar from '../../components/Navs/Navbar.vue';

import { provide, ref, computed, onUnmounted, onMounted } from "vue";
import { useRoute, useRouter } from 'vue-router';

const route = useRoute();
const router = useRouter();

const windowWidth = ref(window.innerWidth)

const handleResize = () => {
  windowWidth.value = window.innerWidth
}

let insertedNodes = []

const useHeadTags = async (settings = null) => {
    const url = process.env.VUE_APP_BASE_URL

    try {
        let response = await axios.post(`${url}/api/public/seo/pages`, { slug: settings.slug });

        const tagsString = await response.data.data

        const parser = new DOMParser()
        const doc = parser.parseFromString(
        `<html><head>${tagsString}</head><body></body></html>`,
        'text/html'
        )

        const headNodes = Array.from(doc.head.childNodes)

        headNodes.forEach(node => {
        const nodeName = node.nodeName.toLowerCase()

        switch (nodeName) {
            case 'title':
            document.title = node.textContent ?? ''
            break

            case 'meta': {
            const metaName = node.getAttribute('name')
            if (metaName) {
                const existingMeta = document.head.querySelector(`meta[name="${metaName}"]`)
                if (existingMeta) {
                document.head.removeChild(existingMeta)
                }
            }
            const meta = document.createElement('meta')
            Array.from(node.attributes).forEach(attr => {
                meta.setAttribute(attr.name, attr.value)
            })
            document.head.appendChild(meta)
            insertedNodes.push(meta)
            break
            }

            case 'script': {
            const script = document.createElement('script')
            Array.from(node.attributes).forEach(attr => {
                script.setAttribute(attr.name, attr.value)
            })
            script.textContent = node.textContent
            document.head.appendChild(script)
            insertedNodes.push(script)
            break
            }

            case 'link': {
            const link = document.createElement('link')
            Array.from(node.attributes).forEach(attr => {
                link.setAttribute(attr.name, attr.value)
            })
            document.head.appendChild(link)
            insertedNodes.push(link)
            break
            }

            default:
            console.warn(`Etiqueta <${nodeName}> no manejada.`)
        }
        })
        console.warn('TAGS CARGADOS')
    } catch (error) {
        console.error('Error procesando las etiquetas:', error)
    }
}

router.beforeEach(() => {
    insertedNodes.forEach(node => {
    if (document.head.contains(node)) {
        document.head.removeChild(node)
    }
    })
    insertedNodes = []
})

onMounted(() => {
  window.addEventListener('resize', handleResize)
  useHeadTags({ slug: route.fullPath.slice(1) })
})

onUnmounted(() => {
  window.removeEventListener('resize', handleResize)
})
</script>

<style lang="scss" scoped>

</style>