<template>
    <div class="row justify-content-center margin-top row__width">
        <div class="col-2 col-md-1 col-lg d-flex justify-content-center align-items-center ps-0 pe-1 max-width-filtros">
            <button class="contenedor" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">
                <img src="../../img/home-icons/filtros.svg" alt="">
                <h3 class="d-none d-lg-flex">{{$t('message.showFilters')}}</h3>
                <span class="d-none d-lg-flex"><img src="../../img/home-icons/ver-mas.svg" alt=""></span>
            </button>
        </div>
        <div class="col d-flex justify-content-center align-items-center pe-0">
            <div class="contenedor2 row__width">
                <h3>
                    <template v-if="props.page == 'curses'">
                        {{$t('message.findYourCourse')}}
                    </template>
                    <template v-if="props.page == 'packs'">
                        {{$t('message.findYourPack')}}
                    </template>
                    <template v-if="props.page == 'downloadables'">
                        {{$t('message.findYourDownloadable')}}
                    </template>
                    <template v-if="props.page == 'professionals'">
                        {{$t('message.findYourProfessional')}}
                    </template>
                </h3>
                <form class="w-100 d-flex justify-content-between" @submit.prevent v-on:keydown.enter="inputValue">
                    <input type="text" placeholder="Click aqui" @submit.prevent v-model.trim="search_query">
                    <button type="button" @click="inputValue"><img src="../../img/home-icons/lupa.svg" alt=""></button>
                </form>
            </div>
        </div>
        <!-- CATEGORIAS ACTIVAS -->
        <div class="row mt-4">
            <div class="col-12 d-flex justify-content-between tags-container">                
                <div class="d-flex flex-wrap">
                    <h5 class="col-12 ps-1 pt-2 pb-2 text-start" v-if="active_tags.length > 0">{{ $t('message.activeCategories') }}:</h5>
                    <span 
                        class="tag me-2 mb-2"
                        v-for="(tag, index) in active_tags"
                        :key="index"
                    >
                        {{ tag.item }} 
                        <button class="btn btn-sm btn-danger ms-2" @click="toggleActiveItem(tag.category, tag.item)">X</button>
                    </span>
                </div>
                <div class="d-none d-lg-flex flex-wrap justify-content-end align-items-end">
                    <button 
                        class="btn-deactivate-filters"
                        v-if="active_tags.length > 0"
                        @click="clearAllActiveItems"
                    >
                        Desactivar Todas
                    </button>
                </div>
            </div>
            <div class="d-flex d-lg-none flex-wrap justify-content-end align-items-end">
                <button 
                    class="btn-deactivate-filters"
                    v-if="active_tags.length > 0"
                    @click="clearAllActiveItems"
                >
                    Desactivar Todas
                </button>
            </div>
        </div>
        <div class="offcanvas offcanvas-start col-12 h-100" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
            <div class="offcanvas-body row justify-content-center">
                <div class="col-11 d-flex justify-content-center align-items-start px-1">
                    <div class="row justify-content-center row__width mt-2">
                        <button type="button" class="col-12 contenedor mb-4 z-index-fix" data-bs-dismiss="offcanvas" aria-label="Close">
                            <img src="../../img/home-icons/filtros.svg" alt="">
                            <h3>{{$t('message.hideFilters')}}</h3>
                            <span><img src="../../img/home-icons/cerrar.svg" alt=""></span>
                        </button>
                        <!-- CATEGORIAS ACTIVAS -->
                        <div class="col-12 div__blanco" v-if="active_tags.length > 0">
                            <div class="row justify-content-center filters">
                                <div class="d-flex flex-wrap">
                                    <h2 class="col-12 pb-3 pt-0 text-start">{{ $t('message.activeCategories') }}:</h2>
                                    <span 
                                        class="tag me-2 mb-2"
                                        v-for="(tag, index) in active_tags"
                                        :key="index"
                                    >
                                        {{ tag.item }} 
                                        <button class="btn btn-sm btn-danger ms-2" @click="toggleActiveItem(tag.category, tag.item)">X</button>
                                    </span>
                                    <button 
                                        class="btn-deactivate-filters mt-3"
                                        v-if="active_tags.length > 0"
                                        @click="clearAllActiveItems"
                                    >
                                        Desactivar Todas
                                    </button>
                                </div>
                            </div>
                        </div>
                        <!-- CATEGORIAS -->
                        <div class="col-12 div__blanco" v-if="shouldShowFilters">
                            <div class="row justify-content-center filters" v-for="(categories, full_name) in search_facets" :key="full_name">
                                <div class="col-12 d-flex flex-column justify-content-start align-items-start" v-if="showFilter(full_name)">
                                    <h2 class="pt-0">{{titles[search_type][full_name] || full_name}}</h2>
                                    <div class="justify-content-start align-items-start text-start px-0 w-100">
                                        <p 
                                            class="ms-3"
                                            v-for="(name, index) in Object.keys(categories).slice(0, visible_items[full_name])"
                                            :key="index"
                                            :class="{ active: active_items[full_name]?.includes(name) }"
                                            @click="toggleActiveItem(full_name, name)"
                                        >
                                            {{ name }}
                                        </p>
                                    </div>
                                    <!-- BOTON VER MAS -->
                                    <button
                                        v-if="Object.keys(categories).length > visible_items[full_name]"
                                        class="btn-deactivate-filters mb-2"
                                        @click="showMore(full_name)"
                                    >
                                        {{ $t('message.seeMore') }}
                                        <img class="ms-3" src="../../img/home-icons/cursos-icons/arrow-abajo-azul.svg" alt="">
                                    </button>
                                </div>
                            </div>
                        </div>
                        <!-- RANGO DE PRECIOS -->
                        <div class="col-12 div__blanco" v-if="Object.keys(search_prices).length > 0">
                            <div class="row justify-content-center filters">
                                <div class="col-12 d-flex flex-column justify-content-start align-items-start" v-if="showFilter(full_name)">
                                    <h2 class="pt-0">{{ $t('message.pricesRange') }}</h2>
                                    <div class="justify-content-start align-items-start text-start px-0 w-100 range-slider" v-if="prices_range.lowest != undefined && prices_range.highest != undefined">
                                        <div class="slider-container">
                                            <!-- Línea de rango -->
                                            <input
                                                type="range"
                                                class="slider"
                                                :min="prices_range.lowest"
                                                :max="prices_range.highest"
                                                v-model.number="selected_range.min"
                                                @input="ensureRangeOrder"
                                                @mouseup="setPriceOnFilter"
                                            />
                                            <input
                                                type="range"
                                                class="slider bg-transparent"
                                                :min="prices_range.lowest"
                                                :max="prices_range.highest"
                                                v-model.number="selected_range.max"
                                                @input="ensureRangeOrder"
                                                @mouseup="setPriceOnFilter"
                                            />

                                            <!-- Mostrar los valores actuales -->
                                            <div class="range-values">
                                                <span>Min: {{ selected_range.min }}</span>
                                                <span>Max: {{ selected_range.max }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- PONENTES -->
                        <div class="col-12 div__blanco" v-if="Object.keys(search_ponents).length > 0">
                            <div class="row justify-content-center filters" v-for="(categories, full_name) in search_ponents" :key="full_name">
                                <div class="col-12 d-flex flex-column justify-content-start align-items-start" v-if="showFilter(full_name)">
                                    <h2 class="pt-0">{{titles[search_type][full_name] || full_name}}</h2>
                                    <div class="justify-content-start align-items-start text-start px-0 w-100">
                                        <p 
                                            class="ms-3"
                                            v-for="(name, index) in Object.keys(categories).slice(0, visible_items[full_name])"
                                            :key="index"
                                            :class="{ active: active_items[full_name]?.includes(name) }"
                                            @click="toggleActiveItem(full_name, name)"
                                        >
                                            {{ name }}
                                        </p>
                                    </div>
                                    <!-- BOTON VER MAS -->
                                    <button
                                        v-if="Object.keys(categories).length > visible_items[full_name]"
                                        class="btn-deactivate-filters mb-2"
                                        @click="showMore(full_name)"
                                    >
                                        {{ $t('message.seeMore') }}
                                        <img class="ms-3" src="../../img/home-icons/cursos-icons/arrow-abajo-azul.svg" alt="">
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 d-flex flex-column justify-content-center align-items-center">
                            <button class="filtrar" data-bs-dismiss="offcanvas" aria-label="Close">
                                {{$t('message.cancel')}}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, provide, inject, computed, onUnmounted, onBeforeUnmount, onMounted, watch, nextTick } from 'vue'
import { useUsersStore } from "../../stores/user"
import { useGeneralStore } from "../../stores/general"    
import { storeToRefs } from "pinia"
import axios from 'axios'

const users = useUsersStore()
const {  } = storeToRefs(users)
const { url } = users

const general = useGeneralStore()
const { reSearch } = storeToRefs(general)
const {  } = general

const props = defineProps({
    page: String
})

const lang = ref('ES')

const search_type = inject('search_type')
const search_query = inject('search_query')
const search_facets = inject('search_facets')
const search_ponents = inject('search_ponents')
const search_prices = inject('search_prices')
const prices_range = inject('prices_range')
const selected_range = inject('selected_range')
const filter_prices = inject('filter_prices')
const search_box = inject('search_box')
const search_results_list = inject('search_results_list')
const is_searching = inject('is_searching')
const active_items = inject('active_items')
const visible_items = inject('visible_items')

console.log(search_facets.value)

const getSearchResults =  inject('getSearchResults')
const setPriceOnFilter = inject('setPriceOnFilter')
const formatPrices = inject('formatPrices')

//Seteo el parametro de busqueda
const inputValue = () => {
    reSearch.value = !reSearch.value
    getSearchResults(0)
}

const hidden = {
    'class.translations.ES.name': 'class.translations.ES.name',
    'country.translations.ES.name': 'country.translations.ES.name',
    'state.translations.ES.name': 'state.translations.ES.name',
    'city.translations.ES.name': 'city.translations.ES.name',
    'prices.MXN.finalPrice': 'prices.MXN.finalPrice',
    'schedules': 'schedules',
    'rating': 'rating',
    'ranking': 'ranking',
    'location': 'location',
    'geolocation': 'geolocation',
    'geolocation.lat': 'geolocation.lat',
    'geolocation.lng': 'geolocation.lng',
    'geolocation.latitude': 'geolocation.latitude',
    'geolocation.longitude': 'geolocation.longitude',
}

const titles = {
    courses: {
        'type.translations.ES.name': 'Modalidad',
        'professions.translations.ES.name': 'Profesiones',
        'specializations.translations.ES.name': 'Especializaciones',
        'owner.name': 'Ponentes',
        'country.translations.ES.name': 'País',
        'state.translations.ES.name': 'Estado',
        'city.translations.ES.name': 'Ciudad',
    },
    professionals: {
        'professions.translations.ES.name': 'Profesiones',
        'specializations.translations.ES.name': 'Especializaciones',
        'country.translations.ES.name': 'País',
        'state.translations.ES.name': 'Estado',
        'city.translations.ES.name': 'Ciudad',
    },
    downloadables: {
        'type.translations.ES.name': 'Modalidad',
        'professions.translations.ES.name': 'Profesiones',
        'specializations.translations.ES.name': 'Especializaciones',
        'owner.name': 'Ponentes',
    },
    packages: {
        'type.translations.ES.name': 'Modalidad',
        'professions.translations.ES.name': 'Profesiones',
        'specializations.translations.ES.name': 'Especializaciones',
        'owner.name': 'Ponentes',
    }
}

// Función para verificar si mostrar un filtro
const showFilter = (full_name) => {
    return !hidden[full_name];
};

// Función para mostrar más resultados
const showMore = (category) => {
    visible_items.value[category] = visible_items.value[category].length;
};

// Función para alternar el estado activo de un elemento
const toggleActiveItem = (category, item) => {
    if (!active_items.value[category]) {
        // Inicializa la categoría si no existe
        active_items.value[category] = [];
    }
    const index = active_items.value[category].indexOf(item);
    if (index === -1) {
        // Si no está activo, agrégalo
        active_items.value[category].push(item);
    } else {
        // Si ya está activo, elimínalo
        active_items.value[category].splice(index, 1);
    }
};

// Computed para obtener todas las etiquetas activas
const active_tags = computed(() => {
    const tags = [];
    Object.entries(active_items.value).forEach(([category, items]) => {
        items.forEach(item => {
        tags.push({ category, item });
        });
    });
    return tags;
});

const clearAllActiveItems = () => {
    Object.keys(active_items.value).forEach(category => {
        active_items.value[category] = [];
    });
    Object.keys(visible_items.value).forEach(category => {
        visible_items.value[category] = 5;
    });
    selected_range.value = Object.keys(search_prices.value).length > 0 ? {
        min: prices_range.value.lowest,
        max: prices_range.value.highest,
    } : {};
};

// Asegura que el rango sea válido (min siempre menor o igual a max)
const ensureRangeOrder = () => {
    if (selected_range.value.min > selected_range.value.max) {
        [selected_range.value.min, selected_range.value.max] = [
            selected_range.value.max,
            selected_range.value.min,
        ];
    }
};

// Función para chequear el contenido de cada indice
const shouldShowFilters = computed(() => {
    // Accedemos a search_facets.value porque es un ref
    const noAvailableCategories = Object.keys(search_facets.value).every(key => {
        const items = search_facets.value[key];
        console.log(items)
        // Verificar si los items están vacíos
        if (typeof items !== 'object' || items === null || Object.keys(items).length === 0) {
            return true; // Tratar como vacío
        }

        // Filtrar las categorías disponibles que NO están activas
        const availableCategories = Object.keys(items).filter(item => 
            !active_tags.value.some(tag => tag.item === item && tag.category === key)
        );

        // Si no quedan categorías disponibles, tratamos la categoría como vacía
        return availableCategories.length === 0;
    });

    // Mostrar los filtros si hay categorías activas o disponibles
    return active_tags.value.length > 0 || !noAvailableCategories;
});

watch(
    () => JSON.stringify(search_prices.value),
    async (newValue) => {
        if (filter_prices.value) {
            formatPrices()
            await nextTick()
        }
    }
)

</script>


<style lang="scss" scoped>
.margin-top{
    @media (min-width: 992px) {
        margin-top: 95px;
    }
}
.contenedor{
    box-shadow: 0px 3px 6px #00000029;
    background-color: #fff;
    border: 1px solid #ECECEC;
    border-radius: 40px;
    height: 66px;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    h3{
        color: #374557;
        font-size: 16px;
        font-weight: 600;
        padding: 5px 10px;
    }
    span{
        position: absolute;
        right: 7px;
        border: 0;
        min-height: 50px;
        width: 50px;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--bgPrincipal);
        img{
            max-height: 20px;
        }
    }
    .azul{
        position: absolute;
        left: 7px;
        height: 60px;
        width: 60px;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--colorPrimary); 
    }
    .mapa{
        padding-left: 23%;
    }
    @media (max-width: 992px) {
        margin-bottom: 5px;
       
    }
    @media (min-width: 992px) {
        justify-content: flex-start;
    }
}
.div__blanco{
    box-shadow: 0px 3px 10px #00000029;
    border-radius: 10px;
    min-height: 88px;
    margin: 0px 0px 25px 0px;
    padding: 30px;
    h2{
        font-weight: 600;
        font-size: 20px;
        color: #374557;
        padding: 15px 0px;
    }
    p, label{  
        font-weight: 400;
        font-size: 16px;
        padding: 0px 0px 7px 0px;
        color: #697891;
    }
    .active{
        font-weight: 700;
        color: var(--colorPrimary);
        display: none;
    }
    
}
.accordion{
    .accordion-item{
        .accordion-button{
            font-weight: 600;
            font-size: 20px;
            color: #374557;
            padding: 0px 0px;
            background-color: transparent;
            border: 0;
            box-shadow: none;
            border-radius: 0;
            &:focus{
                box-shadow: none;
            }
        }
        .accordion-body{
            padding: 10px 0;
            h3{
                font-weight: 500;
                font-size: 16px;
                color: #697891;
                padding-left: 5px;
            }
            .perfil{
                max-height: 41px;
            }
            .verificado{
                position: absolute;
                top: 0;
                right: 0;
                max-height: 14px;
            }
            .mas{
                border: 2px solid var(--colorPrimary);
                background-color: var(--bgPrincipal);
                border-radius: 25px;
                height: 28px;
                width: 28px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: var(--colorPrimary);
                font-size: 15px;
                font-weight: 600;
            }
            .scroll{
                max-height: 20vh;
                overflow-x: hidden;
                overflow-y: scroll;
                .div__contenedor{
                    margin-bottom: 10px;
                    width: 98%;
                }
                input{
                    display: none;
                }
                input + label{  
                    font-weight: 400;
                    font-size: 16px;
                    padding: 0px 0px 7px 0px;
                    color: #697891;
                    width: 100%;
                    height: 44px;
                    padding-left: 15px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    padding-bottom: 0;
                }
                input:checked + label{
                    background-color: #F8F8F9;
                    border-radius: 22px;
                    font-weight: 600;
                    color: var(--colorPrimary);
                }
                &::-webkit-scrollbar{
                    background-color: rgba(219, 219, 219, 0.25);
                    width: 8px;
                    border-radius: 20px;
                }
                &::-webkit-scrollbar-thumb{
                    background-color: #DBDBDB;
                    border-radius: 50px;
                }
            }
        }
        .accordion-button::after{
            background-image: url('../../img/home-icons/cursos-icons/arrow-abajo-negro.svg');
            background-size: 80%;
            background-position: center;
        }
        .ponente{
            padding: 10px 0px;
            &:first-child{
                padding-top: 15px;
            }
        }
    }
}
.filtrar{
    background-color: var(--colorPrimary);
    color: #fff;
    width: 100%;
    height: 50px;
    border-radius: 28px;
    font-weight: 600;
    font-size: 16px;
    color: #FAFAFA; 
    border: 0;
    img{
        padding: 0px 5px;
    }
}
.cancelar{
    margin-top: 20px;
    font-weight: 400;
    font-size: 16px;
    color: #697891;
}
.mapa__seccion{
    padding: 0;
    .position{
        position: absolute;
    }
    .top{
        top: 3%;
    }
    .center{
        top: 7%;
        min-height: 70vh;
    }
    .end{
        bottom: 2%;
        button{
            margin-left: 10px;
        }
    }
    .zoom{
        position: fixed;
        top: 50vh;
        right: 10px;
        button{
            margin: 0 0 10px 0px;
        }
    }
    .pin{
        width: 120px;
        height: 120px;
        border-radius: 50%;
        background-color: var(--bgPrincipal);
        box-shadow: 0px 3px 6px #00000029;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: 0;
        .num{
            position: absolute;
            top: 0;
            right: 0px;
            width: 47px;
            height: 47px;
            border-radius: 50%;
            background-color: var(--colorPrimary);
            font-size: 21px;
            font-weight: 600;
            color: #FAFAFA;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    .botones{
        button{
            background-color: var(--bgPrincipal);
            box-shadow: 0px 3px 10px #00000029;
            border-radius: 10px;
            border: 0;
            width: 50px;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            span{
                width: 27px;
                height: 27px;
                box-shadow: 0px 3px 6px #00000029;
                background-color: var(--bgPrincipal);
                border-radius: 52px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #000000;
                font-weight: 600;
                font-size: 16px;
            }
            &:focus{
                outline: 0;
            }
        }
        .azul{
            background-color: var(--colorPrimary);
        }
    }
}
.offcanvas{
    width: 100% !important;
}
@media (min-width: 992px) {
    .max-width-filtros{
        max-width: 25%;
    }
    .desk-max-width{
        max-width: 5%;
    }
    .margin-top{
        margin-top: 35px;
    }
    .offcanvas{
        max-width: 350px !important;
        top: 95px !important;
        max-height: calc(100vh - 95px) !important;
    }
}
@media (min-width: 1300px) {
    .max-width-filtros{
        max-width: 19%;
    }
}
.contenedor2{
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #ECECEC;
    background-color: var(--bgPrincipal);
    border-radius: 50px;
    min-height: 66px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0px 10px;
    h3{
        color: var(--colorSecondary);
        font-size: 16px;
        font-weight: 500;
        padding: 0px 0px;
        @media (min-width: 992px) {
            font-size: 18px;
        }
    }
    input{
        border: 0;
        font-weight: 400;
        font-size: 14px;
        width: 100%;
        &::placeholder{
            color: #B8C1DB;
        }
        &:focus{
            outline: 0;
        }
        @media (min-width: 992px) {
            font-size: 15px;
        }
    }
    button{
        position: absolute;
        right: 10px;
        top: 6px;
        border: 0;
        min-height: 53px;
        width: 53px;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 50%;
        background-color: var(--colorPrimary);
        justify-content: center;
        align-items: center;
        img{
            padding: 0;
        }
    }
    .border-left{
        border-left: 1px solid rgba(184, 193, 219, 0.4);
    }
    
}
.z-index-fix{
    z-index: 1046;
}
.filters{
    p{
        cursor: pointer;
        user-select: none;
    }
    p:first-letter, h2:first-letter{
        text-transform: uppercase;
    }
    p:hover{
        font-weight: 700;
    }
}
.filtrar-boton{
    background-color: var(--colorPrimary);
    color: #fff;
    width: 75%;
    height: 35px;
    border-radius: 28px;
    font-weight: 600;
    font-size: 16px;
    color: #FAFAFA; 
    border: 0;
    img{
        padding: 0px 5px;
    }
}

.input-price{
    border: 1px solid #69789149;
    border-radius: 10px;
    font-weight: 400;
    font-size: 12px;
    padding: 3px 5px;
    &:focus{
        outline: 0;
        border-color: var(--colorPrimary);
    }
}

.tags-container {
    border-radius: 10px;
    background-color: #F5F5F5;
}

.tag {
    display: inline-flex;
    align-items: center;
    background-color: var(--colorPrimary);
    border-radius: 15px;
    padding: 5px 10px;
    font-size: 14px;
    border: 2px solid var(--colorPrimary);
    color: var(--bgPrincipal);
    font-weight: 500;
}

.tag button {
    background: none;
    border: none;
    color: #F5F5F5;
    cursor: pointer;
    font-weight: 700;
}

.btn-deactivate-filters{
    background-color: var(--bgPrincipal);
    padding-left: 15px;
    font-weight: 600;
    font-size: 16px;
    color: var(--colorPrimary);
}

.range-slider {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    text-align: center;
}

.slider-container {
    position: relative;
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
}

.slider {
    width: 100%;
    -webkit-appearance: none;
    appearance: none;
    background: #ddd;
    height: 4px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    .bg-transparent{
        background: transparent;
    }
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    background: #007bff;
    border-radius: 50%;
    cursor: pointer;
    pointer-events: auto;
    position: relative;
    z-index: 30;
}

.slider::-moz-range-thumb {
    width: 20px;
    height: 20px;
    background: #007bff;
    border-radius: 50%;
    cursor: pointer;
    pointer-events: auto;
    position: relative;
    z-index: 30;
}

.range-values {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 40px;
}
</style>