<template>
    <div class="col d-flex flex-column justify-content-start align-items-center slide" style="height: 355px;" v-for="(professional, id) in search_results_list" v-if="search_results_list.length >= 1" @click="router.push({name: 'Profesional', params: {slug: professional.slug}})">
        <div class="row justify-content-center row__width pt-3 imagenes" v-if="professional.images && professional.images.length == 3">
            <div class="col d-flex justify-content-center align-items-center pe-0 position-relative">
                <img :src="professional.images[0].file" alt="image-1" class="foto_principal">
                <!-- <span>{{professional.ranking}}</span> -->
            </div>
            <div class="col d-flex justify-content-center align-items-between arreglo">
                <div class="row justify-content-center">
                    <div class="col-12 d-flex justify-content-center align-items-center padding">
                        <img :src="professional.images[1].file" alt="image-2" class="foto_secundaria">
                    </div> 
                    <div class="col-12 d-flex justify-content-center align-items-center padding">
                        <img :src="professional.images[2].file" alt="image-3" class="foto_secundaria">
                    </div> 
                </div>
            </div>
        </div>
        <div class="row justify-content-center row__width pt-3 imagenes" v-else>
            <div class="col d-flex justify-content-center align-items-center pe-0 position-relative">
                <img :src="professional.avatar" alt="image-1" class="foto_principal">
                <!-- <span>{{professional.ranking}}</span> -->
            </div>
            <div class="col d-flex justify-content-center align-items-between arreglo">
                <div class="row justify-content-center">
                    <div class="col-12 d-flex justify-content-center align-items-center padding">
                        <img :src="professional.avatar" alt="image-2" class="foto_secundaria">
                    </div> 
                    <div class="col-12 d-flex justify-content-center align-items-center padding">
                        <img :src="professional.avatar" alt="image-3" class="foto_secundaria">
                    </div> 
                </div>
            </div>
        </div>
        <div class="row justify-content-center descripcion row__width">
            <div class="col d-flex justify-content-center align-items-start max-width">
                <img :src="professional.avatar" alt="avatar" class="foto_perfil">
            </div>
            <div class="col-9 d-flex justify-content-center align-items-center px-0">
                <div class="row justify-content-center row__width">
                    <div class="col-12 d-flex justify-content-start align-items-center">
                        <h3 class="text-fix">{{ professional.name }}</h3>
                        <!-- <img src="../../img/home-icons/cursos-icons/verficacion-perfil.svg" alt="" class="ms-2"> -->
                    </div>
                    <div class="col-12 d-flex justify-content-start align-items-center" v-if="professional.country && professional.location">
                        <h4 class="text-fix pt-1 pb-2">{{ countryCodeToFlagEmoji(professional.country.code) }} {{ professional.location }}</h4>
                    </div>
                    <div class="col-6 d-flex justify-content-start align-items-center row__width">
                        <h6 class="max-width-h6">
                            <template v-for="(specialization, index) in professional.specializations" :key="specialization">
                                {{ specialization.translations[lang].name }}
                                <template v-if="index < professional.specializations.length - 1">, </template>
                            </template>
                        </h6>
                    </div>
                </div>
            </div>
        </div>
        <!-- <span class="borde"></span>
        <div class="row justify-content-center comprar row__width d-none">
            <div class="col-5 d-flex flex-column justify-content-center align-items-start ps-4">
                <h2>{{$t('message.from')}}</h2>
                <h5 class="text-fix">{{ professional.slug }}</h5>
            </div>
            <div class="col-7 d-flex justify-content-center align-items-center">
                <router-link :to="{name: 'Profesional', params: {slug: professional.slug}}" class="btn__primario">
                    {{$t('message.viewProfile')}}
                </router-link>
            </div>
        </div> -->
    </div>
    
    <!-- SKELETON -->
    <div class="col d-flex flex-column justify-content-start align-items-center slide " v-if="is_searching" v-for="(n, id) in 12" :key="n">
        <div class="row justify-content-center row__width pt-3 imagenes">
            <div class="col d-flex justify-content-center align-items-center pe-0 position-relative">
                <div class="foto_principal_skeleton"></div>
            </div>
            <div class="col d-flex justify-content-center align-items-between arreglo">
                <div class="row justify-content-center">
                    <div class="col-12 d-flex justify-content-center align-items-center padding">
                        <div class="foto_secundaria_skeleton"></div>
                    </div> 
                    <div class="col-12 d-flex justify-content-center align-items-center padding">
                        <div class="foto_secundaria_skeleton"></div>
                    </div> 
                </div>
            </div>
        </div>
        <div class="row justify-content-center descripcion row__width">
            <div class="col d-flex justify-content-center align-items-start max-width">
                <div class="foto_perfil_skeleton"></div>
            </div>
            <div class="col-9 d-flex justify-content-center align-items-center px-0">
                <div class="row justify-content-center row__width">
                    <div class="col-12 d-flex justify-content-start align-items-center">
                        <div class="text_skeleton text_skeleton_large"></div>
                    </div>
                    <div class="col-12 d-flex justify-content-start align-items-center">
                        <div class="text_skeleton text_skeleton_medium"></div>
                    </div>
                    <div class="col-12 d-flex justify-content-start align-items-center">
                        <div class="text_skeleton text_skeleton_small"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row justify-content-center comprar row__width d-none">
            <div class="col-5 d-flex flex-column justify-content-center align-items-start ps-4">
                <div class="text_skeleton text_skeleton_medium"></div>
            </div>
            <div class="col-7 d-flex justify-content-center align-items-center">
                <div class="btn_skeleton"></div>
            </div>
        </div>
    </div>
    
</template>

<script setup>
import BTNPrimario from "../Botones/PrimarioRutas.vue";
import TituloSinResultados from "../../components/Titulo/TituloSinResultados.vue";
import MasterclassTitulo from '../../components/Titulo/MasterclassTitulo.vue'

import { useGeneralStore } from "../../stores/general"
import { useUsersStore } from "../../stores/user"
import { storeToRefs } from "pinia";
import { ref, watch, nextTick, onMounted, inject } from 'vue';
import { useI18n } from "vue-i18n";
import axios from 'axios';
import { useRouter, useRoute } from 'vue-router';

import { polyfillCountryFlagEmojis } from "country-flag-emoji-polyfill";

onMounted(() => {
    polyfillCountryFlagEmojis();
});

function countryCodeToFlagEmoji(countryCode) {
    return countryCode
        .toUpperCase()
        .replace(/./g, char => String.fromCodePoint(127397 + char.charCodeAt()));
}

const router = useRouter()
const route = useRoute();

const general = useGeneralStore();
const {textos, filtrosDesplegados, mapaDesplegado, reSearch} = storeToRefs(general);
const {} = general;

const users = useUsersStore()
const {  } = storeToRefs(users)
const { url } = users

const {locale} = useI18n({ useScope: 'global' })
const lang = ref('ES')
const X = ref('')
const gallery = ref([])

const urlApi = '/multi-search'

const loading = ref(true);

//Setea parametros de busqueda para el post
const search = ref({
    queries:[{
        indexUid: 'professionals',
        q:''
    }]
})

//Precarga parametros de resultados de busqueda
const childProducts = ref(0)
const sliderLength = ref(0)

const is_searching = inject('is_searching')
const search_results_list = inject('search_results_list')
const professional_list = inject('professional_list')

const getSearchResults = inject('getSearchResults')

//console.log(professional_list.value)

</script>

<style lang="scss" scoped>


.slide{
    cursor: pointer;
    height: 360px;
    max-height: 360px;
    @media (max-width: 992px) {
        height: 390px;
        max-height: 390px;
    }
    .imagenes{
        .padding{
            padding: 0px 10px 10px 10px;
            &:last-child{
                padding-bottom: 0;
            }
        }
        img{
            max-width: 100%;
            height: 100%;
            width: 100%;
            object-fit: cover;
            border-radius: 10px;
            box-shadow: 0px 3px 6px #00000029;
        }
        .arreglo{
            max-width: 33%;
        }
        span{
            background-color: #fff;
            box-shadow: 0px 3px 6px #00000029;
            height: 47px;
            width: 47px;
            border-radius: 50%;
            color: var(--colorPrimary);
            font-size: 18px;
            font-weight: 600;
            text-transform: uppercase;
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            left: 20px;
            top: 6%;
        }
        .foto_principal{
            height: 238px;
        }
        .foto_secundaria{
            height: 114px;
        }
    }
    .descripcion{
        padding: 20px 0px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        .foto_perfil{
            border-radius: 50%;
            min-height: 72px;
            min-width: 72px;
            max-height: 72px;
            max-width: 72px;
            object-fit: cover;
        }
        .max-width{
            max-width: 25%;
            padding-right: 0;
        }
        h3{
            font-size: 22px;
            font-weight: 700;
            color: #374557;
            text-align: left;
        }
        h4{
            font-size: 16px;
            font-weight: 400;
            color: var(--colorSecondary);
            padding: 10px 0px;
        }
        h6{
            font-weight: 400;
            font-size: 14px;
            color: #B8C1DB;
            text-align: left;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
        .text-fix {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: 90%
        }
    }
    .comprar{
        padding: 0px 0px 10px 0;
        h2{
            color: #B8C1DB;
            font-weight: 400;
            font-size: 16px;        
        }
        h5{
            font-weight: 700;
            font-size: 23px;
            color: var(--colorSecondary);
        }
        @media (min-width: 992px) {
            padding: 10px 0;
        }
    }
    .borde{
        width: 95%;
        height: 1px;
        background-color: rgba(184, 193, 219, 0.3);
    }
}




.foto_principal_skeleton,
.foto_secundaria_skeleton,
.foto_perfil_skeleton,
.text_skeleton,
.btn_skeleton {
    background-color: #e0e0e0;
    border-radius: 4px;
    animation: skeleton-loading 1.2s infinite linear;
}

.foto_principal_skeleton {
    width: 100%;
    height: 240px;
}

.ranking_skeleton {
    width: 50px;
    height: 20px;
    background-color: #e0e0e0;
    border-radius: 10px;
    position: absolute;
    top: 10px;
    left: 10px;
}

.foto_secundaria_skeleton {
    width: 80px;
    height: 105px;
    margin-bottom: 10px;
}

.foto_perfil_skeleton {
    width: 72px;
    height: 72px;
    border-radius: 50%;
}

.text_skeleton {
    height: 15px;
    margin-bottom: 10px;
}

.text_skeleton_large {
    width: 70%;
    margin-top: 7px;
}

.text_skeleton_medium {
    width: 50%;
}

.text_skeleton_small {
    width: 30%;
}

.borde_skeleton {
    width: 100%;
    height: 2px;
    background-color: #e0e0e0;
    margin: 10px 0;
}

.btn_skeleton {
    width: 100px;
    height: 30px;
    border-radius: 4px;
}

@keyframes skeleton-loading {
    0% {
        background-color: #e0e0e0;
    }
    50% {
        background-color: #f0f0f0;
    }
    100% {
        background-color: #e0e0e0;
    }
}

.pagination{
    .page-item{
        border: 0;
        border-radius: 25px;
        margin: 0 2px;
        .page-link{
            border-radius: 25px;
            border: 0;
            font-weight: 500;
            color: var(--colorSecondary);
            font-family: 'Poppins';
            a{
                &:hover{
                    background-color: var(--colorPrimary);
                    color: var(--colorSecondary);
                }
            }
            &:focus{
                box-shadow: none;
            }
        }
    }
    .buttons{
        border-radius: 15px;
        .page-link{
            border-radius: 15px;
        }
    }
    
    .active{
        background-color: var(--colorPrimary);
        .page-link{
            color: #fff !important;
        }
        a{
            color: #fff !important;
        }
    }

}
</style>