<template>
    <div class="container-fluid usuario-sesion">
        <NavbarBuscador/>
        <!--- PEDIDO PENDIENTE --->
        <div class="row justify-content-center align-items-center contenedor" v-if="payment && status == 'PENDING'">
            <div class="col-12 d-flex flex-column justify-content-center align-items-center px-0">
                <div class="row justify-content-center row__width titulo">
                    <div class="col-12 d-flex justify-content-center align-items-center my-4">
                        <img src="../../img/plus-icons/pago-aprobado.svg" alt="">
                    </div>
                    <div class="col-12 d-flex flex-column justify-content-center align-items-center">
                        <h2>¡Gracias! Su pedido está en estado:</h2><span>Pendiente</span>
                    </div>
                </div>
            </div>
            <div class="d-flex flex-column justify-content-center align-items-center max-width">
                <div class="row justify-content-center row__width detalles" v-if="payment">
                    <div class="col-12 d-flex justify-content-center align-items-center px-0">
                        <p>Número del pedido: #{{ payment.id }}</p>
                    </div>
                    <div class="col-12 d-flex justify-content-between align-items-center px-0 pb-4" v-for="(product, index) in payment.products">
                        <h3 class="title-fix">{{ product.name }} # {{ index + 1 }}</h3>
                        <h3 class="text-right">{{ product.currency }} ${{ product.real_price }}</h3>
                    </div>
                    <div class="col-12 d-flex justify-content-between align-items-center px-0 pb-3">
                        <h5>{{$t('message.subtotal')}}:</h5>
                        <h3>{{ payment.currency }} ${{ payment.subtotal }}</h3>
                    </div>
                    <div class="col-12 d-flex justify-content-between align-items-center px-0 pb-4" >
                        <h5>{{ $t('message.disccounts') }}:</h5>
                        <h3>{{ payment.currency }} {{ payment?.discount > 0 ? '-' : '' }}${{ payment.discount }}</h3>
                    </div>
                    <div class="col-12 d-flex justify-content-between align-items-center px-0 pt-3">
                        <h6>{{ $t('message.total') }}</h6>
                        <h6>{{ payment.currency }} ${{ payment.total }}</h6>
                    </div>
                    <div class="col-12 d-flex justify-content-end align-items-center px-0 d-none">
                        <p class="metodo">{{ payment.method }}</p>
                    </div>
                </div>
                <BTNPrimario :textoBTNPrimario="$t('message.viewCourseNow')" class="margin-top d-none"/>
                <div class="row justify-content-center row__width pt-lg-4">
                    <div class="col-12  d-flex justify-content-center align-items-center">
                        <p class="recuerda">{{ $t('message.checkYourEmail') }}</p>
                    </div>
                </div>
                <VolverPagina titulo="Volver al inicio" class="d-lg-none"/>
            </div>
        </div>
        <!--- PEDIDO FALLIDO --->
        <div class="row justify-content-center align-items-center contenedor" v-if="payment && status == 'REJECTED'">
            <div class="col-12 d-flex flex-column justify-content-center align-items-center px-0">
                <div class="row justify-content-center row__width titulo">
                    <div class="col-12 d-flex justify-content-center align-items-center my-4">
                        <img src="../../img/plus-icons/pago-aprobado.svg" alt="">
                    </div>
                    <div class="col-12 d-flex flex-column justify-content-center align-items-center">
                        <h2>¡Tuvimos un problema! Su pedido fue:</h2><span>Rechazado</span>
                    </div>
                </div>
            </div>
            <div class="d-flex flex-column justify-content-center align-items-center max-width">
                <div class="row justify-content-center row__width detalles d-none">
                    <div class="col-12 d-flex justify-content-center align-items-center px-0">
                        <p>Número del pedido: #{{ payment.id }}</p>
                    </div>
                    <div class="col-12 d-flex justify-content-between align-items-center px-0 pb-4" v-for="(product, index) in payment.products">
                        <h3 class="title-fix">{{ product.name }} # {{ index + 1 }}</h3>
                        <h3>{{ product.currency }} ${{ product.real_price }}</h3>
                    </div>
                    <div class="col-12 d-flex justify-content-between align-items-center px-0 pb-3">
                        <h5>{{$t('message.subtotal')}}:</h5>
                        <h3>{{ payment.currency }} ${{ payment.subtotal }}</h3>
                    </div>
                    <div class="col-12 d-flex justify-content-between align-items-center px-0 pb-4" >
                        <h5>{{ $t('message.disccounts') }}:</h5>
                        <h3>{{ payment.currency }} {{ payment?.discount > 0 ? '-' : '' }}${{ payment.discount }}</h3>
                    </div>
                    <div class="col-12 d-flex justify-content-between align-items-center px-0 pt-3">
                        <h6>{{ $t('message.total') }}</h6>
                        <h6>{{ payment.currency }} ${{ payment.total }}</h6>
                    </div>
                    <div class="col-12 d-flex justify-content-end align-items-center px-0 d-none">
                        <p class="metodo">{{ $t('message.paypalText') }}</p>
                    </div>
                </div>
                <div class="row justify-content-center row__width pt-lg-4">
                    <div class="col-12  d-flex justify-content-center align-items-center">
                        <p class="recuerda">Por favor comuníquese con nosotros para resolver este problema</p>
                    </div>
                </div>
                <VolverPagina titulo="Volver al inicio" class="d-lg-none"/>
            </div>
        </div>
        <!--- PEDIDO APROBADO --->
        <div class="row justify-content-center align-items-center contenedor" v-if="payment && status == 'SUCCESS'">
            <div class="col-12 d-flex flex-column justify-content-center align-items-center px-0">
                <div class="row justify-content-center row__width titulo">
                    <div class="col-12 d-flex justify-content-center align-items-center my-4">
                        <img src="../../img/plus-icons/pago-aprobado.svg" alt="">
                    </div>
                    <div class="col-12 d-flex flex-column justify-content-center align-items-center">
                        <h2>{{$t('message.thanksYorRequest')}} </h2><span>{{$t('message.approved')}}</span>
                    </div>
                </div>
            </div>
            <div class="d-flex flex-column justify-content-center align-items-center max-width">
                <div class="row justify-content-center row__width detalles" v-if="payment">
                    <div class="col-12 d-flex justify-content-center align-items-center px-0">
                        <p>Número del pedido: #{{ payment.id }}</p>
                    </div>
                    <div class="col-12 d-flex justify-content-between align-items-center px-0 pb-4" v-for="(product, index) in payment.products">
                        <h3 class="title-fix">{{ product.name }} # {{ index + 1 }}</h3>
                        <h3 class="text-rigth">{{ product.currency }} ${{ product.real_price }}</h3>
                    </div>
                    <div class="col-12 d-flex justify-content-between align-items-center px-0 pb-3">
                        <h5>{{$t('message.subtotal')}}:</h5>
                        <h3>{{ payment.currency }} ${{ payment.subtotal }}</h3>
                    </div>
                    <div class="col-12 d-flex justify-content-between align-items-center px-0 pb-4" >
                        <h5>{{ $t('message.disccounts') }}:</h5>
                        <h3>{{ payment.currency }} {{ payment?.discount > 0 ? '-' : '' }}${{ payment.discount }}</h3>
                    </div>
                    <div class="col-12 d-flex justify-content-between align-items-center px-0 pt-3">
                        <h6>{{ $t('message.total') }}</h6>
                        <h6>{{ payment.currency }} ${{ payment.total }}</h6>
                    </div>
                    <div class="col-12 d-flex justify-content-end align-items-center px-0 d-none">
                        <p class="metodo">{{ payment.payment_gateway }}</p>
                    </div>
                </div>
                <BTNPrimario v-if="payment.products.length == 1" :textoBTNPrimario="$t('message.viewCourseNow')" class="margin-top" 
                @click="router.push('/viendo-curso/'+payment.products[0].slug+'-'+payment.products[0].id)"/>
                <BTNPrimario v-else :textoBTNPrimario="$t('message.viewCourseNow')" class="margin-top" 
                @click="router.push('/mis-cursos')"/>
                <div class="row justify-content-center row__width pt-lg-4">
                    <div class="col-12  d-flex justify-content-center align-items-center">
                        <p class="recuerda">{{ $t('message.checkYourEmail') }}</p>
                    </div>
                </div>
                <VolverPagina titulo="Volver al inicio" class="d-lg-none"/>
            </div>
        </div>
    </div>
</template>

<script setup>
import Navbar from '../../components/Navs/Navbar.vue'
import Volver_Inicio from '../../components/Volver/Volver_Inicio.vue'
import BTNPrimario from "../../components/Botones/Primario.vue"
import VolverPagina from "../../components/Volver/VolverPagina.vue"
import { useRoute, useRouter } from 'vue-router'
import { ref, onMounted, onBeforeMount } from 'vue'
import axios from 'axios'
import NavbarBuscador from '../../components/Navs/NavbarBuscador.vue'
import { useUsersStore } from "../../stores/user"
import { useCartStore } from "../../stores/cart"
import { storeToRefs } from "pinia"

const user = useUsersStore()
const {  } = storeToRefs(user)
const { url, usuarioData } = user;

const carts = useCartStore()
const { cart, totalPrice, totalDiscount } = storeToRefs(carts)
const {  } = carts

const urlPaymentResult = '/api/payments/orders/status' // external_reference

const router = useRouter()
const route = useRoute()
console.log(route.query)

const payment = ref({})

const status = ref(null)

const fetchPayment = async() => {
    const idData = JSON.parse(localStorage.getItem('idData'))
    const token = JSON.parse(localStorage.getItem('token'))
    const headers = ref({'Authorization': `Bearer ${token}`})
    let params = null
    if ( route.query ) {
        if ( route.query.token && route.query.PayerID ) {
            params = {
                payment_gateway: "PAYPAL",
                data: {
                    token: route.query.token,
                    PayerID: route.query.PayerID
                }
            }
        }
        else {
            params = {
                payment_gateway: "STRIPE",
                data: {
                    order_id: route.query.order_id,
                    payment_intent: route.query.payment_intent
                }
            }
        }
    }
    try {
        await axios.post(url+urlPaymentResult, params, {headers: headers.value}).then((result) => {
            console.log(result)
            payment.value = result.data.data
            status.value = result.data.data.status
        })
    } catch (error) {
        console.log(error)
    }
}

onBeforeMount(async() => {
    await fetchPayment()
    usuarioData()
})

let insertedNodes = []

const useHeadTags = async (settings = null) => {
    const url = process.env.VUE_APP_BASE_URL

    try {
        let response = await axios.post(`${url}/api/public/seo/pages`, { slug: settings.slug });

        const tagsString = await response.data.data

        const parser = new DOMParser()
        const doc = parser.parseFromString(
        `<html><head>${tagsString}</head><body></body></html>`,
        'text/html'
        )

        const headNodes = Array.from(doc.head.childNodes)

        headNodes.forEach(node => {
        const nodeName = node.nodeName.toLowerCase()

        switch (nodeName) {
            case 'title':
            document.title = node.textContent ?? ''
            break

            case 'meta': {
            const metaName = node.getAttribute('name')
            if (metaName) {
                const existingMeta = document.head.querySelector(`meta[name="${metaName}"]`)
                if (existingMeta) {
                document.head.removeChild(existingMeta)
                }
            }
            const meta = document.createElement('meta')
            Array.from(node.attributes).forEach(attr => {
                meta.setAttribute(attr.name, attr.value)
            })
            document.head.appendChild(meta)
            insertedNodes.push(meta)
            break
            }

            case 'script': {
            const script = document.createElement('script')
            Array.from(node.attributes).forEach(attr => {
                script.setAttribute(attr.name, attr.value)
            })
            script.textContent = node.textContent
            document.head.appendChild(script)
            insertedNodes.push(script)
            break
            }

            case 'link': {
            const link = document.createElement('link')
            Array.from(node.attributes).forEach(attr => {
                link.setAttribute(attr.name, attr.value)
            })
            document.head.appendChild(link)
            insertedNodes.push(link)
            break
            }

            default:
            console.warn(`Etiqueta <${nodeName}> no manejada.`)
        }
        })
        console.warn('TAGS CARGADOS')
    } catch (error) {
        console.error('Error procesando las etiquetas:', error)
    }
}

router.beforeEach(() => {
    insertedNodes.forEach(node => {
    if (document.head.contains(node)) {
        document.head.removeChild(node)
    }
    })
    insertedNodes = []
})

onMounted(() => {
    useHeadTags({ slug: route.fullPath.slice(1) })
})

</script>

<style lang="scss" scoped>
.max__width__login__Desk{
    @media (min-width: 992px) {
        max-width: 30%;
    }
    @media (min-width: 1200px) {
        max-width: 51%;
    }
}
.margin-top{
    margin-top: 5vh;
}
.titulo{
    h2{
        font-weight: 700;
        font-size: 32px;
        color: var(--colorSecondary);
        display: flex;
        justify-content: center;
        align-items: center;
        @media (max-width: 767px) {
            font-size: 22px;
        }
    }
    span{
        font-size: 32px;
        font-weight: 700;
        color: var(--colorPrimary);
        padding-left: 5px;
        @media (max-width: 767px) {
            font-size: 22px;
        }
    }
}
.detalles{
    p{
        font-size: 14px;
        font-weight: 400;
        color: #7D93B2;
        padding: 3vh 0;
        @media (min-width: 400px) {
            font-size: 16px;
        }
    }
    h3{
        font-weight: 500;
        font-size: 16px;
        text-align: start;
        color: var(--colorSecondary);
        text-align: left;
    }
    h5{
        font-weight: 700;
        font-size: 16px;
        color: var(--colorSecondary);
    }
    h6{
        font-size: 22px;
        font-weight: 700;
        color: var(--colorSecondary);
    }
    .metodo{
        font-weight: 400;
        font-size: 16px;
        color: #7D93B2;
        padding: 0;
    }
    .text-right{
        text-align: right !important;
    }
}
.recuerda{
    font-weight: 500;
    font-size: 14px;
    color: var(--colorSecondary);
    padding: 20px 0;
}
.title-fix{
    max-width: 75%;
    text-wrap: wrap;
}
.max-width{
    @media (max-width: 768px) {
        width: 389px !important;
    }
    @media (min-width: 768px) {
        width: 515px !important;
    }
}

.contenedor{
    @media (min-width: 992px) {
        margin-top: 150px;
    }
}


</style>