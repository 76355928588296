<template>
    <div class="container-fluid">
        <NavbarBuscador/>
        <Vacio v-if="step == 1"/>
        <Home v-if="step == 2"/>
        <Checkout v-if="step == 3"/>
        <Pago v-if="step == 4"/>

        <div class="espacio2"></div>
        <!-- <Footer :activeCarrito="true" class="d-lg-none"/> -->
        <FooterHome/>
    </div>
</template>

<script setup>
    import Home from '../Carrito/Home.vue'
    import Vacio from '../Carrito/Vacio.vue'
    import Checkout from '../Carrito/Checkout.vue'
    import Pago from '../Carrito/Pago.vue'
    import NavbarBack from "../../components/Navs/Navbar.vue";
    import NavbarBuscador from "../../components/Navs/NavbarBuscador.vue";
    import Footer from "../../components/Footer/Footer.vue";
    import FooterHome from "../../components/Footer/FooterHome.vue";
    import { ref, inject, provide, onMounted, watch, nextTick, onUnmounted } from 'vue';
    import axios from 'axios';
    import { useUsersStore } from "../../stores/user";
    import { useCartStore } from "../../stores/cart";
    import { useConfigStore } from "../../stores/config"
    import { storeToRefs } from "pinia";
    import { useRoute } from 'vue-router';
    import { useRouter } from 'vue-router';


    const route = useRoute();
    const router = useRouter();
    const users = useUsersStore();
    const { cambioHeader, idUser } = storeToRefs(users);
    const { url, validate } = users;

    const carts = useCartStore();
    const { cart, arrayCheckout } = storeToRefs(carts);
    const {  } = carts;

    const config = useConfigStore();
    const { coinSelected } = storeToRefs(config);

    const urlBuyProd = '/api/generate/payment/link'

    const step = ref(route.params.step)
    let payment = ref()
    let array = [
        {
            titulo: 'Titulo del producto',
            precioPromo: 30,
            precio: 50
        },
        {
            titulo: 'Titulo del producto 2',
            precioPromo: 40,
            precio: 70
        }
    ]

    const user = ref({
        email: '',
        first_name: '',
        last_name: '',
        birthdate: '',
        country_code: '',
        phone_number: '',
        gender: '',
        country_id: null
    })

    provide('user', user)

    provide('payment', payment)
    provide('step', step)
    provide('array', array)

    watch( coinSelected, async () => {
        nextTick(() => {
            if (localStorage.getItem('Cart')){
                cart.value = JSON.parse(localStorage.getItem('Cart'))
                console.log(cart.value)
            }
        })
    }, {deep: true})

    let insertedNodes = []

    const useHeadTags = async (settings = null) => {
        const url = process.env.VUE_APP_BASE_URL

        try {
            let response = await axios.post(`${url}/api/public/seo/pages`, { slug: settings.slug });

            const tagsString = await response.data.data

            const parser = new DOMParser()
            const doc = parser.parseFromString(
            `<html><head>${tagsString}</head><body></body></html>`,
            'text/html'
            )

            const headNodes = Array.from(doc.head.childNodes)

            headNodes.forEach(node => {
            const nodeName = node.nodeName.toLowerCase()

            switch (nodeName) {
                case 'title':
                document.title = node.textContent ?? ''
                break

                case 'meta': {
                const metaName = node.getAttribute('name')
                if (metaName) {
                    const existingMeta = document.head.querySelector(`meta[name="${metaName}"]`)
                    if (existingMeta) {
                    document.head.removeChild(existingMeta)
                    }
                }
                const meta = document.createElement('meta')
                Array.from(node.attributes).forEach(attr => {
                    meta.setAttribute(attr.name, attr.value)
                })
                document.head.appendChild(meta)
                insertedNodes.push(meta)
                break
                }

                case 'script': {
                const script = document.createElement('script')
                Array.from(node.attributes).forEach(attr => {
                    script.setAttribute(attr.name, attr.value)
                })
                script.textContent = node.textContent
                document.head.appendChild(script)
                insertedNodes.push(script)
                break
                }

                case 'link': {
                const link = document.createElement('link')
                Array.from(node.attributes).forEach(attr => {
                    link.setAttribute(attr.name, attr.value)
                })
                document.head.appendChild(link)
                insertedNodes.push(link)
                break
                }

                default:
                console.warn(`Etiqueta <${nodeName}> no manejada.`)
            }
            })
            console.warn('TAGS CARGADOS')
        } catch (error) {
            console.error('Error procesando las etiquetas:', error)
        }
    }

    onMounted(() => {
        if (localStorage.getItem('Cart')){
            cart.value = JSON.parse(localStorage.getItem('Cart'))
            console.log(cart.value)
        }

        useHeadTags({ slug: route.fullPath.slice(1) })
    })

    router.beforeEach((to, from, next) => {
        if (localStorage.getItem('Cart') && to.name != 'Carrito'){
            cart.value = JSON.parse(localStorage.getItem('Cart'))
            if (cart.value.some((item) => item.price.payment_type == 'partial')){
                cart.value = []
                localStorage.removeItem('Cart')
            }
            console.log(cart.value)
        }

        insertedNodes.forEach(node => {
        if (document.head.contains(node)) {
            document.head.removeChild(node)
        }
        })
        insertedNodes = []
        
        next();
    });


</script>

<style lang="scss" scoped>

p{
    font-weight: 500;
    font-size: 16px;
    color: #697891;
    padding: 10px 0px;
    margin-bottom: 30px;
}
.btn{
    max-width: 90%;
}

@media (min-width: 992px) {
    .box-shadow{
        box-shadow: 0px 3px 10px #00000029;
        border-radius: 20px;
        width: 100%;
        padding: 20px;
        a{
            font-weight: 400;
            font-size: 16px;
            color: #0C5FD4;
        }
        p{
            color: #697891;
            font-weight: 400;
            font-size: 16px;
            text-align: left;
        }
    }
}
@media (min-width: 1024px){
    .max-width{
        max-width: 33%;
    }
}
@media (min-width: 1440px){
    .max-width{
        max-width: 34%;
    }
}
</style>