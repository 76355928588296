<template>
    <div class="container-fluid justify-content-center">
        <NavbarPlus/>
        <HeaderTituloPlus class="d-none d-lg-flex" primerTitulo="Membresias" segundoTitulo="Checkout"/>
        <div class="row justify-content-center ">
            <div class="col-11 pt-2 d-lg-none">
                <Volver_Inicio/>
            </div>
            <div class="col-11 d-flex justify-content-center align-items-center px-0">
                <div class="row justify-content-center justify-content-lg-between row__width">
                    <div class="col-12 col-lg d-flex flex-column justify-content-center align-items-center  position-relative ms-lg-4">
                        <div class="row justify-content-center ">
                            <div class="col-12 col-lg-12 d-flex flex-column justify-content-center align-items-center px-0 mt-2">
                                <TituloCarrito titulo="¿Cómo quieres pagar?" :oculto='true' :sinBefore="true" class="py-4 py-lg-0"/>
                                <InputsCheckout v-if="step == 1"/>
                                <MetodosDePago v-if="step == 2"/>
                                <FooterCarritoPlus class="d-none d-lg-flex"/>
                            </div>
                            <div class="col-12 d-flex flex-column justify-content-center align-items-center px-0 px-lg-2">
                                <DetallesCarritoPlus class=" mt-4 d-lg-none"/>
                            </div>
                            <div class="col-11 col-lg-12 d-flex flex-column justify-content-center align-items-center px-0">
                                <FooterCarritoPlus class="mt-4 d-lg-none"/>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg ms-4 d-none d-lg-flex justify-content-center align-items-start espacio px-0 max-widthLG">
                        <DetallesCarritoPlus class=""/>
                    </div>
                </div>
            </div>
        </div>
        <div class="espacio"></div>
        <FooterPlus :activeCarrito="true" class="d-lg-none"/>
        <FooterHomeV2 class="d-lg-none"/>
        <div class="espacio"></div>
    </div>
</template>

<script setup>
import DetallesCarritoPlus from "../../components/Carrito/DetallesPlus.vue";
import FooterPlus from "../../components/Footer/FooterPlus.vue";
import FooterHomeV2 from "../../components/Footer/FooterHomeV2.vue";
import TituloCarrito from "../../components/Titulo/TituloCarrito.vue";
import InputsCheckout from "../../components/Inputs/Checkout.vue"
import MetodosDePago from "../../components/Carrito/MetodosDePago.vue";
import NavbarPlus from "../../components/Navs/NavbarPlus.vue";
import Volver_Inicio from "../../components/Volver/Volver_Inicio.vue";
import FooterCarritoPlus from "../../components/Footer/FooterCarritoPlus.vue";
import HeaderTituloPlus from "../../components/Titulo/HeaderTituloPlus.vue";
import { ref, inject, provide, onMounted } from 'vue';
import axios from 'axios';
import { storeToRefs } from "pinia"
import { useUsersStore } from "../../stores/user";
import { useCartStore } from "../../stores/cart"
import { useConfigStore } from "../../stores/config";
import { useRoute, useRouter } from 'vue-router';

const route = useRoute()
const router = useRouter()

const users = useUsersStore();
const {  } = storeToRefs(users);
const { url } = users;

const carts = useCartStore();
const { cart } = storeToRefs(carts);
const {  } = carts

const config = useConfigStore();
const { coinSelected} = storeToRefs(config);

const urlBuy = '/api/payments/subscription'

let step = ref(1)

const user = ref({
    email: '',
    first_name: '',
    last_name: '',
    birthdate: '',
    country_code: '',
    phone_number: '',
    gender: '',
    country_id: null
})

provide('user', user)

let selectedMethod = ref()
let payment_intention = ref(false)
let payment_data = ref()
let stripe = ref(null)
let card_element = ref(null)

provide('selectedMethod', selectedMethod)
provide('payment_intention', payment_intention)
provide('payment_data', payment_data)
provide('stripe', stripe)
provide('card_element', card_element)

const payment_method = {
    1: "STRIPE",
    2: "PAYPAL"
}

const pay = async(method) => {

    const idData = JSON.parse(localStorage.getItem('idData'))
    const token = JSON.parse(localStorage.getItem('token'))
    const headers = ref({'Authorization': `Bearer ${token}`})

    let params = null
    let stripe_payment = null

    // Stripe
    if (method == 1) {
        try {
            const payment_intent = await stripe.value.paymentIntents.create({
                amount: 999,
                currency: 'USD',
                automatic_payment_methods: {
                    enabled: true,
                },
            })
            console.log(payment_intent)
            stripe_payment = payment_intent
        } catch (error) {
            console.log('Error creando PaymentIntent:', error);
        }

        params = {
            payment_gateway: payment_method[method],
            data: {
                token: stripe_payment.id
            },
            amount: 999,
            currency: 'USD'
        }

        idData?.id ? params.user_id = idData.id : params.user = user.value
        console.log(params)
    }
    // PayPal
    else if (method == 2) {
        params = {
            payment_gateway: payment_method[method],
            amount: 9.99,
            currency: 'USD'
        }

        idData?.id ? params.user_id = idData.id : params.user = user.value
    }

    if (method == 1) { params.data = { token: "tok_visa" } }

    try {
        await axios.post(url+urlBuy, params, {headers: headers.value}).then(async (result) => {
            console.log(result)
            // Stripe
            if (method == 1) {
                payment_intention.value = true
                payment_data.value = result.data.data

                const clientSecret = result.data.data.clientSecret

                console.log(clientSecret)

                console.log(stripe.value)

                const response = await stripe.value.confirmCardPayment(clientSecret, {
                    payment_method: {
                        card: card_element.value,
                    },
                });

                console.log(response)

                if (response.error) {
                    console.log(response.error.message)
                } else if (response.paymentIntent.status === 'succeeded') {
                    router.push({ name: 'Pedido', query: { order_id: response.paymentIntent.id } })
                }
            }
            // PayPal
            else if (method == 2) {
                payment_data.value = result.data.data
                const payerActionUrl = result.data.data.links.find(link => link.rel === 'approve').href
                window.location.href = payerActionUrl
            }
        })
    } catch (error) {
        console.log(error)
    }
}

provide('pay', pay)

let insertedNodes = []

const useHeadTags = async (settings = null) => {
    const url = process.env.VUE_APP_BASE_URL

    try {
        let response = await axios.post(`${url}/api/public/seo/pages`, { slug: settings.slug });

        const tagsString = await response.data.data

        const parser = new DOMParser()
        const doc = parser.parseFromString(
        `<html><head>${tagsString}</head><body></body></html>`,
        'text/html'
        )

        const headNodes = Array.from(doc.head.childNodes)

        headNodes.forEach(node => {
        const nodeName = node.nodeName.toLowerCase()

        switch (nodeName) {
            case 'title':
            document.title = node.textContent ?? ''
            break

            case 'meta': {
            const metaName = node.getAttribute('name')
            if (metaName) {
                const existingMeta = document.head.querySelector(`meta[name="${metaName}"]`)
                if (existingMeta) {
                document.head.removeChild(existingMeta)
                }
            }
            const meta = document.createElement('meta')
            Array.from(node.attributes).forEach(attr => {
                meta.setAttribute(attr.name, attr.value)
            })
            document.head.appendChild(meta)
            insertedNodes.push(meta)
            break
            }

            case 'script': {
            const script = document.createElement('script')
            Array.from(node.attributes).forEach(attr => {
                script.setAttribute(attr.name, attr.value)
            })
            script.textContent = node.textContent
            document.head.appendChild(script)
            insertedNodes.push(script)
            break
            }

            case 'link': {
            const link = document.createElement('link')
            Array.from(node.attributes).forEach(attr => {
                link.setAttribute(attr.name, attr.value)
            })
            document.head.appendChild(link)
            insertedNodes.push(link)
            break
            }

            default:
            console.warn(`Etiqueta <${nodeName}> no manejada.`)
        }
        })
        console.warn('TAGS CARGADOS')
    } catch (error) {
        console.error('Error procesando las etiquetas:', error)
    }
}

router.beforeEach(() => {
    insertedNodes.forEach(node => {
    if (document.head.contains(node)) {
        document.head.removeChild(node)
    }
    })
    insertedNodes = []
})

onMounted(() => {
    let idData = JSON.parse(localStorage.getItem('idData'))
    idData ? step.value = 2 : 1
    useHeadTags({ slug: route.fullPath.slice(1) })
})

</script>

<style lang="scss" scoped>

p{
    font-weight: 500;
    font-size: 16px;
    color: #697891;
    padding: 10px 0px;
    margin-bottom: 30px;
}
.espacio{
    padding: 6rem 0 0 0;
}
.max-widthLG{
    @media (min-width: 992px) {
        max-width: 32vw;
    }
}
</style>