<template>
    <!-- <div class="col-12 col-lg-5 d-flex justify-content-between align-items-center buttons px-0">
        <div class="contenedor__buscar">
            <input type="text" placeholder="Buscar en mis productos">
            <button ><img src="../../img/home-icons/lupa.svg" alt=""></button>
        </div>
    </div> -->
    <div class="col-12 col-lg-5 d-flex justify-content-center align-items-center buttons px-0">
        <div class="contenedor__buscar">
            <input 
                type="text" 
                placeholder="Buscar en mis productos" 
                @input="handleSearch(search_query)" 
                @blur="hideSearchBox" 
                v-model="search_query">
            <button type="button" @click="handleSearch(search_query)">
                <img src="../../img/home-icons/lupa.svg" alt="">
            </button>
        </div>
    </div>
</template>

<script setup>
import { storeToRefs } from "pinia"
import { useUsersStore } from "../../stores/user"
import { ref, provide, inject, watch } from 'vue';
import axios from 'axios';

const users = useUsersStore();
const {headers } = storeToRefs(users);
const { url } = users;

let search_query = inject('search_query')
let search_box = inject('search_box')
let is_searching = inject('is_searching')
let search_results_list = inject('search_results_list')

let products_list = inject('products_list')
let products = inject('products')
let fetchProducts = inject('fetchProducts')

const showSearchBox = () => {
    search_box.value = true
}

const hideSearchBox = () => {
    search_box.value = false
}

const keepVisible = (event) => {
    event.preventDefault()
}

watch(search_query, (newValue) => {
    if (newValue === '') {
        is_searching.value = true
    } else {
        is_searching.value = false
    }
})


// Función para manejar la búsqueda
const handleSearch = async (query) => {
    console.log(search_query.value)
    if (!search_query.value) {
        fetchProducts()
        is_searching.value = false
    } else {
        await getSearchResults(search_query.value);
    }
};

// Función para obtener resultados de búsqueda
const getSearchResults = async (query) => {
    const token = JSON.parse(localStorage.getItem('token'))
    const headers = ref({ 'Authorization': `Bearer ${token}` })
    console.log(headers.value)
    if (!is_searching.value) {
        is_searching.value = true;
        console.log(query)
        const body = {
            name: query,
        };
        try {
            const response = await axios.post(
                `${url}/api/admin/products/search`, body, { headers: headers.value } 
            );
            console.log(response)
            products.value = response.data.data; 
            products_list.value.links = null 
            if(response.data.data.links){
                products_list.value.links = response.data.data.links
            }else{
                products_list.value.links = null
            }
            search_results_list.value = response.data.data.slice(0, 3); 
        } catch (error) {
            console.error(error);
        } finally {
            is_searching.value = false;
        }
    }
};
</script>

<style lang="scss" scoped>
.buttons{
    button{
        border-radius: 50%;
        border: 0;
        box-shadow: 0px 3px 6px #00000029;
        height: 45px;
        width: 45px;
    }
    .buscar{
        background-color: var(--colorPrimary);
    }
    .filtros{
        background-color: #F5F5F5;
    }
}
.contenedor__buscar{
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 33px;
    background-color: var(--bgPrincipal);
    height: 55px;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 15px;
    @media (min-width: 992px) {
        border-radius: 50px;
        padding: 22px 15px;
        margin-left: 15px;
        height: 50px;
    }
    .img{
        padding: 0 20px;
    }
    h3{
        color: var(--colorSecondary);
        font-size: 18px;
        font-weight: 600;
        padding: 5px 0px;
        @media (min-width: 992px) {
            font-weight: 500;
            padding-top: 0;
            padding-bottom: 5px;
        }
    }
    input{
        border: 0;
        color: #B8C1DB;
        font-weight: 400;
        font-size: 16px;
        width: 100%;
        &::placeholder{
            color: #B8C1DB;
        }
        &:focus{
            outline: 0;
        }
        @media (min-width: 992px) {
            color: #697891;
            font-size: 14px;
            width: 80%;
        }
    }
    button{
        position: absolute;
        right: 7px;
        border: 0;
        height: 45px;
        width: 45px;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 50%;
        background-color: var(--colorPrimary);
        display: flex;
        justify-content: center;
        align-items: center;
        img{
            padding: 0;
            height: 22px;
            margin-bottom: 2px;
        }
        @media (min-width: 992px) {
            right: 5px;
        }
    }
    .border-left{
        border-left: 1px solid rgba(184, 193, 219, 0.4);
    }
    
}
</style>