<template>
    <div class="row justify-content-center row__width contenedor">
        <div class="col-12 d-flex flex-column justify-content-center align-items-center px-0 ">
            <div class="row justify-content-center row__width padding h-100">
                <!-- <AtrasAdmin/> -->
                <div class="col-12 col-lg-7 px-0 d-flex flex-column justify-content-center align-items-center mb-4">
                    <TituloPanelAdmin :title="$t('message.manageUsers')" :subtitle="$t('message.seeAndManageUsers')"/>
                </div>
                <div class="col-12 col-lg-4 px-0 px-lg-auto d-flex justify-content-start justify-content-lg-end align-items-center">
                    <button type="button" class="boton-nuevo-usuario" @click="page_id = 2">
                        <span>
                            <img src="../../../img/panel-icons/mas.svg" alt="">
                        </span>
                        {{ $t('message.addNewUser') }}
                    </button>
                </div>
            </div>
            <div class="row justify-content-center row__width px-lg-5 border-top pt-3">
                <div class="col-12 d-flex justify-content-center align-items-center div px-3 pb-2">
                    <div class="row justify-content-center row__width " >
                        <div class="col-12 d-flex justify-content-center align-items-center filtrosMobile px-0">
                            <!-- <div class="row justify-content-start align-items-center row__width">
                                <div class="col-12 d-flex flex-column justify-content-start align-items-start">
                                    <h5>{{ $t('message.users') }}</h5>
                                    <h6 v-if="users_data">{{ users_data.total }} {{ $t('message.actives') }}</h6>
                                </div>
                                <div class="col-12 col-md-9 d-flex justify-content-center align-items-center contenedor px-0">
                                    <input type="text" :placeholder="$t('message.placeholderSearch')" v-model="search_query">
                                    <button type="button" @click="'' /* getSearchResults($event.target.value) */"><img src="../../../img/home-icons/lupa.svg" alt=""></button>
                                </div>
                                <div class="col-12 col-md-3 d-flex justify-content-start justify-content-lg-center align-items-center search-select ps-0 ps-lg-3 pe-0 my-3">
                                    <MazSelect
                                        class="row__width"
                                        :placeholder="$t('message.categories')"
                                        color="info"
                                        :options="options"
                                        name="role"
                                        search
                                        search-placeholder="Buscar en la lista"
                                    />
                                </div>
                            </div> -->
                            <div class="row justify-content-start align-items-center row__width" style="margin: auto;">
                                <!-- Buscador -->
                                <div class="col-12 col-lg d-flex justify-content-center align-items-center contenedor px-0">
                                    <input type="text" 
                                        :placeholder="$t('message.placeholderSearchUsers')" 
                                        @input="handleSearch(search_query)" 
                                        @blur="hideSearchBox" 
                                        v-model="search_query">
                                    <button type="button" @click="router.push({name: 'Profesionales', params: {search: search_query}})">
                                        <img src="../../../img/home-icons/lupa.svg" alt="">
                                    </button>
                                </div>
                                <!-- <div class="search__box" v-show="search_box" @mousedown="keepVisible" id="div-busqueda">
                                    <div class="row justify-content-center row__width focus">
                                        <div class="col-12 d-flex justify-content-between align-items-center">
                                            <h4>Profesionales</h4>
                                            <router-link :to="`/resultados-profesores`"><h5 class="see-more">{{ $t('message.seeMore') }}</h5></router-link>
                                        </div>
                                        <div v-if="is_searching">
                                            <div class="col-12 d-flex justify-content-start align-items-center pb-4">
                                                <div class="skeleton-avatar"></div>
                                                <div class="skeleton-text"></div>
                                            </div>
                                            <div class="col-12 d-flex justify-content-start align-items-center pb-4">
                                                <div class="skeleton-avatar"></div>
                                                <div class="skeleton-text"></div>
                                            </div>
                                            <div class="col-12 d-flex justify-content-start align-items-center pb-4">
                                                <div class="skeleton-avatar"></div>
                                                <div class="skeleton-text"></div>
                                            </div>
                                        </div>
                                        <div class="col-12 d-flex justify-content-start align-items-center pb-4" v-else v-for="(prof, id) in search_results_list" v-if="search_results_list.length >= 1">
                                            <img :src="prof.avatar" alt="avatar" class="ejemplo" v-if="prof && prof.avatar">
                                            <router-link :to="{name: 'Profesional', params: {slug: prof.slug}}" v-if="prof && prof.slug"><h5 v-if="prof && prof.name">{{ prof.name }}</h5></router-link>
                                        </div>
                                    </div>
                                </div> -->
                                <div class="col col-sm-4 col-lg-4 d-flex justify-content-center align-items-center search-select ps-lg-3 pe-lg-0 my-3">
                                    <MazSelect
                                        class="row__width"
                                        :placeholder="$t('message.categories')"
                                        color="info"
                                        :options="professions_list"
                                        name="role"
                                        search
                                        search-placeholder="Buscar en la lista"
                                        @update:model-value="router.push({name: 'Profesionales', params: {filters: 'profession'}})"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="col-12 d-flex flex-column justify-content-center align-items-center contenido px-0">

                            <div class="row row__width" v-if="is_searching || !users_data">
                                <div class="col-12 d-flex justify-content-start align-items-center pt-4">
                                    <div class="skeleton-avatar"></div>
                                    <div class="skeleton-text"></div>
                                </div>
                                <div class="col-12 d-flex justify-content-start align-items-center pt-4">
                                    <div class="skeleton-avatar"></div>
                                    <div class="skeleton-text"></div>
                                </div>
                                <div class="col-12 d-flex justify-content-start align-items-center pt-4">
                                    <div class="skeleton-avatar"></div>
                                    <div class="skeleton-text"></div>
                                </div>
                            </div>
                            <div class="row justify-content-center row__width scroll my-3" v-if="users_data && !is_searching">
                                <!-- <Usuarios v-for="(user, index) in users_data.data" :user="user" :index="index" :key="user.id" v-if="users_data"/> -->
                                <template v-for="(user, index) in users_data.data">
                                    <Usuarios :user="user" :index="index" :key="user.id" v-if="user"/>
                                </template>

                                
                            </div>
                            <!-- Paginación -->
                            <template v-if="users_data">
                                <nav v-if="users_data.links" class="py-2">
                                    <ul class="pagination">
                                    <!-- Previous link -->
                                        <!-- <li v-if="users_data.links[0]" :class="{'disabled': !users_data.prev_page_url}" class="page-item buttons">
                                            <a class="page-link" href="#" v-html="users_data.links[0].label" @click.prevent="fetchUsers(users_data.prev_page_url)" :disabled="!users_data.prev_page_url"></a>
                                        </li> -->

                                        <!-- Display first 5 page links after Previous -->
                                        <li v-for="(link, index) in users_data.links" :key="link.label" :class="{'active': link.active}" class="page-item">
                                            <a v-if="link.url" class="page-link" href="#" v-html="link.label" @click.prevent="fetchUsers(link.url)"></a>
                                            <span v-else class="page-link" v-html="link.label"></span>
                                        </li>

                                    <!-- Next link -->
                                        <!-- <li v-if="users_data.links[users_data.links.length - 1]" :class="{'disabled': !users_data.next_page_url}" class="page-item buttons">
                                            <a class="page-link" href="#" v-html="users_data.links[users_data.links.length - 1].label" @click.prevent="fetchUsers(users_data.next_page_url)" :disabled="!users_data.next_page_url"></a>
                                        </li> -->
                                    </ul>
                                </nav>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import MazSelect from 'maz-ui/components/MazSelect'
import Usuarios from "../../../components/Panel/Admin/Usuarios/Usuarios.vue";
import TituloPanelAdmin from "../../../components/Titulo/TituloPanelAdmin.vue";
import { storeToRefs } from "pinia"
import { useUsersStore } from "../../../stores/user"
import { onBeforeMount, ref, provide, inject, watch } from 'vue';
import { useRouter } from 'vue-router';
import axios from 'axios';

const router = useRouter()

const users = useUsersStore();
const { access, headers } = storeToRefs(users);
const { validate, url } = users;

let page_id = inject('page_id')

const fetchUsers = inject('fetchUsers')

const users_data = inject('users_data')

// Buscador
const original_users_data = ref([]);
const search_query = ref('');
const search_box = ref(false);
const is_searching = ref(false);
const search_results_list = ref([]);
const professions_list = ref([])



const showSearchBox = () => {
    search_box.value = true
}

const hideSearchBox = () => {
    search_box.value = false
}

const keepVisible = (event) => {
    event.preventDefault()
}

watch(search_query, (newValue) => {
    if (newValue === '') {
        is_searching.value = true
    } else {
        is_searching.value = false
    }
})


// Función para manejar la búsqueda
const handleSearch = async (query) => {
    console.log(search_query.value)
    if (!search_query.value) {
        // Si el campo de búsqueda está vacío, restaurar la lista original
        
        fetchUsers()
        is_searching.value = false
    } else {
        await getSearchResults(search_query.value);
    }
};

// Función para obtener resultados de búsqueda
const getSearchResults = async (query) => {
    if (!is_searching.value) {
        is_searching.value = true;
        // console.log(query)
        try {
            const response = await axios.get(`${url}/api/admin/users/search?value=${query}`, { headers: headers.value });
            console.log(response.data)
            users_data.value.data = response.data.data;  
            if(response.data.links){
                users_data.value.links = response.data.links
            }else{
                users_data.value.links = null
            }
            search_results_list.value = response.data.data.slice(0, 3); 
        } catch (error) {
            console.error(error);
        } finally {
            is_searching.value = false;
        }
    }
};

</script>

<style lang="scss" scoped>
.contenedor{
    box-shadow: 0px 3px 10px #00000029;
    border: 1px solid #ECECEC;
    background-color: var(--bgPrincipal);
    border-radius: 40px;
    min-height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 15px;
    padding-bottom: 85px;
    @media (min-width: 992px) {
        border-radius: 50px;
        padding: 22px 15px;
    }
    @media (min-width: 992px) {
        background-color: #fff;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 20px;
        padding: 40px 0 30px 0;
        min-height: auto;
    } 
    @media (max-width: 992px) {
        max-width: 95vw;
    }
    .boton-nuevo-usuario{
        box-shadow: 0 3px 6px #00000016;
        border-radius: 43px;
        height: 60px;
        margin-bottom: 30px;
        border: 0;
        background-color: var(--bgPrincipal);
        font-weight: 600;
        font-size: 16px;
        color: var(--colorSecondary);
        padding-bottom: 5px;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        padding: 0 15px;
        width: 100%;
        max-width: 300px;
        span{
            border: 2px solid #E4E4E4;
            border-radius: 50%;
            height: 40px;
            width: 40px;
            min-width: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    .filtrosMobile{
        h5{
            color: var(--colorSecondary);
            font-weight: 700;
            font-size: 22px;
            text-align: left;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        h6{
            color: #697891;
            font-weight: 500;
            font-size: 16px;
            text-align: left;
            padding-top: 10px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 100%;
        }
        .contenedor{
            box-shadow: 0px 3px 6px #00000029;
            border: 1px solid #ECECEC;
            background-color: var(--bgPrincipal);
            border-radius: 40px;
            min-height: 0px;
            height: 60px;
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            padding: 15px;
            
            @media (min-width: 992px) {
                border-radius: 40px;
            }
            @media (max-width: 991px) {
                margin: 10px 0;
            }
            input{
                border: 0;
                color: #B8C1DB;
                font-weight: 400;
                font-size: 14px;
                width: 100%;
                padding-left: 15px;
                &::placeholder{
                    color: #B8C1DB;
                }
                &:focus{
                    outline: 0;
                }
                
                @media (min-width: 992px) {
                    color: #697891;
                    font-size: 16px;
                    width: 93%;
                }
            }
            button{
                position: absolute;
                right: 7px;
                border: 0;
                min-height: 45px;
                width: 45px;
                box-shadow: 0px 3px 6px #00000029;
                border-radius: 50%;
                background-color: var(--colorPrimary);
                justify-content: center;
                align-items: center;
                img{
                    padding: 0;
                    max-height: 22px;
                }
                @media (min-width: 992px) {
                    right: 10px;
                }
            }
        }
    }
}

.scroll {
    overflow-y: scroll;
}

.contenido {
    max-height: 60dvh;
}

.search-select{
    max-width: 200px;
}

.skeleton-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #e0e0e0;
    margin-right: 10px;
}

.skeleton-text {
    width: 100%;
    height: 50px;
    background-color: #e0e0e0;
    border-radius: 4px;
}

.skeleton-avatar,
.skeleton-text {
    animation: pulse 1.5s infinite;
}

@keyframes pulse {
    0% {
        background-color: #e0e0e0;
    }
    50% {
        background-color: #f0f0f0;
    }
    100% {
        background-color: #e0e0e0;
    }
}

.search__box{
    position: absolute;
    box-shadow: 0px 3px 10px #00000029;
    border-radius: 30px;
    background-color: var(--bgPrincipal);
    height: auto;
    width: 90vw;
    max-width: 460px;
    z-index: 99;
    padding-top: 20px;
    padding-left: 30px;
    margin-top: 320px;
    justify-content: center;
    align-items: flex-start;
    transition: all ease 1s;
    top
    img{
        border-radius: 50%;
        height: 40px;
        width: 40px;
        object-fit: cover;
    }
    a{
        overflow: hidden;
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 100%;
    }
    .see-more{
        color: var(--colorPrimary);
    }
    h4{
        color: var(--colorSecondary);
        font-size: 24px;
        font-weight: 700;
        padding-left: 5px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        @media (min-width: 992px) {
            font-weight: 500;
            padding-top: 10px;
            padding-bottom: 10px;
        }
    }
    h5{
        font-weight: 500;
        font-size: 16px;
        color: #111316;
        padding-left: 10px;
        text-align: left;
        overflow: hidden;
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 100%;
    }
    h6{
        font-weight: 500;
        font-size: 14px;
        color: #697891;
        padding-left: 5px;
        text-align: left;
        overflow: hidden;
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 100%;
    }
}
.pagination{
    .page-item{
        border: 0;
        border-radius: 25px;
        margin: 0 2px;
        .page-link{
            border-radius: 25px;
            border: 0;
            font-weight: 500;
            color: var(--colorSecondary);
            font-family: 'Poppins';
            a{
                &:hover{
                    background-color: var(--colorPrimary);
                    color: var(--colorSecondary);
                }
            }
            &:focus{
                box-shadow: none;
            }
        }
    }
    .buttons{
        border-radius: 15px;
        .page-link{
            border-radius: 15px;
        }
    }
    
    .active{
        background-color: var(--colorPrimary);
        .page-link{
            color: #fff !important;
        }
        a{
            color: #fff !important;
        }
    }

}
</style>