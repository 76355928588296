<template>
    <div class="container-fluid">
        <NavbarBack class="" v-if="cambioHeader"/>
        <NavbarBuscador v-else/>
        <HeaderTitulo class="d-none d-lg-flex" tituloHeader="Home / Perfil"/>
        <div class="row justify-content-center">
            <Banner/>
            <Descripcion    :brandName="brand.slug"
                            :brandPic="brand.logo"/>
            <TabsMarca />
            <ContenidoMarca :brandName="brand.slug"
                            :brandDescription="brand.about"
                            :products="products"
                            :services="services"/>
            <div class="col-11 d-flex flex-column justify-content-center align-items-center px-0 ">
                <PanelSeccionMarcas/>
            </div>
        </div>
        <FooterPerfilMarca :activeHome="true" class="d-lg-none"/>
        <div class="espacio2"></div>
    </div>
</template>

<script setup>
    import HeaderTitulo from "../../components/Titulo/HeaderTitulo.vue";
    import FooterPerfilMarca from "../../components/Footer/FooterPerfilMarca.vue";
    import NavbarBuscador from "../../components/Navs/NavbarBuscador.vue";
    import NavbarBack from "../../components/Navs/Navbar.vue";
    import PanelSeccionMarcas from "../../components/PanelSeccion/PanelSeccionMarcas.vue";
    import Banner from "../../components/Perfil/Banner.vue";
    import Descripcion from "../../components/Perfil/Descripcion.vue";
    import TabsMarca from "../../components/Perfil/TabsMarca.vue";
    import ContenidoMarca from "../../components/Perfil/ContenidoMarca.vue";
    import { useUsersStore } from "../../stores/user";
    import { storeToRefs } from "pinia";
    import { ref, onMounted, computed, onUpdated, watchEffect, watch, onBeforeMount, provide } from 'vue';
    import { useRouter, useRoute } from 'vue-router';
    import { useI18n } from "vue-i18n";
    import axios from 'axios';

    const users = useUsersStore();
    const {cambioHeader} = storeToRefs(users);

    const router = useRouter();
    const route = useRoute();

    const urlSearch = process.env.VUE_APP_MEILISEARCH_URL+'/multi-search'
    const url = process.env.VUE_APP_BASE_URL
    const urlApi = '/api/brands/'
    const slug = route.params.id
    const slugRef = ref('')
    const slugPrueba = ref('')
    const {locale} = useI18n({ useScope: 'global' })
    const lang = ref('ES')
    const id = ref('')
    const products = ref({

    })
    
    const services = ref({

    })
    //Setea parametros de busqueda para el post
    // const slug = ref({
    //     queries:[{
    //         indexUid: 'product_products',
    //         q:''
    //     }]
    // })

    //Precarga parametros de resultados de busqueda
    const brand = ref({
        name: {
            
        },
        description: {
            
        }
    })
    
    const brandProducts = ref({})
    provide("brandProducts", brandProducts)
    //Cuando cambio de lang se vuelve a buscar la información necesaria en ese lang
    watch(locale, async () => {
        //console.log('Me ejecuto')
        setLang()
        try {
            //slugRef.value = brand.value.slug[lang.value]
            console.log(slugPrueba.value)
            
            await axios.get(url+urlApi+lang.value+`/`+slugPrueba.value, ).then((result) => {
                brand.value = result.data
                products.value = result.data.brand_products
                services.value = result.data.services
                id.value = result.data.id
                //console.log('me ejecuto')
                console.log(result)
                router.push({path: `/perfil-marca/${slugPrueba.value}`})
                
            })
        }
        catch (error) {
            console.log(error)
        }
    })
    //Hago un post del usuario x
    const fetchData = async () => {
        try {
            await axios.get(url+urlApi+lang.value+`/`+slug, ).then((result) => {
                brand.value = result.data
                products.value = result.data.brand_products
                services.value = result.data.services
                //console.log(services.value)
                id.value = result.data.id
                console.log(result)
                slugPrueba.value = brand.value.slug
            })
        }
        catch (error) {
            console.log(error)
        }
    }
    fetchData()

    //Setea parametros de busqueda para el post
    // const search = ref({
    //     queries:[
    //         {
    //             indexUid: 'product_products',
    //             q: '',
    //         },
    //     ]
    // })
    // const fetchSearch =  async () => {
    //     //console.log('Meilisearch')
    //     search.value.queries[0].q = slug
    //     try {
    //         await axios.post(urlSearch, search.value).then((result) => {
    //             const resultData = (x) => { return result.data.results[x].hits }
    //             const resultLength = (x) => { return result.data.results[x].hits.length }
    //             console.log(result)
    //             brandProducts.value = resultData(0)
    //             console.log(brandProducts.value)
    //         })
    //     }
    //     catch (error) {
    //     console.log(error)
    //     }
    //     //console.log( 'fin Meilisearch')
    // }
    // fetchSearch()

    let insertedNodes = []

const useHeadTags = async (settings = null) => {
    const url = process.env.VUE_APP_BASE_URL

    try {
        let response = await axios.post(`${url}/api/public/seo/pages`, { slug: settings.slug });

        const tagsString = await response.data.data

        const parser = new DOMParser()
        const doc = parser.parseFromString(
        `<html><head>${tagsString}</head><body></body></html>`,
        'text/html'
        )

        const headNodes = Array.from(doc.head.childNodes)

        headNodes.forEach(node => {
        const nodeName = node.nodeName.toLowerCase()

        switch (nodeName) {
            case 'title':
            document.title = node.textContent ?? ''
            break

            case 'meta': {
            const metaName = node.getAttribute('name')
            if (metaName) {
                const existingMeta = document.head.querySelector(`meta[name="${metaName}"]`)
                if (existingMeta) {
                document.head.removeChild(existingMeta)
                }
            }
            const meta = document.createElement('meta')
            Array.from(node.attributes).forEach(attr => {
                meta.setAttribute(attr.name, attr.value)
            })
            document.head.appendChild(meta)
            insertedNodes.push(meta)
            break
            }

            case 'script': {
            const script = document.createElement('script')
            Array.from(node.attributes).forEach(attr => {
                script.setAttribute(attr.name, attr.value)
            })
            script.textContent = node.textContent
            document.head.appendChild(script)
            insertedNodes.push(script)
            break
            }

            case 'link': {
            const link = document.createElement('link')
            Array.from(node.attributes).forEach(attr => {
                link.setAttribute(attr.name, attr.value)
            })
            document.head.appendChild(link)
            insertedNodes.push(link)
            break
            }

            default:
            console.warn(`Etiqueta <${nodeName}> no manejada.`)
        }
        })
        console.warn('TAGS CARGADOS')
    } catch (error) {
        console.error('Error procesando las etiquetas:', error)
    }
}

router.beforeEach(() => {
    insertedNodes.forEach(node => {
    if (document.head.contains(node)) {
        document.head.removeChild(node)
    }
    })
    insertedNodes = []
})

onMounted(() => {
    useHeadTags({ slug: route.fullPath.slice(1) })
})

</script>

<style lang="scss" scoped>
#app, body, .container-fluid{
    background-color: #FAFAFA !important;
}




</style>