<template>
    <div class="col-12 d-flex justify-content-center align-items-center date">
        <div class="row justify-content-center row__width" v-if="product.details">
            <div class="col-12 d-flex justify-content-start align-items-center" v-for="(date, id) in product.details.schedules">
                <img src="../../img/home-icons/cursos-icons/calendar-negro.svg" alt="">
                <p>{{ formatDate(date) }}</p>
            </div>
        </div>
    </div>
</template>

<script setup>
import { inject } from 'vue';
import dayjs from 'dayjs';

const props = defineProps({});
const product = inject('product');


const formatDate = (date) => {
    return dayjs(date).format('DD-MM-YYYY [a las] HH:mm');
};
</script>

<style lang="scss" scoped>
.date{
    .col-12{
        padding: 0px 0px 15px 0px;
    }
    p{
        font-weight: 400;
        font-size: 16px;
        color: var(--colorSecondary);
        text-align: left;
    }
    .revision{
        color: var(--colorPrimary);
    }
    img{
        padding-right: 10px;
    }
    .border{
        box-shadow: 0px 3px 6px #00000029;
        border: 1px solid #707070;
        border-radius: 10px;
        padding: 15px 20px;
        .col-12{
            &:last-child{
                padding-bottom: 0;
            }
        }
    }
}
</style>